import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import qs from 'qs';
import { convertStringifiedBoolean } from 'core/helpers';

import { LeagueGames } from './league-games/league-games';
import { InplayGames } from './inplay-games/inplay-games';

import './games.scss';

const GamesUI = ({
  params,
  search,
  toggleNavigation,
  setActiveCountries,
  setActiveLeagueId,
  sendEventClickFeedback,
}) => {
  const { isLive } = qs.parse(search, { ignoreQueryPrefix: true });

  return convertStringifiedBoolean(isLive)
    ? (
      <InplayGames
        params={params}
        toggleNavigation={toggleNavigation}
        sendEventClickFeedback={sendEventClickFeedback}
      />
    )
    : (
      <LeagueGames
        coreProps={{ eventId: R.prop('eventId', params) }}
        params={params}
        toggleNavigation={toggleNavigation}
        setActiveCountries={setActiveCountries}
        setActiveLeagueId={setActiveLeagueId}
        sendEventClickFeedback={sendEventClickFeedback}
      />
    );
};

GamesUI.propTypes = {
  params: PropTypes.shape().isRequired,
  search: PropTypes.string.isRequired,
  toggleNavigation: PropTypes.func,
  setActiveCountries: PropTypes.func,
  setActiveLeagueId: PropTypes.func,
  sendEventClickFeedback: PropTypes.func.isRequired,
};

GamesUI.defaultProps = {
  toggleNavigation: () => {},
  setActiveCountries: () => {},
  setActiveLeagueId: () => {},
};

export const Games = GamesUI;
