import * as R from 'ramda';
import {
  handicapStakeTypesIds,
  totalStakeTypesIds,
  tabsConfig,
} from 'core/mapping-stake-types';

import { getIsHighlighted } from 'helpers/stake';

const prepareStakes = (stakes, isEventInLive, betSlipStakes, betSlipState) => R.map(stake => ({
  ...stake,
  isHighlighted: getIsHighlighted(betSlipState, betSlipStakes, stake.stakeId, isEventInLive),
}), stakes);

const prepareHandicap = (stakes) => {
  const result = [];
  const stakeCode1Group = R.filter(R.propEq('stakeCode', 1), stakes);
  let stakeCode2Group = R.filter(R.propEq('stakeCode', 2), stakes);

  stakeCode1Group.forEach((groupItem) => {
    const foundGroupItem = R.find(
      R.propEq('stakeArgument', groupItem.stakeArgument === 0 ? 0 : groupItem.stakeArgument * -1),
      stakeCode2Group
    );

    if (foundGroupItem) {
      result.push([groupItem, foundGroupItem]);
      stakeCode2Group = R.without([foundGroupItem], stakeCode2Group);
    } else {
      result.push([groupItem]);
    }
  });

  stakeCode2Group.forEach((groupItem) => {
    result.push([groupItem]);
  });

  return result.sort((a, b) => {
    const { stakeCode: aStakeCode, stakeArgument: aStakeArgument } = a[0];
    const { stakeCode: bStakeCode, stakeArgument: bStakeArgument } = b[0];
    const aResult = aStakeCode === 1 ? aStakeArgument : aStakeArgument * -1;
    const bResult = bStakeCode === 1 ? bStakeArgument : bStakeArgument * -1;

    return aResult - bResult;
  });
};

const prepareTotal = (stakes) => {
  const result = [];
  const stakeHomeGroup = R.filter(({ stakeCode }) => stakeCode % 2 === 1, stakes);
  let stakeAwayGroup = R.filter(({ stakeCode }) => stakeCode % 2 === 0, stakes);

  stakeHomeGroup.forEach((groupItem) => {
    const foundGroupItem = R.find(
      R.propEq('stakeArgument', groupItem.stakeArgument),
      stakeAwayGroup
    );

    if (foundGroupItem) {
      result.push([groupItem, foundGroupItem]);
      stakeAwayGroup = R.without([foundGroupItem], stakeAwayGroup);
    } else {
      result.push([groupItem]);
    }
  });

  stakeAwayGroup.forEach((groupItem) => {
    result.push([groupItem]);
  });

  return result.sort((a, b) => {
    const { stakeArgument: aStakeArgument } = a[0];
    const { stakeArgument: bStakeArgument } = b[0];

    return aStakeArgument - bStakeArgument;
  });
};

export const mapStakeTypes = (
  {
    stakeTypes,
    isLive,
    eventStatus,
  },
  betSlipStakes,
  betSlipState,
  favoriteStakeTypes,
) => R.compose(
  R.sort((a, b) => {
    if (a.isFavorite && !b.isFavorite) {
      return -1;
    }

    return 0;
  }),
  R.sort((a, b) => {
    if (a.isBig) {
      return -1;
    }

    if (b.isBig) {
      return 1;
    }

    return 0;
  }),
  R.map(({ stakeTypeId, stakeTypeName, stakes }) => {
    const isEventInLive = isLive || eventStatus !== null;
    const isFavorite = !!R.find(R.propEq('stakeTypeId', stakeTypeId), favoriteStakeTypes || []);
    const isHandicap = handicapStakeTypesIds.includes(stakeTypeId);
    const isTotal = totalStakeTypesIds.includes(stakeTypeId);
    const isBig = stakeTypeId === 1 || stakeTypeId === 702 && favoriteStakeTypes
    && favoriteStakeTypes.length && !isFavorite;
    let preparedStakes = prepareStakes(stakes, isEventInLive, betSlipStakes, betSlipState);

    if (isHandicap) {
      preparedStakes = prepareHandicap(preparedStakes);
    } else if (isTotal) {
      preparedStakes = prepareTotal(preparedStakes);
    }

    return {
      stakeTypeId,
      stakeTypeName,
      stakes: preparedStakes,
      isFavorite,
      isHandicap,
      isTotal,
      isBig,
    };
  }),
)(stakeTypes);

export const getXsLabel = (outcomeId) => {
  if (outcomeId === '1' || outcomeId === '4') {
    return '1';
  }

  if (outcomeId === '2') {
    return 'X';
  }

  if (outcomeId === '3' || outcomeId === '5') {
    return '2';
  }

  if (outcomeId === '9') {
    return '1X';
  }

  if (outcomeId === '10') {
    return '12';
  }

  if (outcomeId === '11') {
    return 'X2';
  }

  return null;
};

export const filterSubEvents = (eventData, activeTab) => {
  if (activeTab === 0) {
    return eventData;
  }

  return R.find(R.propEq('periodName', activeTab), eventData.subEvents);
};

export const prepareTabs = ({
  sportId,
  isLive,
  markets,
}) => {
  const result = [];
  const config = tabsConfig[sportId];

  if (config) {
    config.forEach(({
      label,
      marketIds,
      type,
    }) => {
      const isTypeExists = type === 'all' || (type === 'live' && isLive) || (type === 'prematch' && !isLive);
      const isMarketExists = marketIds && marketIds.filter(id => markets[id]).length;

      if (label !== 'all' && isTypeExists && isMarketExists) {
        result.push(label);
      }
    });
  }

  return result;
};

export const filterMarkets = ({ sportId, markets, activeTab }) => {
  const config = tabsConfig[sportId];
  let result = config ? {} : markets;

  if (config) {
    const foundTab = config.find(({ label }) => label === activeTab);

    if (foundTab) {
      const { marketIds, excludedMarkets } = foundTab;

      if (marketIds) {
        marketIds.forEach((id) => {
          const foundMarket = markets[id];

          if (foundMarket) {
            result[id] = foundMarket;
          }
        });
      } else if (excludedMarkets) {
        result = R.omit(excludedMarkets, markets);
      }
    }
  }

  return result;
};
