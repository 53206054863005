import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import { isEmptyOrNil } from 'core/helpers';

import { IconExpand } from 'components/icons/icon-expand/icon-expand';
import { OddsCell } from 'components/betting-table/odds-cell/odds-cell';

import './game-outcome-dropdown.scss';

const sortOutcomes = R.compose(
  R.sort(([keyA], [keyB]) => {
    const aVal = Number(keyA.split('|').map(sp => sp.split('=')).find(([k]) => k === 'total' || k === 'hcp')[1]);
    const bVal = Number(keyB.split('|').map(sp => sp.split('=')).find(([k]) => k === 'total' || k === 'hcp')[1]);

    return aVal - bVal;
  }),
);

export class GameOutcomeDropdown extends Component {
  static propTypes = {
    className: PropTypes.string,
    classNameMenu: PropTypes.string,
    outcomes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    gameOutcomeFiltersRef: PropTypes.node.isRequired,
    isTotal: PropTypes.bool.isRequired,
    isHandicap: PropTypes.bool.isRequired,
    specifier: PropTypes.string.isRequired,
    marketId: PropTypes.number.isRequired,
    oddProps: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    className: null,
    classNameMenu: null
  };

  state = {
    isOpen: false,
  };

  toggle = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  render() {
    const {
      className,
      classNameMenu,
      outcomes,
      gameOutcomeFiltersRef,
      isTotal,
      isHandicap,
      specifier,
      marketId,
      oddProps
    } = this.props;
    const { isOpen } = this.state;
    const { eventId } = oddProps;
    const sortedOutcomes = sortOutcomes(outcomes);

    return (
      <div className={classNames('game-outcome-dropdown', className)}>
        <Dropdown isOpen={isOpen} toggle={this.toggle} className="d-flex align-items-center">
          <DropdownToggle className="game-outcome-dropdown-toggle p-0 bg-transparent">
            <IconExpand />
          </DropdownToggle>
          <DropdownMenu container={gameOutcomeFiltersRef} right className={classNames('game-outcome-dropdown-menu p-1', classNameMenu)}>
            {sortedOutcomes.map(([outcomesSpecifier, outcomeValues]) => (
              <div
                key={`${eventId}-${marketId}-${specifier}-${outcomesSpecifier}`}
                className="game-outcome-dropdown-item caption overflow-hidden"
              >
                {Object.entries(outcomeValues).map(([outcomeId, outcome]) => (
                  !isEmptyOrNil(outcome) ? (
                    <OddsCell
                      key={`${eventId}-${marketId}-${specifier}-${outcomeId}`}
                      className="game-outcome-dropdown-odds"
                      stake={outcome}
                      outcomeId={outcomeId}
                      marketId={marketId}
                      isTotal={isTotal}
                      isHandicap={isHandicap}
                      specifier={outcomesSpecifier}
                      oddsType="secondary"
                      {...oddProps}
                    />
                  ) : (
                    <div className="odd-cell bg-main-5 is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                      &#8212;
                    </div>
                  )
                ))}
              </div>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
