import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { TopLeagueItem } from '../top-league-item/top-league-item';

export class TopLeagues extends PureComponent {
  static propTypes = {
    topLeagues: PropTypes.arrayOf(PropTypes.shape()),
    params: PropTypes.shape({
      sportId: PropTypes.string,
      leagueId: PropTypes.string,
    }),
    locale: PropTypes.string.isRequired,
    isAllSportsModal: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    activeLeagueId: PropTypes.number,
    onFavoriteLeagueClick: PropTypes.func,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    topLeagues: null,
    params: {},
    isAllSportsModal: false,
    activeLeagueId: null,
    onFavoriteLeagueClick: () => {},
  };

  render() {
    const {
      isAllSportsModal,
      topLeagues,
      locale,
      params,
      activeLeagueId,
      closeModal,
      onFavoriteLeagueClick,
      sendLeagueClickFeedback,
    } = this.props;

    return (
      <>
        <FormattedTag
          tag="div"
          className={classNames('text-uppercase pt-2_5 pl-lg-2_5 label text-extra-3', {
            'pb-1 pl-2 pl-sm-2_5': isAllSportsModal,
            'pb-1_5': !isAllSportsModal,
          })}
          id="general.top-leagues"
        />

        {topLeagues.slice(0, 6).map(topLeague => (
          <TopLeagueItem
            key={topLeague.leagueId}
            {...topLeague}
            locale={locale}
            isActive={isAllSportsModal
              ? topLeague.leagueId === params.leagueId
              : activeLeagueId === topLeague.leagueId}
            isAllSportsModal={isAllSportsModal}
            closeModal={closeModal}
            onFavoriteLeagueClick={onFavoriteLeagueClick}
            sendLeagueClickFeedback={sendLeagueClickFeedback}
          />
        ))}

        <div className="sports-divider mt-2" />
      </>
    );
  }
}
