import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { getConfig, convertStringifiedBoolean } from 'core/helpers';

import { RouteLink } from 'components/route-link/route-link';
import { IconStar } from 'components/icons/icon-star/icon-star';
import { IconStarEmpty } from 'components/icons/icon-star-empty/icon-star-empty';
import { IconLivestreamSmall } from 'components/icons/icon-livestream-small/icon-livestream-small';
import { GameStatus } from 'components/betting-table/game-status/game-status';
import { toTimeFormat } from 'helpers/date';
import { GA } from 'helpers/ga';
import { prepareScore } from 'helpers/score';
import { SPORTS_WITHOUT_LIVE_SCORE } from '../../../../constants';

const IS_LIVE_STREAMS_ENABLED = getConfig('IS_LIVE_STREAMS_ENABLED');

export class GameItem extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    eventData: PropTypes.shape().isRequired,
    updateWatchlist: PropTypes.func,
    withWathlistToggle: PropTypes.bool,
    isInWatchlist: PropTypes.bool,
    statuses: PropTypes.shape(),
    hasLiveStream: PropTypes.bool,
    onEventClick: PropTypes.func,
    sendEventClickFeedback: PropTypes.func.isRequired,
    sectionBeforeEventPage: PropTypes.string.isRequired,
  };

  static defaultProps = {
    updateWatchlist: null,
    withWathlistToggle: false,
    isInWatchlist: false,
    statuses: null,
    hasLiveStream: false,
    onEventClick: () => null,
  };

  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props, nextProps);
  }

  updateWatchlist = (e) => {
    e.stopPropagation();
    const { updateWatchlist, eventData, isInWatchlist } = this.props;

    updateWatchlist(eventData.desc.id);
    GA.event({
      category: 'watchlist',
      label: isInWatchlist ? 'delete-sidebar' : 'add-sidebar',
    });
  }

  onClick = (e) => {
    const { dataset: { eventId, isLive } } = e.currentTarget;
    const { sendEventClickFeedback, onEventClick } = this.props;

    sendEventClickFeedback(eventId, convertStringifiedBoolean(isLive));
    onEventClick(e);
  };

  render() {
    const {
      locale,
      isActive,
      eventData: {
        desc: {
          id: eventId,
          sport: {
            id: sportId,
          },
          competitors,
          scheduled,
        },
        score,
        state: {
          match_status: eventStatus,
          clock,
        },
        isLive,
      },
      withWathlistToggle,
      isInWatchlist,
      statuses,
      hasLiveStream,
      sectionBeforeEventPage,
    } = this.props;
    const {
      home_score: homeScore,
      away_score: awayScore,
      home_dismissals: homeDismissals,
      away_dismissals: awayDismissals,
    } = score;
    const teamA = competitors[0].name;
    const teamB = competitors[1].name;
    const timestamp = scheduled * 1000;
    const preparedHomeScore = prepareScore(sportId, homeScore, homeDismissals);
    const preparedAwayScore = prepareScore(sportId, awayScore, awayDismissals);

    return (
      <div className={classNames('sport-item-link d-flex flex-column position-relative', { 'current-item': isActive })}>
        <RouteLink
          locale={locale}
          to={`/event/${eventId}?isLive=${isLive}&sectionBeforeEventPage=${sectionBeforeEventPage}`}
          className="sport-item-link-teams pl-2 d-flex flex-column justify-content-center"
          onClick={this.onClick}
          data-event-id={eventId}
          data-is-live={isLive}
        >
          <div className="d-flex justify-content-between">
            <div className="team-title text-small font-weight-normal text-break">
              <span>{teamA}</span>
            </div>
            <div className={classNames('d-flex', { 'pt-0_5': isLive, 'mt-n0_25': !isLive })}>
              <div className="sport-item-link-status mr-1">
                {isLive
                  ? withWathlistToggle && (
                    <div
                      role="button"
                      tabIndex="0"
                      onClick={this.updateWatchlist}
                      onKeyPress={this.updateWatchlist}
                      className="mt-n0_25"
                    >
                      {isInWatchlist ? <IconStar className="icon-star" /> : <IconStarEmpty className="icon-star" />}
                    </div>
                  )
                  : <span className="label text-extra-2">{toTimeFormat(timestamp, locale)}</span>}
              </div>

              {!SPORTS_WITHOUT_LIVE_SCORE.includes(sportId) && (
                <div className="text-success label font-weight-bold">
                  {isLive ? preparedHomeScore : ''}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div className="team-title text-small font-weight-normal text-break mt-0_5">
              {teamB}
            </div>

            {!SPORTS_WITHOUT_LIVE_SCORE.includes(sportId) && (
              <div className="text-success label font-weight-bold pt-1">
                {isLive ? preparedAwayScore : ''}
              </div>
            )}
          </div>

          {isLive && (
            <div className="d-flex pt-1">
              {IS_LIVE_STREAMS_ENABLED && hasLiveStream && <IconLivestreamSmall className="mr-1 flex-shrink-0" />}

              <GameStatus
                locale={locale}
                sportId={sportId}
                eventStatus={eventStatus}
                className="game-status text-truncate text-extra-3"
                statuses={statuses}
                clock={clock}
              />
            </div>
          )}
        </RouteLink>
      </div>
    );
  }
}
