import * as R from 'ramda';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { LOCALE_KEYS } from 'core/constants';

import { TODAY, TOMORROW } from '../constants';

dayjs.extend(utc);

export const ONE_SECOND_MS = 1000;
export const ONE_MINUTE_MS = 60 * ONE_SECOND_MS;
export const TWO_DAYS_IN_HOURS = 48;

export const DATE_LOCALE_FORMATS = {
  [LOCALE_KEYS.EN]: 'D MMM',
  [LOCALE_KEYS.TR]: 'D MMM',
};

export const FULL_DATE_LOCALE_FORMATS = {
  [LOCALE_KEYS.EN]: 'D MMM YYYY',
  [LOCALE_KEYS.TR]: 'D MMM YYYY',
};

export const DATE_LOCALE_INVERSE_FORMATS = {
  [LOCALE_KEYS.EN]: 'MMM D',
  [LOCALE_KEYS.TR]: 'MMM D',
};

export const TIME_LOCALE_DATE_FORMATS = {
  [LOCALE_KEYS.EN]: 'h:mm a',
  [LOCALE_KEYS.TR]: 'HH:mm',
};

export const SHORT_LOCALE_DATE_TIME_FORMATS = {
  [LOCALE_KEYS.EN]: 'D MMM, h:mm a',
  [LOCALE_KEYS.TR]: 'D MMM, HH:mm',
};

export const DEFAULT_LOCALE = LOCALE_KEYS.EN;

export const formatDate = (dateTime, localeFormats, locale) => {
  const localeDateTimeFormat = localeFormats[locale] || localeFormats[DEFAULT_LOCALE];

  return dayjs(dateTime, { locale }).format(localeDateTimeFormat);
};

export const toDateFormat = (dateTime, locale = DEFAULT_LOCALE) => formatDate(
  dateTime,
  DATE_LOCALE_FORMATS,
  locale,
);

export const toTimeFormat = (dateTime, locale = DEFAULT_LOCALE) => formatDate(
  dateTime,
  TIME_LOCALE_DATE_FORMATS,
  locale,
);

export const toShortFormat = (dateTime, locale = DEFAULT_LOCALE) => formatDate(
  dateTime,
  SHORT_LOCALE_DATE_TIME_FORMATS,
  locale,
);

export const getTimeDiff = (dateTime1, dateTime2) => new Date(dateTime2).getTime() - new Date(dateTime1).getTime();

export const getDiffFromNow = dateTime => getTimeDiff(new Date(Date.now()).toISOString(), dateTime);

export const isDateInPast = R.compose(R.gt(0), getDiffFromNow);

export const isDateIsToday = dateTime => new Date(Date.now()).toDateString() === new Date(dateTime).toDateString()
  ? TODAY
  : false;

export const isDateIsTomorrow = (dateTime) => {
  const tomorrow = new Date(Date.now());
  tomorrow.setDate(tomorrow.getDate() + 1);

  return tomorrow.getDate() === new Date(dateTime).getDate() ? TOMORROW : false;
};

export const isDateInPastOrTodayOrTomorrow = dateTime =>
  isDateInPast(dateTime) || isDateIsToday(dateTime) || isDateIsTomorrow(dateTime);

export const toTimeIfNearOrDate = (dateTime, locale = DEFAULT_LOCALE) =>
  isDateInPastOrTodayOrTomorrow(dateTime) === TODAY || isDateInPastOrTodayOrTomorrow(dateTime) === TOMORROW
    ? toTimeFormat(dateTime, locale)
    : toShortFormat(dateTime, locale);

export const toGamesFormat = (dateTime, locale) => dayjs(dateTime, { locale }).format('dddd, D MMMM');

export const toTodayOrTomorrow = (dateTime) => {
  const formattedDate = isDateInPastOrTodayOrTomorrow(dateTime);

  if (formattedDate === TODAY) {
    return TODAY;
  }

  if (formattedDate === TOMORROW) {
    return TOMORROW;
  }

  return null;
};

export const removeComma = R.replace(',', '');

export const getHoursOrDays = (date) => {
  const targetTime = dayjs.utc(date);
  const now = dayjs.utc();
  const diff = targetTime.diff(now, 'hours');
  const dateMoreTwoDays = Math.ceil(targetTime.diff(now, 'days', true));

  return diff < TWO_DAYS_IN_HOURS ? targetTime : dateMoreTwoDays;
};

export const dateCountDown = date => Date.now() + date * ONE_MINUTE_MS;

export const parseDateCounter = date => (date instanceof dayjs) ? date : dateCountDown(date);

export const toDatePickerFormat = (dateFrom, dateTo, locale = DEFAULT_LOCALE) => {
  const yearFrom = dateFrom.getFullYear();
  const yearTo = dateTo.getFullYear();
  const monthFrom = dateFrom.getMonth();
  const monthTo = dateTo.getMonth();
  const dayFrom = dateFrom.getDate();
  const dayTo = dateTo.getDate();

  if (yearFrom !== yearTo) {
    return `${formatDate(dateFrom, FULL_DATE_LOCALE_FORMATS, locale)} - ${formatDate(dateTo, FULL_DATE_LOCALE_FORMATS, locale)}`;
  }

  if (monthFrom === monthTo) {
    if (dayFrom === dayTo) {
      return formatDate(dateFrom, FULL_DATE_LOCALE_FORMATS, locale);
    }

    return `${formatDate(dateFrom, DATE_LOCALE_INVERSE_FORMATS, locale)} - ${dayTo}`;
  }

  return `${toDateFormat(dateFrom, locale)} - ${toDateFormat(dateTo, locale)}`;
};

export const prepareDateFrom = dateTime => dateTime ? Math.floor(+dateTime.setHours(0, 0, 0) / 1000) : null;
export const prepareDateTo = dateTime => dateTime ? Math.ceil(+dateTime.setHours(23, 59, 59) / 1000) : null;

export const toScheduleFormat = (dateTime, locale) => dayjs(dateTime, { locale }).format('ddd D MMM');
export const toScheduleMobileShortFormat = (dateTime, locale) => dayjs(dateTime, { locale }).format(', MMM D');
export const toScheduleMobileFormat = (dateTime, locale) => dayjs(dateTime, { locale }).format('dddd, MMM D');

export const toFormat = (dateTime, locale, format) => dayjs(dateTime, { locale }).format(format);
export const toCurrentTimeFormat = (dateTime, locale) => dayjs(dateTime, { locale }).format('ddd HH:mm');

export const toResultsFormat = dateTime => dayjs(dateTime).format('YYYY-MM-DD');
