import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';
import Button from 'reactstrap/lib/Button';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import { subtractValues, isEmptyOrNil, mathRound } from 'core/helpers';
import {
  BET_TYPE,
  BET_SLIP_STATE,
  IS_MULTIPLE_RESTRICTED_FIELD,
  BET_SLIP_ERRORS,
} from 'core/constants';

import { Spinner } from 'components/spinner/spinner';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { BetFactorChanges } from 'components/bet-slip/bet-factor-changes/bet-factor-changes';
import { Bonuses } from 'components/bonuses/bonuses';
import { IconInfo } from 'components/icons/icon-info/icon-info';
import { IconWarning } from 'components/icons/icon-warning/icon-warning';
import { prepareCurrency } from 'helpers/currency';
import { GA } from 'helpers/ga';
import { BetAmountInput } from '../bet-amount-input/bet-amount-input';

import './bet-slip-footer.scss';

const TOOLTIP_ID = 'system-variants-tooltip-id';

export class BetSlipFooter extends Component {
  static propTypes = {
    currency: PropTypes.string.isRequired,
    stakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    totalBetFactor: PropTypes.number,
    totalReturnAmount: PropTypes.number,
    systemVariants: PropTypes.number,
    totalBetAmount: PropTypes.number,
    isSystemInfoInProgress: PropTypes.bool.isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    acceptChanges: PropTypes.func.isRequired,
    setIsKeyboardShowed: PropTypes.func.isRequired,
    placeBet: PropTypes.func.isRequired,
    isPlaceBetInProgress: PropTypes.bool.isRequired,
    error: PropTypes.shape(),
    bonuses: PropTypes.arrayOf(PropTypes.shape({})),
    isBonusConditionsReached: PropTypes.bool.isRequired,
    bonusAmount: PropTypes.number,
    betFactorChanges: PropTypes.bool.isRequired,
    setBetFactorChanges: PropTypes.func.isRequired,
    setTicketMaxHeight: PropTypes.func.isRequired,
    setBetAmount: PropTypes.func.isRequired,
    isFreebetUsed: PropTypes.bool.isRequired,
    systemBetAmount: PropTypes.number,
    isPlaceBetDisabled: PropTypes.bool.isRequired,
    setIsPlaceBetDisabled: PropTypes.func.isRequired,
  };

  static defaultProps = {
    totalBetFactor: null,
    totalReturnAmount: null,
    totalBetAmount: null,
    systemVariants: null,
    error: null,
    bonuses: null,
    bonusAmount: null,
    systemBetAmount: null,
  };

  onPlaceBetClick = () => {
    const { placeBet, stakes } = this.props;
    const isAccumulator1 = !!R.find(R.propEq('accumulatorIdx', 0), stakes);
    const isAccumulator2 = !!R.find(R.propEq('accumulatorIdx', 1), stakes);
    let label = 'place-bet-click';

    if (isAccumulator1) {
      label = 'place-bet-1';
    } else if (isAccumulator2) {
      label = 'place-bet-2';
    }

    placeBet();
    GA.event({
      category: 'bet-slip',
      label,
    });
  };

  onAcceptChangesClick = () => {
    const { acceptChanges } = this.props;
    acceptChanges();
    GA.event({
      category: 'bet-slip',
      label: 'accept-changes-click',
    });
  };

  render() {
    const {
      currency,
      betType,
      totalBetFactor,
      totalReturnAmount,
      systemVariants,
      totalBetAmount,
      isSystemInfoInProgress,
      betSlipState,
      stakes,
      setIsKeyboardShowed,
      isPlaceBetInProgress,
      error,
      bonuses,
      isBonusConditionsReached,
      bonusAmount,
      betFactorChanges,
      setBetFactorChanges,
      setTicketMaxHeight,
      setBetAmount,
      isFreebetUsed,
      systemBetAmount,
      isPlaceBetDisabled,
      setIsPlaceBetDisabled,
    } = this.props;
    const isOrdinar = betType === BET_TYPE.ORDINAR;
    const isExpress = betType === BET_TYPE.EXPRESS;
    const isSystem = betType === BET_TYPE.SYSTEM;
    const isOneBet = stakes.length === 1;
    const isDefaultState = betSlipState === BET_SLIP_STATE.DEFAULT;
    const isSuspendedState = betSlipState === BET_SLIP_STATE.SUSPENDED;
    const isBetFactorDecreasedState = betSlipState === BET_SLIP_STATE.BET_FACTOR_DECREASED;
    const isErrorState = betSlipState === BET_SLIP_STATE.ERROR;
    const isMultipleRestrictedState = R.find(R.prop(IS_MULTIPLE_RESTRICTED_FIELD), stakes);
    const isExpressDeniedState = isExpress && R.find(R.prop('isExpressDenied'), stakes);
    const isOutOfBalance = isErrorState && error && error.errorId === BET_SLIP_ERRORS.BET_AMOUNT_OUT_OF_BALANCE;
    const isStakesAmountExceed = isErrorState && error && error.errorId === BET_SLIP_ERRORS.STAKES_AMOUNT_EXCEED;
    const totalReturnWithoutBonus = bonusAmount
      ? subtractValues([totalReturnAmount, bonusAmount])
      : null;
    let errorIntlId;

    if (isErrorState) {
      if (error && error.errorId === BET_SLIP_ERRORS.BET_AMOUNT_LESS_THAN_LIMIT) {
        errorIntlId = 'bet-slip.stake-limit-error.less.title';
      } else if (error && error.errorId === BET_SLIP_ERRORS.BET_AMOUNT_MORE_THAN_LIMIT) {
        errorIntlId = 'bet-slip.stake-limit-error.more.title';
      } else if (isOutOfBalance) {
        errorIntlId = 'bet-slip.out-of-balance-error.title';
      }
    }

    return (
      <div className={classNames('bet-slip-footer-mobile px-1_5 pb-2 bg-main-4', {
        'is-ordinar': isOneBet,
        'pt-2': !isOneBet && !isMultipleRestrictedState && !isExpressDeniedState,
        'pt-1_5': !isOneBet && (isMultipleRestrictedState || isExpressDeniedState),
      })}
      >
        {(isMultipleRestrictedState || isExpressDeniedState) && (
          <FormattedTag
            tag="div"
            id={`bet-slip.${isMultipleRestrictedState ? 'multiple-restricted' : 'express-denied'}`}
            className="text-warning label mb-1"
          />
        )}

        <div className={classNames('d-flex justify-content-between align-items-center', {
          'mb-2': isOrdinar,
        })}
        >
          {!isOrdinar && (
            <BetAmountInput
              currency={currency}
              betAmount={totalBetAmount}
              setBetAmount={setBetAmount}
              betSlipState={betSlipState}
              error={error}
              isPlaceBetInProgress={isPlaceBetInProgress}
              setIsKeyboardShowed={setIsKeyboardShowed}
              setIsPlaceBetDisabled={setIsPlaceBetDisabled}
            />
          )}
          {isExpress && !!totalBetFactor && <span className="font-weight-bold">{mathRound(totalBetFactor)}</span>}
          {isSystem && !!totalBetAmount && (
            <Fragment>
              {systemVariants
                ? (
                  <Fragment>
                    {isSystemInfoInProgress
                      ? <Spinner isSmall />
                      : (
                        <div className="d-flex align-items-center">
                          <div id={TOOLTIP_ID} className="icon-info d-flex justify-content-center align-items-center align-selft-stretch">
                            <IconInfo />
                          </div>
                          <FormattedTag
                            id="bet-slip.variants.description"
                            tag={UncontrolledTooltip}
                            placement="bottom-start"
                            target={TOOLTIP_ID}
                          />
                          <FormattedTag id="bet-slip.variants" className="text-small text-extra-2" values={{ amount: null }} />
                          <span className="text-small font-weight-bold ml-0_5">{systemVariants}</span>
                        </div>
                      )}
                  </Fragment>
                )
                : isSystemInfoInProgress && <Spinner isSmall />}
            </Fragment>
          )}
        </div>

        {isExpress && !isEmptyOrNil(bonuses) && (
          <Bonuses
            currency={currency}
            bonuses={bonuses}
            isBonusConditionsReached={isBonusConditionsReached}
            bonusAmount={bonusAmount}
            stakes={stakes}
            setTicketMaxHeight={setTicketMaxHeight}
          />
        )}

        {!isOneBet && (
          <>
            {isOrdinar && (
              <div className="d-flex justify-content-between align-items-center mt-2 mb-0_5 text-small">
                <FormattedTag id="bet-slip.total-bet" className="text-extra-2 text-nowrap mr-1_5" />
                {totalBetAmount
                  ? <span className="text-right text-break">{mathRound(totalBetAmount)} {prepareCurrency(currency)}</span>
                  : <span className="text-extra-3">–</span>}
              </div>
            )}
            {isSystem && (
              <div className="d-flex justify-content-between align-items-center mt-2 mb-0_5 text-small">
                <FormattedTag id="bet-slip.total-bet" className="text-extra-2 text-nowrap mr-1_5" />
                {systemBetAmount
                  ? <span className="text-right text-break">{mathRound(systemBetAmount)} {prepareCurrency(currency)}</span>
                  : <span className="text-extra-3">–</span>}
              </div>
            )}
            <div className={classNames('d-flex justify-content-between align-items-center', {
              'mb-2': !isSuspendedState,
              'mb-1_5': isSuspendedState,
              'mt-1_5': isExpress,
            })}
            >
              <FormattedTag id="bet-slip.total-return" className="bet-slip-footer-mobile-return-title text-small text-extra-2" />
              {totalReturnAmount
                ? (
                  <div className="d-flex align-items-center">
                    {(totalReturnWithoutBonus || (isErrorState && !isStakesAmountExceed
                    && error && !!error.prevTotalReturn)) && (
                      <span className="bet-slip-footer-mobile-prev-total-return mr-1_5 text-small text-extra-3 text-right text-break">
                        {error && error.prevTotalReturnAmount
                          ? mathRound(error.prevTotalReturnAmount)
                          : mathRound(totalReturnWithoutBonus)} {prepareCurrency(currency)}
                      </span>
                    )}
                    <span className={classNames('bet-slip-footer-mobile-total-return font-weight-bold text-right text-break', {
                      'text-warning': isErrorState && !isStakesAmountExceed && !isOutOfBalance,
                    })}
                    >
                      {mathRound(totalReturnAmount)} {prepareCurrency(currency)}
                    </span>
                  </div>
                )
                : <Fragment>{isSystemInfoInProgress ? <Spinner isSmall /> : <span className="text-extra-3">–</span>}</Fragment>}
            </div>
          </>
        )}

        {(isSuspendedState || isBetFactorDecreasedState) && (
          <div className="bet-slip-footer-mobile-suspended text-small text-center position-relative pt-1_5">
            <FormattedTag tag="div" id={`bet-slip.${isSuspendedState ? 'suspended' : 'decreased'}.title`} />
            <FormattedTag tag="div" id="bet-slip.accept-changes.subtitle" className="mb-1_5 text-extra-2" />
            <FormattedTag
              id="bet-slip.accept-changes"
              tag={Button}
              size="sm"
              color="info"
              block
              onClick={this.onAcceptChangesClick}
            />
          </div>
        )}

        {error && isErrorState && !isStakesAmountExceed && (
          <div className="bet-slip-footer-mobile-error text-small text-center position-relative pt-1_5 mb-1_5">
            <div className="d-flex align-items-center justify-content-center">
              <IconWarning className="mr-0_5 flex-shrink-0" />
              {error && (
                <FormattedTag
                  id={errorIntlId}
                  values={{ amount: error.prevTotalBetAmount ? `${mathRound(error.prevTotalBetAmount)} ${prepareCurrency(currency)}` : null }}
                  className="font-weight-bold"
                />
              )}
            </div>
            {!isOutOfBalance && (
              <div className="d-flex align-items-center justify-content-center">
                <FormattedTag id="bet-slip.stake-limit-error.subtitle" className="text-extra-2" />
                {error && error.limit && (
                  <span className="ml-0_5 font-weight-bold">
                    {' '}
                    {mathRound(error.limit)} {prepareCurrency(currency)}
                  </span>
                )}
              </div>
            )}
          </div>
        )}

        {(isDefaultState || isErrorState) && (
          <ButtonWithLoader
            size="sm"
            color={isFreebetUsed ? 'warning' : 'primary'}
            isBlack={isFreebetUsed}
            isWhite={!isFreebetUsed}
            block
            disabled={!totalBetAmount || isMultipleRestrictedState || isExpressDeniedState || isPlaceBetDisabled}
            onClick={this.onPlaceBetClick}
            isLoading={isPlaceBetInProgress}
          >
            <FormattedMessage
              id={isErrorState && !isStakesAmountExceed && !isOutOfBalance ? 'bet-slip.place-amount' : `bet-slip.place-${isOneBet ? 'bet' : 'bets'}`}
              values={{ amount: isErrorState && !isStakesAmountExceed && !isOutOfBalance && totalBetAmount ? `${totalBetAmount} ${prepareCurrency(currency)}` : null }}
            />
          </ButtonWithLoader>
        )}

        <BetFactorChanges
          betFactorChanges={betFactorChanges}
          setBetFactorChanges={setBetFactorChanges}
        />
      </div>
    );
  }
}
