import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
  withSports,
  withFavorites,
  withUser,
  withFeedbackActions,
} from 'core/hocs';
import { isEmptyOrNil } from 'core/helpers';
import { USER_STORE_FIELDS } from 'core/constants';

import { MediaQuery } from 'components/media-query/media-query';
import { SearchedGamesInput } from 'components/searched-games/searched-games-input/searched-games-input';
import { FastNavigationItem } from 'components/fast-navigation-item/fast-navigation-item';
import { NavigationItemInplay } from 'components/navigation-item-inplay/navigation-item-inplay';
import { findSport, findCountryByLeague } from 'helpers/sports';
import { GA } from 'helpers/ga';
import { Sports } from './sports/sports';
import { EventNavigation } from './event-navigation/event-navigation';
import { PAGE_NAMES } from '../../constants';

import './left-sidebar.scss';

class LeftSidebarUI extends Component {
  static propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    pageName: PropTypes.string.isRequired,
    params: PropTypes.shape().isRequired,
    isSearchOpen: PropTypes.bool.isRequired,
    setSearchOpen: PropTypes.func.isRequired,
    sports: PropTypes.arrayOf(PropTypes.shape()),
    topLeagues: PropTypes.arrayOf(PropTypes.shape()),
    inplayEventsCount: PropTypes.number,
    isSportsInProgress: PropTypes.bool.isRequired,
    isFavoritesInProgress: PropTypes.bool.isRequired,
    favoriteSports: PropTypes.arrayOf(PropTypes.shape()),
    favoriteLeagues: PropTypes.arrayOf(PropTypes.shape()),
    search: PropTypes.string,
    sendSportClickFeedback: PropTypes.func.isRequired,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sports: null,
    topLeagues: null,
    inplayEventsCount: null,
    favoriteSports: null,
    favoriteLeagues: null,
    search: null,
  };

  state = {
    activeSportId: null,
    activeLeagueId: null,
    activeCountries: {},
    isActiveSportIdSet: false,
    isActiveLeagueIdSet: false,
  };

  static getDerivedStateFromProps(props, state) {
    const {
      pageName,
      params: { sportId, leagueId },
      sports,
      favoriteLeagues,
    } = props;
    const { isActiveSportIdSet, isActiveLeagueIdSet } = state;

    if (pageName === PAGE_NAMES.SPORT && !isActiveSportIdSet) {
      return {
        isActiveSportIdSet: true,
        activeSportId: sportId,
      };
    }

    if (pageName === PAGE_NAMES.LEAGUE && !isActiveLeagueIdSet && !isEmptyOrNil(sports) && !R.isNil(favoriteLeagues)) {
      const extendedState = {};
      const foundSport = findSport(sports, sportId);

      if (foundSport) {
        const foundCountry = findCountryByLeague(foundSport.countries, leagueId);

        if (foundCountry) {
          extendedState.activeCountries = {
            [sportId]: [foundCountry.countryId],
          };
        }
      }

      return {
        ...extendedState,
        isActiveLeagueIdSet: true,
        activeSportId: sportId,
        activeLeagueId: leagueId,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { pageName } = this.props;
    const { pageName: prevPageName } = prevProps;

    if (
      pageName !== prevPageName
      && (pageName === PAGE_NAMES.HOME || pageName === PAGE_NAMES.LIVE || pageName === PAGE_NAMES.WATCHLIST)
    ) {
      this.setActiveSportId(null);
      this.clearActiveLeagueId();
      this.clearActiveCountries();
      this.clearActiveIds();
    }

    if (pageName === PAGE_NAMES.SPORT && prevPageName === PAGE_NAMES.LEAGUE) {
      this.clearActiveLeagueId();
      this.clearActiveCountries();
    }

    if (pageName !== prevPageName && pageName === PAGE_NAMES.EVENT) {
      this.clearActiveIds();
    }
  }

  setActiveSportId = activeSportId => this.setState({ activeSportId });

  setActiveLeagueId = (sportId, leagueId) => this.setState({
    activeSportId: sportId,
    activeLeagueId: leagueId,
  });

  onFavoriteLeagueClick = (activeLeagueId, sportId) => {
    const foundSport = findSport(this.props.sports, sportId);
    const foundCountry = findCountryByLeague(foundSport.countries, activeLeagueId);

    this.setState({
      activeSportId: sportId,
      activeLeagueId,
      activeCountries: { [sportId]: [foundCountry.countryId] },
      isActiveSportIdSet: true,
      isActiveLeagueIdSet: true,
    });
  };

  clearActiveLeagueId = () => this.setState({ activeLeagueId: null });

  setActiveCountries = (sportId, countryId) => {
    const { activeCountries } = this.state;
    const activeCountriesBySport = activeCountries[sportId] || [];

    this.setState({
      activeCountries: {
        ...activeCountries,
        [sportId]: activeCountriesBySport.includes(countryId)
          ? R.without([countryId], activeCountriesBySport)
          : [...activeCountriesBySport, countryId],
      },
    });
  };

  clearActiveCountries = () => this.setState({ activeCountries: {} });

  clearActiveCountriesBySport = sportId => this.setState(prevState => ({
    activeCountries: R.dissoc(sportId, prevState.activeCountries),
  }));

  clearActiveCountriesExceptSport = sportId => this.setState(prevState => ({
    activeCountries: R.pick([sportId], prevState.activeCountries),
  }));

  clearActiveCountriesExceptSportAndCountry = (sportId, countryId) => this.setState(prevState => ({
    activeCountries: {
      [sportId]: R.filter(R.equals(countryId), prevState.activeCountries[sportId])
    },
  }));

  clearActiveIds = () => this.setState({
    isActiveSportIdSet: false,
    isActiveLeagueIdSet: false,
  });

  onHomeClick = () => {
    GA.event({
      category: 'navigation',
      label: 'home-click',
    });
  };

  onInplayClick = () => {
    GA.event({
      category: 'navigation',
      label: 'in-play-click',
    });
  };

  onWatchlistClick = () => {
    GA.event({
      category: 'watchlist',
      label: 'open',
    });
  };

  onCalendarClick = () => {
    GA.event({
      category: 'calendar',
      label: 'click',
    });
  };

  onBetsFeedClick = () => {
    GA.event({
      category: 'navigation',
      label: 'bets-feed-click',
    });
  };

  render() {
    const {
      isUserLoggedIn,
      sports,
      topLeagues,
      inplayEventsCount,
      isSportsInProgress,
      params,
      pageName,
      isSearchOpen,
      setSearchOpen,
      favoriteSports,
      favoriteLeagues,
      isFavoritesInProgress,
      search,
      sendSportClickFeedback,
      sendLeagueClickFeedback,
      sendEventClickFeedback,
    } = this.props;
    const {
      activeSportId,
      activeLeagueId,
      activeCountries,
    } = this.state;

    return (
      <div className="left-sidebar d-none d-lg-block bg-main-2 pb-4 flex-shrink-0">
        <MediaQuery up="lg">
          <SearchedGamesInput isSearchOpen={isSearchOpen} setSearchOpen={setSearchOpen} className="m-1_5 mt-2" />

          <div className="pb-2 text-small">
            <FastNavigationItem
              itemId="left-sidebar.home"
              link="/"
              page={PAGE_NAMES.HOME}
              activePage={pageName}
              className="pl-4"
              handleClick={this.onHomeClick}
            />
            <NavigationItemInplay
              activePage={pageName}
              inplayEventsCount={inplayEventsCount}
              handleClick={this.onInplayClick}
            />
            <FastNavigationItem
              itemId="left-sidebar.watchlist"
              link="/watchlist"
              page={PAGE_NAMES.WATCHLIST}
              activePage={pageName}
              className="pl-4"
              handleClick={this.onWatchlistClick}
            />
            <FastNavigationItem
              itemId="left-sidebar.bets-feed"
              link="/bets-feed"
              page={PAGE_NAMES.BETS_FEED}
              activePage={pageName}
              className="pl-4"
              handleClick={this.onBetsFeedClick}
            />
          </div>

          <div className="left-sidebar-divider" />

          {pageName === PAGE_NAMES.EVENT
            ? (
              <EventNavigation
                isUserLoggedIn={isUserLoggedIn}
                params={params}
                pageName={pageName}
                sports={sports}
                topLeagues={topLeagues}
                isSportsInProgress={isSportsInProgress}
                activeSportId={activeSportId}
                activeLeagueId={activeLeagueId}
                activeCountries={activeCountries}
                setActiveSportId={this.setActiveSportId}
                setActiveCountries={this.setActiveCountries}
                setActiveLeagueId={this.setActiveLeagueId}
                clearActiveLeagueId={this.clearActiveLeagueId}
                clearActiveCountries={this.clearActiveCountries}
                clearActiveCountriesBySport={this.clearActiveCountriesBySport}
                clearActiveCountriesExceptSport={this.clearActiveCountriesExceptSport}
                clearActiveCountriesExceptSportAndCountry={this.clearActiveCountriesExceptSportAndCountry}
                favoriteSports={favoriteSports}
                favoriteLeagues={favoriteLeagues}
                isFavoritesInProgress={isFavoritesInProgress}
                onFavoriteLeagueClick={this.onFavoriteLeagueClick}
                search={search}
                sendSportClickFeedback={sendSportClickFeedback}
                sendLeagueClickFeedback={sendLeagueClickFeedback}
                sendEventClickFeedback={sendEventClickFeedback}
              />
            )
            : (
              <Sports
                isUserLoggedIn={isUserLoggedIn}
                params={params}
                pageName={pageName}
                sports={sports}
                topLeagues={topLeagues}
                isSportsInProgress={isSportsInProgress}
                activeSportId={activeSportId}
                activeLeagueId={activeLeagueId}
                activeCountries={activeCountries}
                setActiveSportId={this.setActiveSportId}
                setActiveCountries={this.setActiveCountries}
                setActiveLeagueId={this.setActiveLeagueId}
                clearActiveLeagueId={this.clearActiveLeagueId}
                clearActiveCountries={this.clearActiveCountries}
                clearActiveCountriesBySport={this.clearActiveCountriesBySport}
                clearActiveCountriesExceptSport={this.clearActiveCountriesExceptSport}
                clearActiveCountriesExceptSportAndCountry={this.clearActiveCountriesExceptSportAndCountry}
                favoriteSports={favoriteSports}
                favoriteLeagues={favoriteLeagues}
                isFavoritesInProgress={isFavoritesInProgress}
                onFavoriteLeagueClick={this.onFavoriteLeagueClick}
                sendSportClickFeedback={sendSportClickFeedback}
                sendLeagueClickFeedback={sendLeagueClickFeedback}
              />
            )}
        </MediaQuery>
      </div>
    );
  }
}

export const LeftSidebar = withFeedbackActions(
  withFavorites(withSports(withUser(LeftSidebarUI, [USER_STORE_FIELDS.IS_USER_LOGGED_IN])))
);
