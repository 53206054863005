import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import { withLocale } from 'core/hocs';
import { AI_OUTCOME_SECTION } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { SportTypeIcon } from 'components/sport-type-icon/sport-type-icon';
import { getTopLeagueIcon } from 'helpers/top-leagues';

const SHOW_MORE_AMOUNT = 3;

class RestrictionEventsUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    events: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    closeModal: PropTypes.func.isRequired,
    sendSportClickFeedback: PropTypes.func.isRequired,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  state = {
    isShowMoreAvailable: this.props.events.length > SHOW_MORE_AMOUNT,
  };

  onShowMoreClick = (e) => {
    e.stopPropagation();
    this.setState({ isShowMoreAvailable: false });
  };

  onSportClick = (e) => {
    const { dataset: { sportId } } = e.currentTarget;
    const { closeModal, sendSportClickFeedback } = this.props;
    closeModal();
    sendSportClickFeedback(sportId);
  };

  onLeagueClick = (e) => {
    const { dataset: { leagueId } } = e.currentTarget;
    const { closeModal, sendLeagueClickFeedback } = this.props;
    closeModal();
    sendLeagueClickFeedback(leagueId);
  };

  onEventClick = (e) => {
    const { dataset: { eventId } } = e.currentTarget;
    const { closeModal, sendEventClickFeedback } = this.props;
    closeModal();
    sendEventClickFeedback(eventId, false);
  };

  render() {
    const { locale, events } = this.props;
    const { isShowMoreAvailable } = this.state;
    const showedEvents = isShowMoreAvailable ? R.take(SHOW_MORE_AMOUNT, events) : events;

    return (
      <div>
        <FormattedTag id="freebet.events" tag="div" className="caption text-center text-extra-2" />
        <div className="freebets-modal-divider mt-2 mb-1_5 mt-sm-2_5 mb-sm-2" />
        {showedEvents.map(({ id, type, desc }, idx) => {
          let linkUrl;
          let linkName;
          let onClick;
          const dataAttrs = {};
          const isSport = type === 'sport';
          const LeagueTypeIcon = !isSport && getTopLeagueIcon(desc.tournament.id);

          if (isSport) {
            linkUrl = `/sport/${desc.sport.id}`;
            linkName = desc.sport.name;
            onClick = this.onSportClick;
            dataAttrs['data-sport-id'] = desc.sport.id;
          } else if (type === 'tournament') {
            linkUrl = `/league/${desc.sport.id}/${desc.tournament.id}`;
            linkName = desc.tournament.name;
            onClick = this.onLeagueClick;
            dataAttrs['data-league-id'] = desc.tournament.id;
          } else if (type === 'event') {
            linkUrl = `/event/${desc.event.id}?isLive=false&sectionBeforeEventPage=${AI_OUTCOME_SECTION.FREEBETS}`;
            linkName = desc.event.name;
            onClick = this.onEventClick;
            dataAttrs['data-event-id'] = desc.event.id;
          }

          return (
            <RouteLink
              key={id}
              locale={locale}
              to={linkUrl}
              className={classNames('d-flex align-items-center px-2 px-sm-3', { 'mb-1_5': idx !== showedEvents.length - 1 })}
              onClick={onClick}
              {...dataAttrs}
            >
              {isSport
                ? <SportTypeIcon sportId={desc.sport.id} />
                : <LeagueTypeIcon />}

              <div className="ml-2_5 text-small">{linkName}</div>
              {isShowMoreAvailable && idx === showedEvents.length - 1 && (
                <FormattedTag
                  id="freebet.show-more"
                  values={{ amount: `+${events.length - showedEvents.length}` }}
                  onClick={this.onShowMoreClick}
                  className="text-small font-weight-bold text-extra-2 ml-1 flex-shrink-0 align-self-end"
                  role="button"
                />
              )}
            </RouteLink>
          );
        })}
      </div>
    );
  }
}

export const RestrictionEvents = withLocale(RestrictionEventsUI);
