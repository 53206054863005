import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withWatchlist, withWatchlistActions } from 'core/hocs';
import { AI_OUTCOME_SECTION } from 'core/constants';

import { IconStar } from 'components/icons/icon-star/icon-star';
import { IconStarEmpty } from 'components/icons/icon-star-empty/icon-star-empty';
import { SportTypeIcon } from 'components/sport-type-icon/sport-type-icon';
import { filterByLiveStream, filterGamesWithLiveStream } from 'helpers/games';
import { prepareInplayGames } from 'helpers/watchlist';
import { GA } from 'helpers/ga';
import { GamesPreloader } from '../../games-preloader/games-preloader';
import { EmptyState } from '../../empty-state/empty-state';
import { GameItem } from '../../game-item/game-item';
import colors from '../../../../../customizations/js/color-variables';

class GamesWithLiveStreamUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()),
    isInProgress: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      eventId: PropTypes.string,
    }).isRequired,
    watchlist: PropTypes.arrayOf(PropTypes.shape()),
    updateWatchlist: PropTypes.func.isRequired,
    statuses: PropTypes.shape(),
    streams: PropTypes.shape(),
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    items: null,
    watchlist: null,
    statuses: null,
    streams: null,
  };

  onEventClick = () => {
    GA.event({
      category: 'go-to-event-page',
      label: 'left-sidebar-live-event-tv',
    });
  };

  render() {
    const {
      locale,
      items,
      isInProgress,
      params: { eventId },
      watchlist,
      updateWatchlist,
      statuses,
      streams,
      sendEventClickFeedback,
    } = this.props;

    const { preparedWatchlist, preparedInplayGames } = prepareInplayGames(items || [], watchlist || []);
    const filteredGames = filterGamesWithLiveStream(preparedInplayGames, streams);
    const filteredWatchlist = filterByLiveStream(preparedWatchlist, streams);

    if (isInProgress) {
      return <GamesPreloader />;
    }

    if (!filteredWatchlist.length && !filteredGames.length) {
      return <EmptyState />;
    }

    return (
      <Fragment>
        <div className="list-of-games label d-flex flex-column text-capitalize">
          <div className="game-title w-100 py-1 px-2 d-flex align-items-center text-extra-3">
            <IconStar className="icon-star mr-1" color={colors.cpSecondary3} />
            <FormattedMessage id="left-sidebar.watchlist" />
          </div>

          {filteredWatchlist.length
            ? (
              <div>
                {filteredWatchlist.map(game => (
                  <GameItem
                    key={game.desc.id}
                    eventData={game}
                    locale={locale}
                    isActive={eventId === game.desc.id}
                    withWathlistToggle
                    updateWatchlist={updateWatchlist}
                    isInWatchlist
                    hasLiveStream={Boolean(streams && streams[game.desc.id])}
                    sendEventClickFeedback={sendEventClickFeedback}
                    sectionBeforeEventPage={AI_OUTCOME_SECTION.LEFT_SIDEBAR_LIVE_TV}
                  />
                ))}
              </div>
            )
            : (
              <div className="p-2_5 text-center text-small font-weight-normal text-extra-2 d-flex align-items-center flex-wrap">
                <FormattedMessage id="left-sidebar.watchlist.title.1" />
                <IconStarEmpty className="icon-star-empty mx-0_5 flex-shrink-0" />
                <FormattedMessage id="left-sidebar.watchlist.title.2" />
              </div>
            )}
        </div>

        {filteredGames && filteredGames.map(games => (
          <div key={games[0].desc.tournament.id} className="list-of-games label d-flex flex-column text-capitalize">
            <div className="game-title w-100 py-1 px-2 d-flex align-items-center text-extra-3">
              <SportTypeIcon sportId={games[0].desc.sport.id} width="12" height="12" isActive className="sport-type-icon mr-1" />
              <span>
                {games[0].desc.tournament.name}
              </span>
            </div>

            <div>
              {games.map(game => (
                <GameItem
                  key={game.desc.id}
                  eventData={game}
                  locale={locale}
                  isActive={eventId === game.desc.id}
                  withWathlistToggle
                  updateWatchlist={updateWatchlist}
                  statuses={statuses}
                  hasLiveStream={Boolean(streams && streams[game.desc.id])}
                  onEventClick={this.onEventClick}
                  sendEventClickFeedback={sendEventClickFeedback}
                  sectionBeforeEventPage={AI_OUTCOME_SECTION.LEFT_SIDEBAR_LIVE_TV}
                />
              ))}
            </div>
          </div>
        ))}
      </Fragment>
    );
  }
}

export const GamesWithLiveStream = withWatchlistActions(withWatchlist(GamesWithLiveStreamUI));
