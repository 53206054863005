import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportEfighting = ({
  className, isActive, width, height,
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7 17.9951C6.99988 17.0041 7.10364 15.7326 7.48423 14.4H7.09157C6.59741 14.4 6.37509 14.8023 6.37509 15.3V17.1C6.37509 17.5658 6.56986 17.9481 7 17.9951ZM15.7456 10.9145C15.8949 10.2627 16.0007 9.51883 16.0007 8.721C16.0007 7.038 15.9292 6.219 15.8041 5.553C15.7863 5.553 15.7773 5.553 15.7595 5.544C13.7578 4.878 11.6757 4.581 9.59364 4.68C8.20857 4.743 6.83243 4.986 5.50097 5.382C5.42948 5.796 5.40267 6.21 5.42948 6.624C5.45629 7.308 5.61713 7.983 5.89415 8.622C6.03712 8.964 5.88521 9.369 5.54565 9.513C5.45629 9.558 5.36693 9.567 5.27757 9.567C5.01842 9.567 4.76822 9.423 4.66098 9.162C4.32142 8.37 4.12483 7.542 4.08908 6.687C4.05334 6.093 4.10695 5.508 4.23206 4.923C3.48144 5.103 2.07849 5.58 2.00789 6.615C1.9087 8.037 2.84698 10.305 4.38397 11.529C5.25969 12.222 6.06393 12.771 6.59026 13.104C6.752 13.068 6.92268 13.05 7.09157 13.05H7.96575C8.05297 12.849 8.14762 12.6482 8.25029 12.4484C10.1013 8.83921 12.8911 11.3498 12.8911 11.3498C13.065 11.508 13.4023 11.6496 13.6271 11.6413H14.3689C14.6007 11.6413 14.9299 11.508 15.1119 11.3498C15.1119 11.3498 15.3551 11.1304 15.7456 10.9145ZM4.41078 4.311L4.72354 4.212C6.28733 3.708 7.90474 3.411 9.53109 3.33C11.5864 3.24 13.6148 3.591 15.6254 4.113V2.673C15.6254 2.07 15.1339 1.575 14.5352 1.575H13.6327C13.4182 1.575 13.2395 1.728 13.1948 1.935L12.9804 3.06L12.8553 2.43L12.8106 2.196L12.5425 0.837C12.4978 0.63 12.3191 0.477 12.1046 0.477H10.8179C10.6034 0.477 10.4247 0.63 10.38 0.846L10.0404 2.637L9.99576 2.871L9.49445 0.36C9.45067 0.153 9.27105 0 9.05748 0H7.76177C7.52943 0 7.33284 0.189 7.31497 0.423L7.22561 1.98L7.15412 3.186L6.56435 1.341C6.51073 1.152 6.33201 1.026 6.14346 1.026H5.46522C4.88438 1.026 4.41078 1.503 4.41078 2.088V4.311Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M17.9146 15.5269C17.5848 15.5269 17.3145 15.255 17.3145 14.922C17.3145 14.59 17.5848 14.317 17.9146 14.317C18.2454 14.317 18.5156 14.59 18.5156 14.922C18.5156 15.255 18.2454 15.5269 17.9146 15.5269ZM16.4135 14.62C16.0836 14.62 15.8124 14.348 15.8124 14.016C15.8124 13.683 16.0836 13.41 16.4135 13.41C16.7443 13.41 17.0135 13.683 17.0135 14.016C17.0135 14.348 16.7443 14.62 16.4135 14.62ZM16.4135 16.4339C16.0836 16.4339 15.8124 16.1619 15.8124 15.8289C15.8124 15.4969 16.0836 15.225 16.4135 15.225C16.7443 15.225 17.0135 15.4969 17.0135 15.8289C17.0135 16.1619 16.7443 16.4339 16.4135 16.4339ZM11.9071 15.6779H11.457V16.1319C11.457 16.3799 11.2524 16.5849 11.006 16.5849C10.7596 16.5849 10.5559 16.3799 10.5559 16.1319V15.6779H10.1049C9.8595 15.6779 9.65484 15.4729 9.65484 15.225C9.65484 14.977 9.8595 14.771 10.1049 14.771H10.5559V14.317C10.5559 14.069 10.7596 13.864 11.006 13.864C11.2524 13.864 11.457 14.069 11.457 14.317V14.771H11.9071C12.1544 14.771 12.3581 14.977 12.3581 15.225C12.3581 15.4729 12.1544 15.6779 11.9071 15.6779ZM18.924 13.417C17.3384 10.7922 14.953 12.6181 14.953 12.6181C14.7971 12.7331 14.5149 12.8301 14.3162 12.8301H13.6804C13.4877 12.8361 13.1986 12.7331 13.0495 12.6181C13.0495 12.6181 10.6583 10.7922 9.07167 13.417C7.48609 16.0349 8.12886 18.8888 8.12886 18.8888C8.23715 19.5838 8.60971 20.0498 9.30017 19.9958C9.99759 19.9358 11.5047 18.1148 11.5047 18.1148C11.6249 17.9638 11.8892 17.8368 12.0819 17.8368H15.9147C16.1065 17.8368 16.3717 17.9638 16.4919 18.1148C16.4919 18.1148 17.999 19.9358 18.6965 19.9958C19.3929 20.0498 19.7595 19.5838 19.8737 18.8888C19.8737 18.8888 20.5105 16.0349 18.924 13.417Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
  </svg>
);

IconSportEfighting.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportEfighting.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
