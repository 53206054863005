import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import { convertStringifiedBoolean, mathRound } from 'core/helpers';
import {
  BET_TYPE,
  BET_SLIP_STATE,
  BET_SLIP_ERRORS,
  AI_OUTCOME_SECTION,
} from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { IconClose } from 'components/icons/icon-close/icon-close';
import { IconWarning } from 'components/icons/icon-warning/icon-warning';
import { IconFreebet } from 'components/icons/icon-freebet/icon-freebet';
import { prepareCurrency } from 'helpers/currency';
import { GA } from 'helpers/ga';
import { BetAmountInput } from '../bet-amount-input/bet-amount-input';
import { BetSlipPresets } from '../bet-slip-presets/bet-slip-presets';

import './stake-item.scss';

const TOOLTIP_ID = 'stake-item-freebet-tooltip-id';

export class StakeItem extends Component {
  static propTypes = {
    stakesAmount: PropTypes.number.isRequired,
    stake: PropTypes.shape().isRequired,
    toggleStake: PropTypes.func.isRequired,
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    currency: PropTypes.string.isRequired,
    setBetAmount: PropTypes.func.isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    error: PropTypes.shape(),
    isPlaceBetInProgress: PropTypes.bool.isRequired,
    availableFreebetId: PropTypes.string,
    setUsedFreebet: PropTypes.func.isRequired,
    isFreebetUsed: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    setIsPlaceBetDisabled: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    availableFreebetId: null,
  };

  onRemoveClick = () => {
    const { toggleStake, stake } = this.props;
    toggleStake(stake);
    GA.event({
      category: 'bet-slip',
      label: 'delete-outcome',
    });
  };

  onFreebetClick = () => {
    const { setUsedFreebet, availableFreebetId, isFreebetUsed } = this.props;
    setUsedFreebet(isFreebetUsed ? null : availableFreebetId);
  };

  onEventClick = (e) => {
    const { dataset: { isOutright } } = e.currentTarget;
    const { sendEventClickFeedback } = this.props;

    sendEventClickFeedback(e);

    if (!convertStringifiedBoolean(isOutright)) {
      GA.event({
        category: 'go-to-event-page',
        label: 'bet-slip-event-click',
      });
    }
  };

  render() {
    const {
      locale,
      stakesAmount,
      stake: {
        stakeId,
        betFactor,
        teamA,
        teamB,
        betAmount,
        returnAmount,
        marketName,
        outcomeName,
        error,
        isMultipleRestricted,
        isBetFactorDecreased,
        isBetFactorIncreased,
        isSuspended,
        isExpressDenied,
        isOutright,
        outrightName,
        eventId,
        isLive,
      },
      betType,
      currency,
      setBetAmount,
      betSlipState,
      isPlaceBetInProgress,
      availableFreebetId,
      isFreebetUsed,
      setIsPlaceBetDisabled,
    } = this.props;
    const isOrdinar = betType === BET_TYPE.ORDINAR;
    const isOneBet = stakesAmount === 1;
    const teams = isOutright ? outrightName : `${teamA} - ${teamB}`;
    const isOutOfBalance = error && error.errorId === BET_SLIP_ERRORS.BET_AMOUNT_OUT_OF_BALANCE;
    let errorIntlId;
    const LinkComponent = isOutright ? 'span' : RouteLink;

    if (error) {
      if (error.errorId === BET_SLIP_ERRORS.BET_AMOUNT_LESS_THAN_LIMIT) {
        errorIntlId = 'bet-slip.stake-limit-error.less.title';
      } else if (error.errorId === BET_SLIP_ERRORS.BET_AMOUNT_MORE_THAN_LIMIT) {
        errorIntlId = 'bet-slip.stake-limit-error.more.title';
      } else if (isOutOfBalance) {
        errorIntlId = 'bet-slip.out-of-balance-error.title';
      }
    }

    return (
      <div className={classNames('stake-item pr-1 py-2 bg-main-4 position-relative', {
        'is-suspended': !isOneBet && isSuspended,
      })}
      >
        <div className="d-flex justify-content-between align-items-center font-weight-bold mb-0_5 pr-3_5">
          <div className="d-flex align-items-center mr-1 overflow-hidden">
            <span title={outcomeName} className="text-truncate">
              {outcomeName}
            </span>
            {(isMultipleRestricted || isExpressDenied) && <IconWarning className="ml-0_5 flex-shrink-0" />}
          </div>

          {!!betFactor && (
            <span className={classNames('stake-item-bet-factor position-relative', {
              'is-decreased': isBetFactorDecreased && !isSuspended,
              'is-increased': isBetFactorIncreased && !isSuspended,
            })}
            >
              {betFactor}
            </span>
          )}
        </div>
        <div title={marketName} className="text-extra-2 caption text-truncate mb-0_25">{marketName}</div>
        {teams && (
          <LinkComponent
            locale={locale}
            to={`/event/${eventId}?isLive=${isLive}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.BET_SLIP}`}
            className={classNames('caption', { 'stake-item-event-link': !isOutright, 'text-extra-2': isOutright })}
            onClick={this.onEventClick}
            data-is-outright={isOutright}
            data-event-id={eventId}
            data-is-live={isLive}
          >
            {teams}
          </LinkComponent>
        )}

        {isOrdinar && (
          <div className="mt-1_5 pr-1">
            <div className="d-flex align-items-center">
              <BetAmountInput
                stakeId={stakeId}
                currency={currency}
                betAmount={betAmount}
                setBetAmount={setBetAmount}
                betSlipState={betSlipState}
                isPlaceBetInProgress={isPlaceBetInProgress}
                isFreebetUsed={isFreebetUsed}
                error={error}
                setIsPlaceBetDisabled={setIsPlaceBetDisabled}
              />

              {availableFreebetId && (
                <>
                  <div
                    id={TOOLTIP_ID}
                    role="button"
                    tabIndex="0"
                    onClick={this.onFreebetClick}
                    onKeyPress={this.onFreebetClick}
                    className={classNames('stake-item-freebet d-flex align-items-center justify-content-center', { 'active bg-warning': isFreebetUsed })}
                  >
                    <IconFreebet className="stake-item-icon-freebet" />
                  </div>
                  {!isFreebetUsed && (
                    <UncontrolledTooltip placement="top" target={TOOLTIP_ID}>
                      <FormattedTag id="freebet.tooltip" className="text-small font-weight-bold" />
                    </UncontrolledTooltip>
                  )}
                </>
              )}
            </div>

            <BetSlipPresets
              stakeId={stakeId}
              currency={currency}
              setBetAmount={setBetAmount}
              isPlaceBetInProgress={isPlaceBetInProgress}
              isFreebetUsed={isFreebetUsed}
            />
            <div className="d-flex justify-content-between align-items-center mt-2">
              <FormattedTag id="bet-slip.return" className="stake-item-return-title text-small text-extra-2 text-nowrap mr-1_5" />
              {returnAmount
                ? (
                  <div className="d-flex align-items-center">
                    {error && !!error.prevReturnAmount && (
                      <span className="stake-item-prev-return mr-1_5 text-small text-extra-3 text-right text-break">
                        {mathRound(error.prevReturnAmount)} {prepareCurrency(currency)}
                      </span>
                    )}
                    <span className="font-weight-bold text-right text-break">{mathRound(returnAmount)} {prepareCurrency(currency)}</span>
                  </div>
                )
                : <span className="text-extra-3">–</span>}
            </div>
            {error && (
              <div className="stake-item-error text-small text-center position-relative pt-2 mt-2">
                <div className="text-small">
                  <IconWarning className="mr-0_5 flex-shrink-0" />
                  <FormattedTag
                    id={errorIntlId}
                    className="font-weight-bold"
                    values={{ amount: error.prevBetAmount ? `${mathRound(error.prevBetAmount)} ${prepareCurrency(currency)}` : null }}
                  />
                </div>
                {!isOutOfBalance && (
                  <div className="d-flex align-items-center justify-content-center">
                    <FormattedTag id="bet-slip.stake-limit-error.subtitle" className="text-extra-2 text-small" />
                    {error && error.limit && (
                      <span className="ml-0_5 font-weight-bold">
                        {' '}
                        {mathRound(error.limit)} {prepareCurrency(currency)}
                      </span>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <div
          role="button"
          tabIndex="0"
          onClick={this.onRemoveClick}
          onKeyPress={this.onRemoveClick}
          className="icon-close d-flex justify-content-center align-items-center position-absolute"
        >
          <IconClose />
        </div>

        {!isOneBet && isSuspended && (
          <FormattedTag
            id="bet-slip.suspended"
            className="stake-item-suspended-label h6 d-flex align-items-center position-absolute bg-info"
          />
        )}
      </div>
    );
  }
}
