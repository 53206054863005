import { SPORTS } from '../constants';

const FIRST_HALF = 6;
const SECOND_HALF = 7;
const FIRST_EXTRA = 41;
const SECOND_EXTRA = 42;
const NOT_STARTED_MATCH_TIME = ['0:00', '00:00'];
const FOOTBALL_SPORTS = [
  SPORTS.FOOTBALL,
  SPORTS.FIFA,
  SPORTS.FIFA18,
];

const convertTimeStringToSeconds = (time) => {
  if (!time) {
    return 0;
  }

  const [minutes, seconds] = time.split(':');

  return Number(minutes) * 60 + Number(seconds);
};

const prepareServerTs = (clientTs, timestamp) => {
  let serverTs = timestamp;
  const lengthDiff = String(clientTs).length - String(serverTs).length;
  const tsMultiplier = lengthDiff && 10 ** lengthDiff;

  if (tsMultiplier) {
    serverTs *= tsMultiplier;
  }

  return serverTs;
};

export const prepareTime = ({
  stopped,
  timestamp,
  match_time: matchTime,
  remaining_time: remainingTime,
}, sportId, eventStatus) => {
  try {
    if ((stopped && NOT_STARTED_MATCH_TIME.includes(matchTime)) || (!matchTime && !remainingTime)) {
      return null;
    }

    let seconds;
    const matchSeconds = convertTimeStringToSeconds(matchTime);

    if (stopped) {
      seconds = matchSeconds;
    } else {
      const clientTs = Date.now();
      const serverTs = prepareServerTs(clientTs, timestamp);
      const tsDiff = parseInt((clientTs - serverTs) / 1000, 10);
      let correctionSeconds = tsDiff + matchSeconds;

      if (remainingTime) {
        correctionSeconds = Math.min(correctionSeconds, convertTimeStringToSeconds(remainingTime));
      }

      seconds = correctionSeconds;
    }

    let minute = Math.ceil(seconds / 60);

    if (FOOTBALL_SPORTS.includes(sportId)) {
      if (eventStatus === FIRST_HALF) {
        minute = minute > 45 ? '45+' : Math.max(1, minute);
      } else if (eventStatus === SECOND_HALF) {
        minute = minute > 90 ? '90+' : Math.max(46, minute);
      } else if (eventStatus === FIRST_EXTRA) {
        minute = minute > 105 ? '105+' : Math.max(91, minute);
      } else if (eventStatus === SECOND_EXTRA) {
        minute = minute > 120 ? '120+' : Math.max(106, minute);
      }
    }

    return minute;
  } catch (error) {
    console.log(error);

    return null;
  }
};
