import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { withCoreComponent } from 'core/hocs';
import { LiveWidget as LiveWidgetCore } from 'core/components';
import { LIVE_WIDGET_TYPES, POST_MESSAGE_TYPES } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { Tabs, Tab } from 'components/tabs/tabs';
import { Spinner } from 'components/spinner/spinner';
import { IconLiveStreamsStub } from 'components/icons/icon-live-streams-stub/icon-live-streams-stub';
import { IconLiveStatsStub } from 'components/icons/icon-live-stats-stub/icon-live-stats-stub';
import { GA } from 'helpers/ga';
import { LiveStreams } from './live-streams/live-streams';
import { LiveStats } from './live-stats/live-stats';
import { LiveWidgetHeader } from './live-widget-header/live-widget-header';
import { PAGE_NAMES } from '../../constants';

import './live-widget.scss';

class LiveWidgetUI extends Component {
  static propTypes = {
    parent: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
    params: PropTypes.shape().isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
    isLiveStreamsEnabled: PropTypes.bool.isRequired,
    isPinned: PropTypes.bool.isRequired,
    streams: PropTypes.PropTypes.shape(),
    stats: PropTypes.PropTypes.shape(),
    isInProgress: PropTypes.bool.isRequired,
    setActiveLiveWidgetItem: PropTypes.func.isRequired,
    activeItem: PropTypes.string,
    setLiveWidgetType: PropTypes.func.isRequired,
    type: PropTypes.oneOf([
      LIVE_WIDGET_TYPES.LIVE_STREAMS,
      LIVE_WIDGET_TYPES.LIVE_STATS,
    ]).isRequired,
    setIsLiveWidgetPinned: PropTypes.func.isRequired,
    eventData: PropTypes.shape(),
    logoUrl: PropTypes.string.isRequired,
    logoUrl2: PropTypes.string.isRequired,
    liveTrackerProviders: PropTypes.string.isRequired,
    isUserIdentified: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    streams: null,
    stats: null,
    activeItem: null,
    eventData: null,
  };

  state = {
    isShowed: false,
  };

  componentDidMount() {
    const { pageName } = this.props;

    if (pageName === PAGE_NAMES.EVENT) {
      this.setIsShowed(true);
      this.setActiveItem();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  componentDidUpdate(prevProps) {
    const {
      pageName,
      params: { eventId },
      isPinned,
      activeItem,
      isInProgress,
      streams,
      eventData,
      isUserLoggedIn,
      isLiveStreamsEnabled,
      setLiveWidgetType,
    } = this.props;
    const {
      pageName: prevPageName,
      params: { eventId: prevEventId },
      activeItem: prevActiveItem,
      isInProgress: prevIsInProgress,
    } = prevProps;

    if (pageName === PAGE_NAMES.EVENT && eventId && eventId !== prevEventId && !isPinned) {
      this.setIsShowed(true);
      this.setActiveItem();
    }

    if (prevPageName === PAGE_NAMES.EVENT && pageName !== PAGE_NAMES.EVENT && !isPinned) {
      this.setIsShowed(false);
    }

    if ((prevActiveItem !== activeItem && (isUserLoggedIn && isLiveStreamsEnabled ? streams && eventData : eventData))
    || (activeItem && prevIsInProgress && !isInProgress)) {
      const hasLiveStream = streams && streams[eventId];
      const hasLiveStats = eventData && eventData.desc && eventData.desc.betradar_id;

      if (hasLiveStream) {
        setLiveWidgetType(LIVE_WIDGET_TYPES.LIVE_STREAMS);
      } else if (hasLiveStats) {
        setLiveWidgetType(LIVE_WIDGET_TYPES.LIVE_STATS);
      }
    }
  }

  setIsShowed = isShowed => this.setState({ isShowed });

  setActiveItem = () => {
    const { params: { eventId }, setActiveLiveWidgetItem } = this.props;
    setActiveLiveWidgetItem(eventId);
  };

  setIsLiveWidgetPinned = (newIsPinned) => {
    const { setIsLiveWidgetPinned, isPinned, pageName } = this.props;

    if (!newIsPinned && isPinned) {
      if (pageName === PAGE_NAMES.EVENT) {
        this.setActiveItem();
      } else {
        this.setIsShowed(false);
      }
    }

    setIsLiveWidgetPinned(newIsPinned);
  };

  onLoginClick = () => {
    if (window && window.parent && window.parent.postMessage) {
      window.parent.postMessage({ type: POST_MESSAGE_TYPES.UNAUTHORIZED }, '*');
    }
  };

  onTabClick = (tab) => {
    let label;

    if (tab === LIVE_WIDGET_TYPES.LIVE_STREAMS) {
      label = 'live-stream';
    } else if (LIVE_WIDGET_TYPES.LIVE_STATS) {
      label = 'live-stats-widget';
    }

    if (label) {
      GA.event({
        category: 'event-page',
        label,
      });
    }
  };

  render() {
    const {
      parent,
      isUserLoggedIn,
      isLiveStreamsEnabled,
      type,
      streams,
      stats,
      isInProgress,
      setActiveLiveWidgetItem,
      activeItem: activeEventId,
      isPinned,
      eventData,
      setLiveWidgetType,
      logoUrl,
      logoUrl2,
      liveTrackerProviders,
      isUserIdentified,
    } = this.props;
    const { isShowed } = this.state;
    const isLiveStreams = type === LIVE_WIDGET_TYPES.LIVE_STREAMS;
    const isLiveStats = type === LIVE_WIDGET_TYPES.LIVE_STATS;
    const isHeaderShowed = (isLiveStreams && isUserLoggedIn) || isLiveStats;
    const items = isLiveStreams ? streams : stats;
    const activeItem = items && activeEventId ? items[activeEventId] : null;

    if (!isShowed) {
      return null;
    }

    return (
      <div className={classNames('live-widget position-relative bg-main-2 mt-1 rounded', {
        'with-tabs': isLiveStreamsEnabled,
      })}
      >
        {isLiveStreamsEnabled && (
          <Fragment>
            <Tabs activeTab={type} toggleTab={setLiveWidgetType} className="">
              <Tab tabId={LIVE_WIDGET_TYPES.LIVE_STREAMS} label="live-widget.tab.1" onClick={this.onTabClick} />
              <Tab tabId={LIVE_WIDGET_TYPES.LIVE_STATS} label="live-widget.tab.2" onClick={this.onTabClick} />
            </Tabs>
          </Fragment>
        )}

        {isHeaderShowed && (
          <LiveWidgetHeader
            items={items}
            isPinned={isPinned}
            setIsLiveWidgetPinned={this.setIsLiveWidgetPinned}
            activeItem={activeItem}
            setActiveLiveWidgetItem={setActiveLiveWidgetItem}
            eventData={eventData}
            isInProgress={isInProgress}
            logoUrl={logoUrl}
            logoUrl2={logoUrl2}
          />
        )}

        <div className={classNames({ 'd-none': isInProgress && !isPinned })}>
          {isLiveStreamsEnabled && isLiveStreams && (
            <Fragment>
              {isUserLoggedIn
                ? (
                  <Fragment>
                    {activeItem
                      ? <LiveStreams activeItem={activeItem} parent={parent} />
                      : (
                        <div className="d-flex flex-column align-items-center justify-content-center text-center px-2 pb-2_5 pt-5">
                          <IconLiveStreamsStub className="mb-2" />
                          <FormattedTag id="live-streams.empty.event" className="text-extra-3" />
                        </div>
                      )}
                  </Fragment>
                )
                : (
                  <div className="d-flex flex-column align-items-center justify-content-center py-8_5">
                    <FormattedTag id="live-streams.not-logged.title" className="mt-0_5 mb-2 font-weight-bold" />
                    <FormattedTag
                      tag={Button}
                      id="live-streams.not-logged.button"
                      color="primary"
                      className="live-widget-button text-small mb-0_5"
                      onClick={this.onLoginClick}
                    />
                  </div>
                )}
            </Fragment>
          )}

          {isLiveStats && eventData && eventData.desc && (
            <Fragment>
              {eventData.desc.betradar_id
                ? (
                  <LiveStats
                    key={eventData.desc.betradar_id}
                    eventData={eventData}
                    liveTrackerProviders={liveTrackerProviders}
                    isUserIdentified={isUserIdentified}
                  />
                )
                : (
                  <div className="d-flex flex-column align-items-center justify-content-center text-center px-2 pb-2_5 pt-5">
                    <IconLiveStatsStub className="mb-2" />
                    <FormattedTag id="live-stats.empty.event" className="text-extra-3" />
                  </div>
                )}
            </Fragment>
          )}
        </div>

        {isInProgress && !isPinned && (
          <div className="d-flex justify-content-center py-8_5 bg-main-2">
            <Spinner className="my-0_5" />
          </div>
        )}
      </div>
    );
  }
}

export const LiveWidget = withCoreComponent(LiveWidgetCore, LiveWidgetUI);
