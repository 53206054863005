import * as R from 'ramda';

import { toDateFormat, toGamesFormat } from './date';
import { TODAY, TOMORROW } from '../constants';

export const sortGames = R.sort((a, b) => {
  const { isLive: aIsLive, eventStatus: aEventStatus, timestamp: aTimestamp } = a;
  const { isLive: bIsLive, eventStatus: bEventStatus, timestamp: bTimestamp } = b;

  const isAInLive = aIsLive || aEventStatus !== null;
  const isBInLive = bIsLive || bEventStatus !== null;

  if (isAInLive && !isBInLive) {
    return -1;
  }

  if (!isAInLive && isBInLive) {
    return 1;
  }

  return aTimestamp - bTimestamp;
});

export const groupByDate = R.compose(
  R.groupBy(({ desc: { scheduled } }) => {
    const formattedDate = toDateFormat(scheduled * 1000);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    if (toDateFormat(today) === formattedDate) {
      return TODAY;
    }

    if (toDateFormat(tomorrow) === formattedDate) {
      return TOMORROW;
    }

    return formattedDate;
  })
);

export const groupUpcomingGamesByDate = (games, locale) => R.groupBy(({ desc: { scheduled } }) => {
  const formattedDate = toGamesFormat(scheduled * 1000, locale);
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  if (toGamesFormat(today, locale) === formattedDate) {
    return TODAY;
  }

  if (toGamesFormat(tomorrow, locale) === formattedDate) {
    return TOMORROW;
  }

  return formattedDate;
}, games);

export const filterByLiveStream = (games, streams) => R.filter(item => streams && streams[R.path(['desc', 'id'], item)], games);

export const filterGamesWithLiveStream = (games, streams) => R.compose(
  R.reject(R.isEmpty),
  R.map(item => filterByLiveStream(item, streams))
)(games);

export const groupGamesByLeague = R.compose(
  R.values,
  R.groupBy(R.path(['desc', 'tournament', 'id'])),
);
