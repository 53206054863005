import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportArenaOfValor = ({
  className, isActive, width, height,
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.71829 0.416807C5.29953 1.29748 4.28152 3.34118 3.16242 5.56639C2.51984 6.83697 2 7.90588 2 7.9395C2 7.97311 2.49818 8 3.17686 8C4.29596 8 4.33928 7.99328 4.18766 7.87899C4.05048 7.78487 4.03604 7.73109 4.08658 7.52941C4.12268 7.40168 4.2382 7.11933 4.35372 6.9042L4.55588 6.52101H5.55223C6.10095 6.52101 6.54859 6.50756 6.54859 6.50084C6.54859 6.48739 6.41863 6.22521 6.25979 5.92269L5.96377 5.36471L5.54501 5.3916C5.16957 5.41176 5.12626 5.39832 5.18401 5.3042C5.21289 5.2437 5.35729 4.95462 5.50169 4.66555L5.76161 4.14118L6.56303 5.7479C7.32113 7.28067 7.53773 7.86555 7.35001 7.86555C7.30669 7.86555 7.27059 7.89244 7.27059 7.93277C7.27059 7.97311 7.77599 8 8.64961 8C9.40771 8 10.0142 7.97983 9.99975 7.94622C9.98531 7.91933 9.81925 7.59664 9.63153 7.22689C9.44381 6.85714 8.54853 5.08235 7.64603 3.28067C6.74353 1.47899 5.98543 0 5.96377 0C5.93489 0 5.82659 0.188235 5.71829 0.416807Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M7 9.02861C7 9.04292 7.13869 9.12875 7.31387 9.20743C7.48175 9.28611 7.68613 9.45777 7.77372 9.58652C7.85401 9.71526 8.48905 11.0313 9.18978 12.5048C9.88321 13.9782 10.7226 15.7521 11.0584 16.4459C11.3869 17.1397 11.6788 17.7763 11.708 17.8621C11.7372 17.9479 11.7883 18.0123 11.8248 17.998C11.8686 17.9837 12.146 17.4329 12.4453 16.7749L13 15.5733L12.2774 14.0569C11.8759 13.22 11.1752 11.7466 10.7153 10.7739L9.88321 9.00715L8.44526 9C7.64964 9 7 9.01431 7 9.02861Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M13.5567 9.07092C13.5567 9.10638 13.6088 9.14184 13.6684 9.14184C13.9514 9.14184 13.7801 9.63826 12.8938 11.4112L12 13.1912L12.4618 14.1061C12.715 14.6096 12.9534 15.0138 12.9906 14.9996C13.0279 14.9925 13.2885 14.5245 13.5716 13.9571C13.8472 13.3969 14.227 12.6168 14.4207 12.2268C14.6143 11.8367 14.9718 11.1204 15.2176 10.6311C15.456 10.1418 15.7316 9.6028 15.8135 9.42551C15.9029 9.24821 15.9848 9.0851 15.9997 9.04964C16.0146 9.02128 15.4709 9 14.7931 9C14.0185 9 13.5567 9.02837 13.5567 9.07092Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
  </svg>
);

IconSportArenaOfValor.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportArenaOfValor.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
