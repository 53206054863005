import React from 'react';
import PropTypes from 'prop-types';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { isEmptyOrNil } from 'core/helpers';
import { prepareTime } from 'helpers/clock';
import { withClock } from 'hocs/with-clock';
import { EVENT_STATUSES_WITH_TIME } from '../../../constants';

const GameStatusUI = ({
  eventStatus,
  className,
  statuses,
  clock,
  sportId,
}) => {
  const preparedTime = !isEmptyOrNil(clock) && EVENT_STATUSES_WITH_TIME.includes(eventStatus)
  && prepareTime(clock, sportId, eventStatus);

  if (preparedTime) {
    return (
      <span className={className}>
        {preparedTime}
        <span className="animate-minute-live">&rsquo;</span>
      </span>
    );
  }

  if (statuses && statuses[eventStatus]) {
    return <span title={statuses[eventStatus]} className={className}>{statuses[eventStatus]}</span>;
  }

  return <FormattedTag id="general.live" className={className} />;
};

GameStatusUI.propTypes = {
  clock: PropTypes.shape().isRequired,
  statuses: PropTypes.shape().isRequired,
  eventStatus: PropTypes.string.isRequired,
  sportId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

GameStatusUI.defaultProps = {
  className: null,
};

export const GameStatus = withClock(GameStatusUI);
