import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportCod = ({
  className, isActive, width, height,
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#icon-leagues-a)">
      <path d="M1.87764 3.02154C3.1799 2.41445 4.7034 2.39553 6.0992 2.66898C7.15684 2.91148 8.27923 3.48417 8.67854 4.51262C9.07785 5.69757 8.99151 6.96163 8.99331 8.18786C7.72523 8.1827 6.45714 8.18786 5.19085 8.18614C5.16387 7.16801 5.24841 6.14472 5.13869 5.13003C5.16207 4.42835 3.86161 4.43351 3.8796 5.13519C3.7429 6.73118 3.84722 8.33748 3.81485 9.93863C3.84542 10.9602 3.7375 11.9955 3.90658 13.0085C4.0109 13.4144 4.54332 13.4453 4.87968 13.3077C5.13869 13.1306 5.14409 12.8056 5.17107 12.5321C5.21244 11.772 5.18006 11.0118 5.19085 10.2516C6.46074 10.2482 7.72882 10.2499 8.99871 10.2499C8.96093 11.4039 9.06886 12.6319 8.56162 13.7102C7.71983 15.043 5.9643 15.5435 4.41741 15.4971C3.05399 15.485 1.49272 15.1841 0.645531 14.061C0.0393683 13.153 0.0609527 12.0213 0.0123877 10.9826C0.0213812 9.14064 -0.0523656 7.29528 0.0807384 5.4568C0.136498 4.39911 0.895551 3.46526 1.87764 3.02154Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
      <path d="M10.0007 3.00391C11.518 2.99238 13.0369 3.00391 14.5526 3.07147C15.3504 3.12915 16.1857 3.18517 16.897 3.59218C17.5382 3.98106 17.9314 4.71763 17.9379 5.47067C18.0423 7.55679 17.982 9.6495 17.9885 11.7373C17.9477 12.5991 18.0374 13.6009 17.4191 14.2881C16.7306 14.9241 15.7419 14.9554 14.8593 14.9917C13.2409 15.0098 11.6208 14.9917 10.0007 14.9983C9.99909 11.0007 10.0007 7.00148 10.0007 3.00391ZM13.4448 5.06696C13.4464 7.70015 13.4464 10.3317 13.4448 12.9649C13.7727 12.8924 14.272 12.9599 14.4172 12.5678C14.6195 11.7027 14.5118 10.7997 14.5395 9.91644C14.5118 8.47297 14.5999 7.0229 14.489 5.58108C14.4416 5.06202 13.8249 5.08344 13.4448 5.06696Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    </g>
  </svg>
);

IconSportCod.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportCod.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
