import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import classNames from 'classnames';
import Input from 'reactstrap/lib/Input';
import { BET_SLIP_STATE, BET_SLIP_ERRORS } from 'core/constants';

import { isInvalidStake } from 'helpers/validate';
import { prepareCurrency } from 'helpers/currency';

import './bet-amount-input.scss';

export class BetAmountInput extends Component {
  static propTypes = {
    stakeId: PropTypes.string,
    currency: PropTypes.string.isRequired,
    betAmount: PropTypes.number,
    setBetAmount: PropTypes.func.isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    isPlaceBetInProgress: PropTypes.bool.isRequired,
    error: PropTypes.shape(),
    isFreebetUsed: PropTypes.bool,
    setIsPlaceBetDisabled: PropTypes.func.isRequired,
  };

  static defaultProps = {
    stakeId: null,
    betAmount: null,
    error: null,
    isFreebetUsed: false,
  };

  state = {
    value: this.props.betAmount || '',
  };

  onChangeSubject;

  onChangeSubsription;

  inputRef = createRef();

  componentDidMount() {
    this.onChangeSubject = new Subject();
    this.onChangeSubsription = this.onChangeSubject
      .pipe(debounceTime(100))
      .subscribe(this.onChange);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      betSlipState,
      error,
      isPlaceBetInProgress,
      betAmount,
    } = this.props;
    const {
      betSlipState: prevBetSlipState,
      error: prevError,
      isPlaceBetInProgress: prevIsPlaceBetInProgress,
      betAmount: prevBetAmount,
    } = prevProps;
    const { value } = this.state;
    const { value: prevValue } = prevState;
    const isErrorState = betSlipState === BET_SLIP_STATE.ERROR;

    if (error && prevIsPlaceBetInProgress && !isPlaceBetInProgress && isErrorState) {
      const newValue = error.limit || error.amount;

      if (newValue) {
        this.setValueWithoutStoreUpdate(String(newValue));
      }
    }

    if ((prevBetSlipState === BET_SLIP_STATE.ERROR && prevError
    && prevError.errorId === BET_SLIP_ERRORS.BET_AMOUNT_OUT_OF_BONUS_BALANCE && !isErrorState)
    || (prevBetAmount && !betAmount && prevValue && value)) {
      this.setValueWithoutStoreUpdate('');
    }

    if (betAmount !== prevBetAmount) {
      const newValue = betAmount === null || betAmount === 0 ? '' : String(betAmount);
      this.setValueWithoutStoreUpdate(newValue);
    }
  }

  componentWillUnmount() {
    const { setIsPlaceBetDisabled } = this.props;
    setIsPlaceBetDisabled(false);

    if (this.onChangeSubsription) {
      this.onChangeSubsription.unsubscribe();
    }
  }

  setValueWithoutStoreUpdate = value => this.setState({ value });

  setValue = (e) => {
    const { value } = e.target;
    const { isPlaceBetInProgress, setIsPlaceBetDisabled } = this.props;
    let formattedValue = value;

    if (value === '.') {
      formattedValue = '0.';
    }

    if (isInvalidStake(formattedValue) || isPlaceBetInProgress) {
      return;
    }

    setIsPlaceBetDisabled(true);
    this.setState({ value: formattedValue });
    this.onChangeSubject.next(formattedValue);
  }

  onChange = (value) => {
    const { setBetAmount, stakeId, setIsPlaceBetDisabled } = this.props;
    setBetAmount({ stakeId, betAmount: Number(value) });
    setIsPlaceBetDisabled(false);
  };

  focusInput = () => {
    if (this.inputRef) {
      this.inputRef.current.focus();
    }
  }

  render() {
    const {
      currency,
      betSlipState,
      error,
      isFreebetUsed,
    } = this.props;
    const { value } = this.state;
    const isErrorState = betSlipState === BET_SLIP_STATE.ERROR;

    return (
      <div className={classNames('bet-amount-input position-relative', { 'is-freebet': isFreebetUsed })}>
        <Input
          innerRef={this.inputRef}
          type="text"
          value={value}
          onChange={this.setValue}
          className={classNames('pl-6 text-right font-weight-bold', {
            'text-warning': isErrorState && error && error.limit,
          })}
        />
        <div
          role="button"
          tabIndex="0"
          onClick={this.focusInput}
          onKeyPress={this.focusInput}
          className="bet-amount-input-currency position-absolute caption text-extra-2"
        >
          {prepareCurrency(currency)}
        </div>
      </div>
    );
  }
}
