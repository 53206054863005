import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { prepareBetsForRepeat, mathRound, convertStringifiedBoolean } from 'core/helpers';
import { BET_STATUS, AI_OUTCOME_SECTION } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { BetScore } from 'components/bet-score/bet-score';
import { IconRepeat } from 'components/icons/icon-repeat/icon-repeat';
import { getBetStatusIcon, prepareOutrightName, isBetWithRepeat } from 'helpers/bets';
import { formatAmountWithCurrencySymbol } from 'helpers/currency';
import { toShortFormat } from 'helpers/date';
import { GA } from 'helpers/ga';

export class BetItemOrdinar extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    result_sum: PropTypes.string.isRequired,
    sum: PropTypes.string.isRequired,
    k: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    selections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    bonus: PropTypes.shape(),
    repeatBet: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    bonus: null,
  };

  onEventClick = (e) => {
    const { dataset: { eventId, isLive } } = e.currentTarget;
    const { sendEventClickFeedback } = this.props;
    GA.event({
      category: 'go-to-event-page',
      label: 'my-bets-event-click',
    });
    sendEventClickFeedback(eventId, convertStringifiedBoolean(isLive));
  };

  repeatBet = () => {
    const {
      repeatBet,
      sum,
      type,
      selections,
    } = this.props;
    repeatBet({
      sum,
      type,
      selections: prepareBetsForRepeat(selections, sum),
    });

    GA.event({
      category: 'bet-slip',
      label: 'repeat-last-bet-click',
    });
  };

  render() {
    const {
      locale,
      currency,
      result_sum: resultSum,
      sum,
      k,
      status,
      selections,
      bonus,
    } = this.props;
    const {
      desc: {
        scheduled,
        competitors,
        type,
        stage,
        season,
        tournament,
        sport: {
          id: sportId,
        },
      },
      market_name: marketName,
      outcome_name: outcomeName,
      final_score: finalScore,
      event_id: eventId,
      eventInfo,
    } = selections[0];
    const eventDateTimeStamp = scheduled * 1000;
    const isOutright = type !== 'match';
    const teamA = !isOutright && competitors[0].name;
    const teamB = !isOutright && competitors[1].name;
    const outrightName = isOutright && prepareOutrightName(type, stage, season, tournament);
    const StatusIcon = getBetStatusIcon(status);
    const isWon = status === BET_STATUS.WON;
    const isCashouted = status === BET_STATUS.CASHED_OUT;
    const isFreebet = bonus && bonus.type === 'freebet';
    const withEventLink = eventInfo && !isOutright;
    const LinkComponent = withEventLink ? RouteLink : 'span';
    const linkProps = withEventLink
      ? {
        locale,
        to: `/event/${eventId}?isLive=${eventInfo && eventInfo.isLive}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.MY_BETS}`,
        onClick: this.onEventClick,
        'data-event-id': eventId,
        'data-is-live': !!eventInfo && eventInfo.isLive,
      }
      : {};

    return (
      <div className="bet-item-ordinar p-2 bg-main-4">
        <div className="d-flex justify-content-between mb-0_25">
          <span title={outcomeName} className="font-weight-bold text-truncate mr-1">{outcomeName}</span>
          {!!k && <span className="font-weight-bold">{mathRound(k)}</span>}
        </div>

        <div title={marketName} className="text-extra-2 caption mb-0_25">{marketName}</div>

        <div className="d-flex align-items-center justify-content-between mb-1">
          <div className="d-flex overflow-hidden caption">
            <LinkComponent
              {...linkProps}
              title={isOutright ? outrightName : `${teamA} - ${teamB}`}
              className={classNames('text-truncate', { 'bet-item-event-link': withEventLink, 'text-extra-2': !withEventLink })}
            >
              {isOutright ? outrightName : `${teamA} - ${teamB}`}
            </LinkComponent>

            <span className="mx-0_5 text-extra-3">&middot;</span>

            <BetScore
              score={eventInfo && eventInfo.score}
              finalScore={finalScore}
              sportId={sportId}
              date={toShortFormat(eventDateTimeStamp, locale)}
            />
          </div>

          {isBetWithRepeat(selections) && (
            <div
              role="button"
              tabIndex="0"
              onClick={this.repeatBet}
              onKeyPress={this.repeatBet}
              className="d-flex align-items-center flex-shrink-0 ml-1"
            >
              <IconRepeat className="flex-shrink-0 mr-1" />
              <FormattedTag id="bet-slip.repeat-bet" className="caption font-weight-bold text-extra-2" />
            </div>
          )}
        </div>

        <div className="d-flex bet-item-info without-cashout">
          <div className="bet-item-stake pr-0_25 mr-0_5">
            <FormattedTag id="bet-slip.stake" tag="div" className="caption text-extra-2" />
            {!!sum && <div className="text-small font-weight-bold text-break">{formatAmountWithCurrencySymbol(sum, currency)}</div>}
          </div>
          <div className="bet-item-return pl-0_25 ml-0_5">
            <FormattedTag id={isCashouted ? 'bets-history.cashed-out' : 'bet-slip.return'} tag="div" className="caption text-extra-2" />
            <div className="d-flex align-items-center">
              {!isCashouted && <StatusIcon className="bet-status-icon mr-1 flex-shrink-0" />}
              {resultSum && (
                <div className={classNames('bet-item-win-amount text-small font-weight-bold text-break', {
                  'text-success': isWon && !isCashouted,
                  'text-extra-3': !isWon,
                })}
                >
                  {formatAmountWithCurrencySymbol(resultSum, currency)}
                </div>
              )}
            </div>
          </div>
          {isFreebet && <FormattedTag id="freebet.title" className="bet-item-freebet bg-warning px-0_5 py-0_25 font-weight-bold align-self-end" />}
        </div>
      </div>
    );
  }
}
