import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportFifa18 = ({
  className, isActive, width, height,
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.9146 12.5269C17.5848 12.5269 17.3145 12.255 17.3145 11.922C17.3145 11.59 17.5848 11.317 17.9146 11.317C18.2454 11.317 18.5156 11.59 18.5156 11.922C18.5156 12.255 18.2454 12.5269 17.9146 12.5269ZM16.4135 11.62C16.0836 11.62 15.8124 11.348 15.8124 11.016C15.8124 10.683 16.0836 10.41 16.4135 10.41C16.7443 10.41 17.0135 10.683 17.0135 11.016C17.0135 11.348 16.7443 11.62 16.4135 11.62ZM16.4135 13.4339C16.0836 13.4339 15.8124 13.1619 15.8124 12.8289C15.8124 12.4969 16.0836 12.225 16.4135 12.225C16.7443 12.225 17.0135 12.4969 17.0135 12.8289C17.0135 13.1619 16.7443 13.4339 16.4135 13.4339ZM11.9071 12.6779H11.457V13.1319C11.457 13.3799 11.2524 13.5849 11.006 13.5849C10.7596 13.5849 10.5559 13.3799 10.5559 13.1319V12.6779H10.1049C9.8595 12.6779 9.65484 12.4729 9.65484 12.225C9.65484 11.977 9.8595 11.771 10.1049 11.771H10.5559V11.317C10.5559 11.069 10.7596 10.864 11.006 10.864C11.2524 10.864 11.457 11.069 11.457 11.317V11.771H11.9071C12.1544 11.771 12.3581 11.977 12.3581 12.225C12.3581 12.4729 12.1544 12.6779 11.9071 12.6779ZM18.924 10.417C17.3384 7.79215 14.953 9.61807 14.953 9.61807C14.7971 9.73307 14.5149 9.83006 14.3162 9.83006H13.6804C13.4877 9.83606 13.1986 9.73307 13.0495 9.61807C13.0495 9.61807 10.6583 7.79215 9.07167 10.417C7.48609 13.0349 8.12886 15.8888 8.12886 15.8888C8.23715 16.5838 8.60971 17.0498 9.30017 16.9958C9.99759 16.9358 11.5047 15.1148 11.5047 15.1148C11.6249 14.9638 11.8892 14.8368 12.0819 14.8368H15.9147C16.1065 14.8368 16.3717 14.9638 16.4919 15.1148C16.4919 15.1148 17.999 16.9358 18.6965 16.9958C19.3929 17.0498 19.7595 16.5838 19.8737 15.8888C19.8737 15.8888 20.5105 13.0349 18.924 10.417Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.3707 3H18.1553C19.2136 3 20 3.65498 20 4.53932V10H19.771C19.7622 9.9828 19.7535 9.96561 19.7446 9.94843C19.2043 8.89417 18.5843 8.36208 17.9774 8.13706V7.61679H16.9072V6.2271H17.9774V4.78831C17.9774 4.56017 17.8348 4.3909 17.5011 4.3909H17.0263C16.6926 4.3909 16.55 4.60924 16.55 4.78831V8.08729C15.7168 8.30411 15.1119 8.84985 15.1119 8.84985C14.9686 8.97439 14.7339 9.08358 14.5274 9.12434V4.53932C14.5274 3.65498 15.3124 3 16.3707 3ZM10.6821 8.00261V7.19853H12.959V5.79901H10.6821V4.79936C10.6821 4.61904 10.8261 4.39952 11.1613 4.39952H13.7983V3H10.5027C9.43559 3 8.64474 3.65867 8.64474 4.54912V9.28717C9.2942 8.35813 10.0184 8.0342 10.6821 8.00261ZM7.91568 3H5.87982V9.99878H7.91568V3ZM0 9.99878V4.54912C0 3.65867 0.790857 3 1.85653 3H5.15073V4.39952H2.51508C2.17996 4.39952 2.0359 4.61904 2.0359 4.79936V5.79901H4.31431V7.19853H2.0374V9.99878H0Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
  </svg>
);

IconSportFifa18.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportFifa18.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
