import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import {
  withCoreComponent,
  withModalActions,
  withLocale,
  withFeedbackActions,
} from 'core/hocs';
import { BetSlip as BetSlipCore } from 'core/components';
import { isEmptyOrNil, convertStringifiedBoolean, prepareStakesForRepeat } from 'core/helpers';
import {
  BET_TYPE,
  BET_SLIP_STATE,
  BET_SLIP_ERRORS,
} from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { EmptyState } from 'components/ticket/empty-state/empty-state';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { GA } from 'helpers/ga';
import { BetSlipHeader } from './bet-slip-header/bet-slip-header';
import { StakeItem } from './stake-item/stake-item';
import { BetSlipFooter } from './bet-slip-footer/bet-slip-footer';
import { BetSlipSuccess } from './bet-slip-success/bet-slip-success';
import { BetSlipNotification } from './bet-slip-notification/bet-slip-notification';

import './bet-slip.scss';

class BetSlipUI extends Component {
  static propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    stakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    toggleStake: PropTypes.func.isRequired,
    clearBetSlip: PropTypes.func.isRequired,
    setBetType: PropTypes.func.isRequired,
    totalBetFactor: PropTypes.number,
    totalReturnAmount: PropTypes.number,
    systemVariants: PropTypes.number,
    setBetAmount: PropTypes.func.isRequired,
    totalBetAmount: PropTypes.number,
    isSystemInfoInProgress: PropTypes.bool.isRequired,
    systemIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    acceptChanges: PropTypes.func.isRequired,
    placeBet: PropTypes.func.isRequired,
    isPlaceBetInProgress: PropTypes.bool.isRequired,
    error: PropTypes.shape(),
    bonuses: PropTypes.arrayOf(PropTypes.shape({})),
    isBonusConditionsReached: PropTypes.bool.isRequired,
    bonusAmount: PropTypes.number,
    betFactorChanges: PropTypes.bool.isRequired,
    setBetFactorChanges: PropTypes.func.isRequired,
    clearOutOfBonusBalanceError: PropTypes.func.isRequired,
    bonusValue: PropTypes.number,
    isPartialPlaced: PropTypes.bool.isRequired,
    availableFreebetId: PropTypes.string,
    setUsedFreebet: PropTypes.func.isRequired,
    isFreebetUsed: PropTypes.bool.isRequired,
    systemBetAmount: PropTypes.number,
    repeatBet: PropTypes.func.isRequired,
    lastBet: PropTypes.shape().isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    totalBetFactor: null,
    totalReturnAmount: null,
    totalBetAmount: null,
    systemVariants: null,
    systemIndex: null,
    error: null,
    bonuses: null,
    bonusAmount: null,
    bonusValue: null,
    availableFreebetId: null,
    systemBetAmount: null,
  };

  state = {
    isPlaceBetDisabled: false,
  };

  componentDidUpdate(prevProps) {
    const { betSlipState } = this.props;
    const { betSlipState: prevBetSlipState } = prevProps;

    if (betSlipState === BET_SLIP_STATE.SUCCESS && prevBetSlipState !== BET_SLIP_STATE.SUCCESS) {
      GA.event({
        category: 'bet-slip',
        label: 'bet-accepted',
      });
    }
  }

  repeatBet = () => {
    const { repeatBet, lastBet } = this.props;
    repeatBet({
      sum: lastBet.sum,
      type: lastBet.type,
      selections: prepareStakesForRepeat(lastBet.selections),
    });

    GA.event({
      category: 'bet-slip',
      label: 'repeat-last-bet-click',
    });
  };

  setIsPlaceBetDisabled = isPlaceBetDisabled => this.setState({ isPlaceBetDisabled });

  sendEventClickFeedback = (e) => {
    const { dataset: { eventId, isLive } } = e.currentTarget;
    const { sendEventClickFeedback } = this.props;

    sendEventClickFeedback(eventId, convertStringifiedBoolean(isLive));
  };

  render() {
    const {
      isUserLoggedIn,
      locale,
      currency,
      stakes,
      betType,
      toggleStake,
      clearBetSlip,
      setBetType,
      totalBetFactor,
      totalReturnAmount,
      systemVariants,
      setBetAmount,
      totalBetAmount,
      isSystemInfoInProgress,
      systemIndex,
      betSlipState,
      acceptChanges,
      placeBet,
      isPlaceBetInProgress,
      error,
      bonuses,
      isBonusConditionsReached,
      bonusAmount,
      betFactorChanges,
      setBetFactorChanges,
      clearOutOfBonusBalanceError,
      bonusValue,
      isPartialPlaced,
      availableFreebetId,
      setUsedFreebet,
      isFreebetUsed,
      systemBetAmount,
      repeatBet,
      lastBet,
    } = this.props;
    const { isPlaceBetDisabled } = this.state;
    const isSuspended = stakes.length === 1 && betSlipState === BET_SLIP_STATE.SUSPENDED;
    const isErrorState = betSlipState === BET_SLIP_STATE.ERROR;
    const isNotificationShowed = isErrorState && error && error.errorId === BET_SLIP_ERRORS.STAKES_AMOUNT_EXCEED;

    return (
      <div className={classNames('bet-slip position-relative', {
        'is-suspended': isSuspended,
      })}
      >
        {stakes.length
          ? (
            <Fragment>
              {betSlipState === BET_SLIP_STATE.SUCCESS
                ? (
                  <BetSlipSuccess
                    currency={currency}
                    betType={betType}
                    totalBetAmount={totalBetAmount}
                    totalBetFactor={totalBetFactor}
                    systemIndex={systemIndex}
                    systemVariants={systemVariants}
                    totalReturnAmount={totalReturnAmount}
                    stakes={stakes}
                    clearBetSlip={clearBetSlip}
                    bonusValue={bonusValue}
                    isFreebetUsed={isFreebetUsed}
                    systemBetAmount={systemBetAmount}
                    locale={locale}
                    repeatBet={repeatBet}
                    sendEventClickFeedback={this.sendEventClickFeedback}
                  />
                )
                : (
                  <Fragment>
                    <BetSlipHeader
                      betType={betType}
                      clearBetSlip={clearBetSlip}
                      setBetType={setBetType}
                      stakesAmount={stakes.length}
                      systemIndex={systemIndex}
                      isPartialPlaced={isPartialPlaced}
                    />

                    <div>
                      {stakes.map(stake => (
                        <StakeItem
                          key={stake.stakeId}
                          stake={stake}
                          stakesAmount={stakes.length}
                          toggleStake={toggleStake}
                          betType={betType}
                          currency={currency}
                          setBetAmount={setBetAmount}
                          betSlipState={betSlipState}
                          isPlaceBetInProgress={isPlaceBetInProgress}
                          availableFreebetId={availableFreebetId}
                          setUsedFreebet={setUsedFreebet}
                          isFreebetUsed={isFreebetUsed}
                          locale={locale}
                          setIsPlaceBetDisabled={this.setIsPlaceBetDisabled}
                          sendEventClickFeedback={this.sendEventClickFeedback}
                        />
                      ))}
                    </div>

                    <BetSlipFooter
                      currency={currency}
                      betType={betType}
                      totalBetFactor={totalBetFactor}
                      totalReturnAmount={totalReturnAmount}
                      systemVariants={systemVariants}
                      setBetAmount={setBetAmount}
                      totalBetAmount={totalBetAmount}
                      isSystemInfoInProgress={isSystemInfoInProgress}
                      betSlipState={betSlipState}
                      acceptChanges={acceptChanges}
                      stakes={stakes}
                      placeBet={placeBet}
                      isPlaceBetInProgress={isPlaceBetInProgress}
                      error={error}
                      bonuses={bonuses}
                      isBonusConditionsReached={isBonusConditionsReached}
                      bonusAmount={bonusAmount}
                      betFactorChanges={betFactorChanges}
                      setBetFactorChanges={setBetFactorChanges}
                      clearOutOfBonusBalanceError={clearOutOfBonusBalanceError}
                      isFreebetUsed={isFreebetUsed}
                      systemBetAmount={systemBetAmount}
                      isPlaceBetDisabled={isPlaceBetDisabled}
                      setIsPlaceBetDisabled={this.setIsPlaceBetDisabled}
                    />

                    {isSuspended && (
                      <FormattedTag
                        id="bet-slip.suspended"
                        className="bet-slip-suspended-label h6 d-flex align-items-center position-absolute bg-info"
                      />
                    )}

                    <BetSlipNotification betType={betType} isShowed={isNotificationShowed} />
                  </Fragment>
                )}
            </Fragment>
          )
          : (
            <>
              {isUserLoggedIn && (lastBet.isInProgress || !isEmptyOrNil(lastBet.selections)) && (
                <div className="bet-slip-repeat-bet p-1_5">
                  <ButtonWithLoader
                    block
                    size="sm"
                    outline
                    isWhite
                    isLoading={lastBet.isInProgress}
                    onClick={this.repeatBet}
                  >
                    <FormattedMessage id="bet-slip.repeat-last-bet" />
                  </ButtonWithLoader>
                </div>
              )}
              <EmptyState />
            </>
          )}
      </div>
    );
  }
}

export const BetSlip = withFeedbackActions(withLocale(withCoreComponent(BetSlipCore, withModalActions(BetSlipUI))));
