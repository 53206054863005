import React from 'react';
import PropTypes from 'prop-types';
import { withCoreComponent, withLocale } from 'core/hocs';
import { FinishedBets as FinishedBetsCore } from 'core/components';

import { Spinner } from 'components/spinner/spinner';
import { EmptyState } from 'components/ticket/empty-state/empty-state';
import { BetItemOrdinar } from './bet-item/bet-item-ordinar';
import { BetItemMultiple } from './bet-item/bet-item-multiple';

const FinishedBetsUI = ({
  locale,
  isUserLoggedIn,
  currency,
  items,
  isInProgress,
  repeatBet,
  sendEventClickFeedback,
}) => {
  if (isInProgress) {
    return (
      <div className="my-bets-tab-loader d-flex align-items-center justify-content-center">
        <Spinner />
      </div>
    );
  }

  if (!isUserLoggedIn || (!isInProgress && items && !items.length)) {
    return <EmptyState />;
  }

  return (
    <div>
      {items && items.map(bet => bet.type === '1/1'
        ? (
          <BetItemOrdinar
            key={bet.id}
            {...bet}
            locale={locale}
            currency={currency}
            repeatBet={repeatBet}
            sendEventClickFeedback={sendEventClickFeedback}
          />
        )
        : (
          <BetItemMultiple
            key={bet.id}
            {...bet}
            locale={locale}
            currency={currency}
            repeatBet={repeatBet}
            sendEventClickFeedback={sendEventClickFeedback}
          />
        ))}
    </div>
  );
};

FinishedBetsUI.propTypes = {
  locale: PropTypes.string.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()),
  isInProgress: PropTypes.bool.isRequired,
  repeatBet: PropTypes.func.isRequired,
  sendEventClickFeedback: PropTypes.func.isRequired,
};

FinishedBetsUI.defaultProps = {
  items: null,
};

export const FinishedBets = withLocale(withCoreComponent(FinishedBetsCore, FinishedBetsUI));
