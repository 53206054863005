import { SPORTS } from '../constants';

export const prepareLogo = ({
  logoUrl,
  logoUrl2,
  competitorId,
  sportId,
  categorySlug,
  size = 'medium',
  countryCode,
}) => {
  if (([SPORTS.TENNIS, SPORTS.TABLE_TENNIS, SPORTS.BADMINTON, SPORTS.AO_TENNIS].includes(sportId)
  || categorySlug === 'international') && countryCode) {
    return `https://start.sptpub.com/e17fda34/flags_by_code/${countryCode}.svg`;
  }

  if (logoUrl2 && [SPORTS.FIFA18, SPORTS.NBA2K19, SPORTS.E_FIGHTING].includes(sportId)) {
    return `${logoUrl2}/${size}/${competitorId}.png`;
  }

  if (logoUrl) {
    return `${logoUrl}/${size}/${competitorId}.png`;
  }

  return null;
};
