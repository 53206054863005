import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fromEvent } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { withLocale, withBetSlip, withBetSlipActions } from 'core/hocs';
import { BET_SLIP_STORE_FIELDS } from 'core/constants';
import { isEmptyOrNil } from 'core/helpers';
import * as R from 'ramda';

import { RouteLink } from 'components/route-link/route-link';
import { TicketMobile } from 'components/ticket-mobile/ticket-mobile';
import { IconHome } from 'components/icons/icon-home/icon-home';
import { IconStar } from 'components/icons/icon-star/icon-star';
import { IconBetSlip } from 'components/icons/icon-bet-slip/icon-bet-slip';
import { IconClock } from 'components/icons/icon-clock/icon-clock';
import { IconGift } from 'components/icons/icon-gift/icon-gift';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconChat } from 'components/icons/icon-chat/icon-chat';
import { GA } from 'helpers/ga';
import colors from '../../customizations/js/color-variables';
import { FREEBET_TYPES, PAGE_NAMES } from '../../constants';

import './navigation-bar.scss';

class NavigationBarUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setIsOpened: PropTypes.func.isRequired,
    isBetSlipOpened: PropTypes.bool.isRequired,
    freebets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  static defaultProps = {
  };

  state = {
    isBackdropShowed: false,
  };

  scrollSubscription;

  prevScrollTop = 0;

  state = {
    isHidden: false,
  };

  componentDidMount() {
    const centralPart = document.querySelector('.central-part');

    if (centralPart) {
      this.prevScrollTop = centralPart.scrollTop;

      this.scrollSubscription = fromEvent(centralPart, 'scroll').pipe(
        map(() => {
          const { isHidden } = this.state;
          const deltaY = centralPart.scrollTop - this.prevScrollTop;

          if (!isHidden && deltaY > 0) {
            this.setState({ isHidden: true });
          }

          this.prevScrollTop = centralPart.scrollTop;
        }),
        debounceTime(150)
      ).subscribe(() => {
        const { isHidden } = this.state;

        if (isHidden) {
          this.setState({ isHidden: false });
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { betSlipStakes, isBetSlipOpened } = this.props;
    const { betSlipStakes: prevBetSlipStakes, isBetSlipOpened: prevIsBetSlipOpened } = prevProps;
    const { isBackdropShowed } = this.state;
    const { isBackdropShowed: prevIsBackdropShowed } = prevState;

    if ((prevBetSlipStakes.length && !betSlipStakes.length) || (prevIsBetSlipOpened && !isBetSlipOpened)) {
      this.setIsBackdropShowed(false);
    }

    if (isBackdropShowed !== prevIsBackdropShowed) {
      if (isBackdropShowed) {
        document.body.classList.add('scroll-disabled');
      } else {
        document.body.classList.remove('scroll-disabled');
      }
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('scroll-disabled');

    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  onClick = () => {
    const { setIsOpened } = this.props;

    this.setIsBackdropShowed(true);
    setIsOpened(true);
    GA.event({
      category: 'bet-slip',
      label: 'open',
    });
  };

  onBackdropClick = () => {
    const { setIsOpened } = this.props;

    this.setIsBackdropShowed(false);
    setIsOpened(false);
  };

  setIsBackdropShowed = isBackdropShowed => this.setState({ isBackdropShowed });

  onHomeClick = () => {
    GA.event({
      category: 'navigation',
      label: 'home-bottom-bar',
    });
  };

  onWatchlistClick = () => {
    GA.event({
      category: 'watchlist',
      label: 'open',
    });
  };

  onMyBetsClick = () => {
    GA.event({
      category: 'bet-slip',
      label: 'my-bets-click',
    });
  };

  render() {
    const {
      locale,
      pageName,
      betSlipStakes,
      freebets,
    } = this.props;
    const { isBackdropShowed, isHidden } = this.state;
    const isGiftVisible = !isEmptyOrNil(freebets) && R.compose(
      Boolean,
      R.length,
      R.reject(({ freebet_data: { type } }) => type !== FREEBET_TYPES.BET_REFUND)
    )(freebets);

    return (
      <Fragment>
        {isBackdropShowed && (
          <div
            role="button"
            tabIndex="0"
            onClick={this.onBackdropClick}
            onKeyPress={this.onBackdropClick}
            className="navigation-bar-backdrop position-fixed"
          />
        )}
        <div className={classNames('navigation-bar d-flex align-items-center justify-content-between d-lg-none position-fixed px-3 mx-1 bg-main-5', {
          'is-hidden': isHidden,
        })}
        >
          <RouteLink
            locale={locale}
            to="/"
            className="navigation-bar-item d-flex flex-column align-items-center"
            onClick={this.onHomeClick}
          >
            <IconHome color={pageName === PAGE_NAMES.HOME ? colors.cpExtra1 : colors.cpExtra2} className="mb-0_5" />
            <FormattedTag
              id="nav-bar.home"
              className={classNames('font-weight-bold', {
                'text-extra-2': pageName !== PAGE_NAMES.HOME,
              })}
            />
          </RouteLink>

          <RouteLink
            locale={locale}
            to="/watchlist"
            className="navigation-bar-item d-flex flex-column align-items-center"
            onClick={this.onWatchlistClick}
          >
            <IconStar color={pageName === PAGE_NAMES.WATCHLIST ? colors.cpExtra1 : colors.cpExtra2} className="mb-0_5" />
            <FormattedTag
              id="nav-bar.watchlist"
              className={classNames('font-weight-bold', {
                'text-extra-2': pageName !== PAGE_NAMES.WATCHLIST,
              })}
            />
          </RouteLink>

          <div
            role="button"
            tabIndex="0"
            onClick={this.onClick}
            onKeyPress={this.onClick}
            className="ticket-small rounded-circle position-relative d-flex align-items-center justify-content-center"
          >
            <IconBetSlip />
            <span className="ticket-small-count font-weight-bold position-absolute">{betSlipStakes.length}</span>
          </div>

          <RouteLink
            locale={locale}
            to="/my-bets"
            className="navigation-bar-item d-flex flex-column align-items-center position-relative"
            onClick={this.onMyBetsClick}
          >
            <IconClock color={pageName === PAGE_NAMES.MY_BETS ? colors.cpExtra1 : colors.cpExtra2} className="mb-0_5" />

            {isGiftVisible && (
              <div className="freebet-icon-gift d-flex align-items-center justify-content-center bg-main-5 position-absolute">
                <IconGift />
              </div>
            )}

            <FormattedTag
              id="nav-bar.my-bets"
              className={classNames('font-weight-bold', {
                'text-extra-2': pageName !== PAGE_NAMES.MY_BETS,
              })}
            />
          </RouteLink>

          <div
            role="button"
            tabIndex="0"
            className="navigation-bar-item d-flex flex-column align-items-center "
          >
            <IconChat color={colors.cpExtra2} className="mb-0_5" />
            <FormattedTag id="nav-bar.live-chat" className="font-weight-bold text-extra-2" />
          </div>
        </div>

        <TicketMobile isBackdropShowed={isBackdropShowed} />
      </Fragment>
    );
  }
}

export const NavigationBar = withLocale(
  withBetSlipActions(withBetSlip(NavigationBarUI, [
    BET_SLIP_STORE_FIELDS.STAKES,
    BET_SLIP_STORE_FIELDS.IS_OPENED,
    BET_SLIP_STORE_FIELDS.BET_TYPE,
    BET_SLIP_STORE_FIELDS.BET_FACTOR,
    BET_SLIP_STORE_FIELDS.FREEBETS,
  ]))
);
