import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import store from 'store';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import { convertStringifiedBoolean, mathRound } from 'core/helpers';
import {
  BET_TYPE,
  BET_SLIP_STATE,
  BET_SLIP_ERRORS,
  AI_OUTCOME_SECTION,
} from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { IconWarning } from 'components/icons/icon-warning/icon-warning';
import { IconFreebet } from 'components/icons/icon-freebet/icon-freebet';
import { prepareCurrency } from 'helpers/currency';
import { GA } from 'helpers/ga';
import { BetAmountInput } from '../bet-amount-input/bet-amount-input';

import './stake-item.scss';

const TOOLTIP_ID = 'stake-item-mobile-freebet-tooltip-id';
const STORAGE_DELETE_SWIPE = 'isDeleteSwipeShowed';

export class StakeItem extends Component {
  static propTypes = {
    stake: PropTypes.shape().isRequired,
    toggleStake: PropTypes.func.isRequired,
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    isTouchXDisabled: PropTypes.bool.isRequired,
    setIsOpened: PropTypes.func.isRequired,
    stakesAmount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    setBetAmount: PropTypes.func.isRequired,
    error: PropTypes.shape(),
    isPlaceBetInProgress: PropTypes.bool.isRequired,
    setIsKeyboardShowed: PropTypes.func.isRequired,
    availableFreebetId: PropTypes.string,
    setUsedFreebet: PropTypes.func.isRequired,
    isFreebetUsed: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    setIsPlaceBetDisabled: PropTypes.func.isRequired,
    stakeIdx: PropTypes.number.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    availableFreebetId: null,
  };

  prevClientX = null;

  timeoutId;

  state = {
    isDeleteActive: false,
    isDeleteSwipeShowed: false,
  };

  componentDidMount() {
    const { stakeIdx } = this.props;

    if (stakeIdx === 0) {
      const isDeleteSwipeShowed = store.get(STORAGE_DELETE_SWIPE) || false;

      if (!isDeleteSwipeShowed) {
        store.set(STORAGE_DELETE_SWIPE, true);
        this.setState({ isDeleteSwipeShowed: true });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { betSlipState } = this.props;
    const { betSlipState: prevBetSlipState } = prevProps;
    const { isDeleteActive } = this.state;
    const { isDeleteActive: prevIsDeleteActive } = prevState;

    if (isDeleteActive && betSlipState !== prevBetSlipState) {
      this.setIsDeleteActive(false);
    }

    if (prevIsDeleteActive !== isDeleteActive) {
      if (isDeleteActive) {
        document.addEventListener('touchstart', this.checkOutsideTouch);
      } else {
        document.removeEventListener('touchstart', this.checkOutsideTouch);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('touchstart', this.checkOutsideTouch);
    this.clearTimeoutId();
  }

  setIsDeleteActive = isDeleteActive => this.setState({ isDeleteActive });

  onOnTouchStart = (e) => {
    if (e.targetTouches.length === 1) {
      this.prevClientX = e.targetTouches[0].clientX;
    }
  };

  onOnTouchMove = (e) => {
    const { isTouchXDisabled } = this.props;
    const { isDeleteActive } = this.state;

    if (isTouchXDisabled) {
      return;
    }

    if (e.targetTouches.length === 1) {
      const deltaX = e.targetTouches[0].clientX - this.prevClientX;

      if (!isDeleteActive && deltaX < -20) {
        this.setIsDeleteActive(true);
      } else if (isDeleteActive && deltaX > 20) {
        this.setIsDeleteActive(false);
      }
    }
  };

  checkOutsideTouch = (e) => {
    const { stake: { stakeId } } = this.props;
    const { isDeleteActive } = this.state;

    if (isDeleteActive) {
      const path = e.path || (e.composedPath && e.composedPath());
      const { touchedStakeId } = R.compose(
        R.find(R.prop('touchedStakeId')),
        R.pluck('dataset')
      )(path) || {};

      if (touchedStakeId !== stakeId) {
        this.setIsDeleteActive(false);
      }
    }
  };

  onRemoveClick = () => {
    const {
      toggleStake,
      stake,
      setIsOpened,
      stakesAmount,
    } = this.props;

    if (stakesAmount === 1) {
      setIsOpened(false);
      this.clearTimeoutId();
      this.timeoutId = setTimeout(() => toggleStake(stake), 300); // 300ms - close animation
    } else {
      toggleStake(stake);
    }

    GA.event({
      category: 'bet-slip',
      label: 'delete-outcome',
    });
  };

  clearTimeoutId = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  };

  onFreebetClick = () => {
    const { setUsedFreebet, availableFreebetId, isFreebetUsed } = this.props;
    setUsedFreebet(isFreebetUsed ? null : availableFreebetId);
  };

  onEventClick = (e) => {
    const { dataset: { isOutright } } = e.currentTarget;
    const { setIsOpened, sendEventClickFeedback, stake } = this.props;

    if (!convertStringifiedBoolean(isOutright)) {
      setIsOpened(false);
      GA.event({
        category: 'go-to-event-page',
        label: 'bet-slip-event-click',
      });
    }

    sendEventClickFeedback(stake.eventId, stake.isLive);
  };

  render() {
    const {
      locale,
      stakesAmount,
      stake: {
        stakeId,
        betFactor,
        teamA,
        teamB,
        betAmount,
        returnAmount,
        marketName,
        outcomeName,
        error,
        isMultipleRestricted,
        isBetFactorDecreased,
        isBetFactorIncreased,
        isSuspended,
        isExpressDenied,
        isOutright,
        outrightName,
        eventId,
        isLive,
      },
      betType,
      currency,
      setBetAmount,
      betSlipState,
      isPlaceBetInProgress,
      setIsKeyboardShowed,
      availableFreebetId,
      isFreebetUsed,
      setIsPlaceBetDisabled,
    } = this.props;
    const { isDeleteActive, isDeleteSwipeShowed } = this.state;
    const isOrdinar = betType === BET_TYPE.ORDINAR;
    const isOneBet = stakesAmount === 1;
    const teams = isOutright ? outrightName : `${teamA} - ${teamB}`;
    const isOutOfBalance = error && error.errorId === BET_SLIP_ERRORS.BET_AMOUNT_OUT_OF_BALANCE;
    let errorIntlId;
    const LinkComponent = isOutright ? 'span' : RouteLink;

    if (error) {
      if (error.errorId === BET_SLIP_ERRORS.BET_AMOUNT_LESS_THAN_LIMIT) {
        errorIntlId = 'bet-slip.stake-limit-error.less.title';
      } else if (error.errorId === BET_SLIP_ERRORS.BET_AMOUNT_MORE_THAN_LIMIT) {
        errorIntlId = 'bet-slip.stake-limit-error.more.title';
      } else if (isOutOfBalance) {
        errorIntlId = 'bet-slip.out-of-balance-error.title';
      }
    }

    return (
      <div
        onTouchStart={this.onOnTouchStart}
        onTouchMove={this.onOnTouchMove}
        className={classNames('stake-item-mobile-wrapper d-flex', {
          'is-delete-active': isDeleteActive,
          'is-delete-swipe-showed': isDeleteSwipeShowed,
        })}
        data-touched-stake-id={stakeId}
      >
        <div className={classNames('stake-item-mobile p-1_5 bg-main-4 position-relative flex-shrink-0', {
          'is-suspended': !isOneBet && isSuspended,
        })}
        >
          <div className="d-flex justify-content-between font-weight-bold mb-0_25">
            <div className="d-flex align-items-center mr-1 overflow-hidden">
              <span title={outcomeName} className="text-truncate">
                {outcomeName}
              </span>
              {(isMultipleRestricted || isExpressDenied) && <IconWarning className="ml-0_5 flex-shrink-0" />}
            </div>

            {!!betFactor && (
              <span className={classNames('stake-item-mobile-bet-factor position-relative', {
                'is-decreased': isBetFactorDecreased && !isSuspended,
                'is-increased': isBetFactorIncreased && !isSuspended,
              })}
              >
                {betFactor}
              </span>
            )}
          </div>

          <div title={marketName} className="text-extra-2 caption text-truncate mb-0_25">{marketName}</div>
          {teams && (
            <LinkComponent
              locale={locale}
              to={`/event/${eventId}?isLive=${isLive}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.BET_SLIP}`}
              className={classNames('caption', { 'stake-item-event-link': !isOutright, 'text-extra-2': isOutright })}
              onClick={this.onEventClick}
              data-is-outright={isOutright}
            >
              {teams}
            </LinkComponent>
          )}

          {isOrdinar && (
            <>
              <div className="mt-2_5 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <BetAmountInput
                    stakeId={stakeId}
                    currency={currency}
                    betAmount={betAmount}
                    setBetAmount={setBetAmount}
                    betSlipState={betSlipState}
                    isPlaceBetInProgress={isPlaceBetInProgress}
                    setIsKeyboardShowed={setIsKeyboardShowed}
                    isFreebetUsed={isFreebetUsed}
                    error={error}
                    setIsPlaceBetDisabled={setIsPlaceBetDisabled}
                  />

                  {availableFreebetId && (
                    <>
                      <div
                        id={TOOLTIP_ID}
                        role="button"
                        tabIndex="0"
                        onClick={this.onFreebetClick}
                        onKeyPress={this.onFreebetClick}
                        className={classNames('stake-item-mobile-freebet d-flex align-items-center justify-content-center', { 'active bg-warning': isFreebetUsed })}
                      >
                        <IconFreebet className="stake-item-mobile-icon-freebet" />
                      </div>
                      {!isFreebetUsed && (
                        <UncontrolledTooltip placement="top" target={TOOLTIP_ID}>
                          <FormattedTag id="freebet.tooltip" className="text-small font-weight-bold" />
                        </UncontrolledTooltip>
                      )}
                    </>
                  )}
                </div>

                <div className="d-flex align-items-center ml-2">
                  <FormattedTag id="bet-slip.return" className="caption text-extra-2 text-nowrap mr-1" />
                  {returnAmount
                    ? (
                      <div className="d-flex align-items-center">
                        {error && !!error.prevReturnAmount && (
                          <span className="stake-item-mobile-prev-return mr-1_5 text-small text-extra-3 text-right text-break">
                            {mathRound(error.prevReturnAmount)} {prepareCurrency(currency)}
                          </span>
                        )}
                        <span className="font-weight-bold text-small text-break">{mathRound(returnAmount)} {prepareCurrency(currency)}</span>
                      </div>
                    )
                    : <span className="text-extra-3 text-small">–</span>}
                </div>
              </div>
              {error && (
                <div className="stake-item-mobile-error d-flex flex-column align-items-center pt-2 mt-2">
                  <div className="text-small mr-0_5">
                    <IconWarning className="mr-0_5 flex-shrink-0" />
                    <FormattedTag
                      id={errorIntlId}
                      className="font-weight-bold"
                      values={{ amount: error.prevBetAmount ? `${mathRound(error.prevBetAmount)} ${prepareCurrency(currency)}` : null }}
                    />
                  </div>
                  {!isOutOfBalance && (
                    <div className="d-flex align-items-center justify-content-center">
                      <FormattedTag id="bet-slip.stake-limit-error.subtitle" className="text-extra-2 text-small" />
                      {error && error.limit && (
                        <span className="ml-0_5 font-weight-bold">
                          {' '}
                          {mathRound(error.limit)} {prepareCurrency(currency)}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          {!isOneBet && isSuspended && (
            <FormattedTag
              id="bet-slip.suspended"
              className="stake-item-mobile-suspended-label h6 d-flex align-items-center position-absolute bg-info"
            />
          )}
        </div>

        <div
          role="button"
          tabIndex="0"
          onClick={this.onRemoveClick}
          onKeyPress={this.onRemoveClick}
          className="stake-item-mobile-delete-button bg-danger d-flex align-items-center justify-content-center flex-shrink-0"
        >
          <FormattedTag id="general.delete" className="text-small font-weight-bold" />
        </div>
      </div>
    );
  }
}
