import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BET_SLIP_STATE, AI_OUTCOME_SECTION } from 'core/constants';
import { getMainLineMarkets, isEmptyOrNil } from 'core/helpers';

import { RouteLink } from 'components/route-link/route-link';
import { SportTypeIcon } from 'components/sport-type-icon/sport-type-icon';
import { GameStatus } from 'components/betting-table/game-status/game-status';
import { IconArrowControl } from 'components/icons/icon-arrow-control/icon-arrow-control';
import { toTimeIfNearOrDate } from 'helpers/date';
import { prepareScore } from 'helpers/score';
import { GA } from 'helpers/ga';

import { SearchedGamesOutcomeGroup } from '../../searched-games-outcome-group/searched-games-outcome-group';
import { SPORTS_WITH_3WAY_MAIN_MARKET, SPORTS_WITHOUT_LIVE_SCORE } from '../../../../constants';

import './search-group-item.scss';

const SCROLL_OFFSET = 100;
export class SearchGroupItem extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    isLogoShowed: PropTypes.bool.isRequired,
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    toggleStake: PropTypes.func.isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    statuses: PropTypes.shape(),
    marketsInfo: PropTypes.shape(),
    desc: PropTypes.shape().isRequired,
    markets: PropTypes.shape().isRequired,
    score: PropTypes.shape().isRequired,
    state: PropTypes.shape().isRequired,
    isLive: PropTypes.bool.isRequired,
    isLiveFrozen: PropTypes.bool.isRequired,
    isPrematchFrozen: PropTypes.bool.isRequired,
    className: PropTypes.string,
    onLeagueClick: PropTypes.func.isRequired,
    onEventClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    statuses: null,
    marketsInfo: null,
    className: null,
  };

  oddsRef = createRef();
  gameOutcomeFiltersRef = createRef();

  resizeWindowSubsription;

  timeout = null;

  state = {
    isPrevShowed: false,
    isNextShowed: false,
  };

  componentDidMount() {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      this.resizeWindowSubsription = fromEvent(window, 'resize')
        .pipe(debounceTime(200))
        .subscribe(this.onScroll);

      this.timeout = setTimeout(() => {
        const { scrollWidth, clientWidth } = odds;

        if (scrollWidth > clientWidth) {
          this.setIsNextShowed(true);
        }
      }, 50);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  componentDidUpdate(prevProps) {
    const { markets: prevMarkets } = prevProps;
    const { markets } = this.props;

    if (!R.equals(prevMarkets, markets)) {
      this.onScroll();
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (this.resizeWindowSubsription) {
      this.resizeWindowSubsription.unsubscribe();
    }
  }

  setIsNextShowed = isNextShowed => this.setState({ isNextShowed });

  setIsPrevShowed = isPrevShowed => this.setState({ isPrevShowed });

  onScroll = () => {
    const { isPrevShowed, isNextShowed } = this.state;

    if (this.oddsRef && this.oddsRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = this.oddsRef.current;
      const preparedWidth = Math.ceil(scrollLeft + clientWidth);

      if (isPrevShowed) {
        if (scrollLeft === 0) {
          this.setIsPrevShowed(false);
        }
      } else if (scrollLeft > 0) {
        this.setIsPrevShowed(true);
      }

      if (isNextShowed) {
        if (preparedWidth >= scrollWidth) {
          this.setIsNextShowed(false);
        }
      } else if (preparedWidth < scrollWidth) {
        this.setIsNextShowed(true);
      }
    }
  };

  onPrevClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft - SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  onNextClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft + SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  onEventClick = (e) => {
    const { onEventClick } = this.props;
    onEventClick(e);
    GA.event({
      category: 'search',
      label: 'event-click',
    });
  };

  render() {
    const {
      locale,
      isLogoShowed,
      toggleStake,
      betSlipStakes,
      betSlipState,
      statuses,
      marketsInfo,
      desc: {
        id: eventId,
        sport: {
          id: sportId,
        },
        tournament: {
          id: leagueId,
          name: leagueName,
        },
        competitors,
        scheduled,
      },
      score: {
        home_score: homeScore,
        away_score: awayScore,
        home_dismissals: homeDismissals,
        away_dismissals: awayDismissals,
      },
      state: {
        match_status: eventStatus,
        clock,
      },
      markets,
      isLive,
      isLiveFrozen,
      isPrematchFrozen,
      className,
      onLeagueClick,
    } = this.props;
    const { isPrevShowed, isNextShowed } = this.state;
    const teamA = competitors[0].name;
    const teamB = competitors[1].name;
    const timestamp = scheduled * 1000;
    const is3way = SPORTS_WITH_3WAY_MAIN_MARKET.includes(sportId);
    const isFrozen = isLive ? isLiveFrozen : isPrematchFrozen;
    const marketsList = getMainLineMarkets(markets, marketsInfo, sportId, isFrozen);
    const isAnyMarketExists = marketsList.length > 0;

    const oddProps = {
      teamA,
      teamB,
      eventId,
      betSlipState,
      betSlipStakes,
      toggleStake,
      markets: marketsInfo,
      sportId,
      leagueId,
      isLive,
      isSearch: true,
    };

    return (
      <div className={classNames('search-group-item position-relative mb-2 d-flex justify-content-between align-items-center', className)}>
        <div className="search-group-item-head pr-1 d-flex flex-column overflow-hidden">
          <div className="d-flex align-items-center mb-1">
            {isLogoShowed && <SportTypeIcon sportId={sportId} width="16" height="16" isActive className="sport-type-icon d-flex mr-1" />}
            <RouteLink
              to={`/league/${sportId}/${leagueId}`}
              locale={locale}
              className="text-extra-2 text-truncate"
              onClick={onLeagueClick}
              data-league-id={leagueId}
            >
              {leagueName}
            </RouteLink>
          </div>

          <div className="d-flex flex-column overflow-hidden">
            <RouteLink
              locale={locale}
              to={`/event/${eventId}?isLive=${isLive}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.SEARCH}`}
              className="search-group-item-link text-small text-truncate"
              onClick={this.onEventClick}
              data-event-id={eventId}
              data-is-live={isLive}
            >
              {teamA} - {teamB}
            </RouteLink>

            <div className="d-flex caption text-truncate text-extra-3">
              {isLive
                ? (
                  <>
                    <GameStatus
                      locale={locale}
                      sportId={sportId}
                      eventStatus={eventStatus}
                      className="mr-0_5 caption"
                      statuses={statuses}
                      clock={clock}
                    />
                    {!SPORTS_WITHOUT_LIVE_SCORE.includes(sportId) && (
                      <>
                        &middot;
                        <div className="mx-0_5 font-weight-bold text-success">
                          {prepareScore(sportId, homeScore, homeDismissals)}
                          {' - '}
                          {prepareScore(sportId, awayScore, awayDismissals)}
                        </div>
                      </>
                    )}
                  </>
                )
                : <span className="mr-0_5">{toTimeIfNearOrDate(timestamp, locale)}</span>}
              &middot;
              <RouteLink
                locale={locale}
                to={`/league/${sportId}/${leagueId}`}
                className="ml-0_5 text-truncate"
                onClick={onLeagueClick}
                data-league-id={leagueId}
              >
                {leagueName}
              </RouteLink>
            </div>
          </div>
        </div>

        <div
          ref={this.gameOutcomeFiltersRef}
          className="search-group-item-odds-wrapper d-flex justify-content-between position-absolute"
        >
          <div
            ref={this.oddsRef}
            onScroll={this.onScroll}
            className="search-group-item-odds-scroll-bar w-100 d-flex align-items-end"
          >
            {isAnyMarketExists ? (
              <>
                {marketsList.map(({
                  marketInfo,
                  marketOutcome, isTotal, isHandicap, specifier, outcomes
                }, index) =>
                  !isEmptyOrNil(marketOutcome) && (
                    <SearchedGamesOutcomeGroup
                      key={marketInfo.id}
                      marketInfo={marketInfo}
                      marketOutcome={marketOutcome}
                      isTotal={isTotal}
                      isHandicap={isHandicap}
                      specifier={specifier}
                      outcomes={outcomes}
                      oddProps={oddProps}
                      gameOutcomeFiltersRef={this.gameOutcomeFiltersRef}
                      isLastMarket={marketsList.length === index + 1}
                      isFirstMarket={index === 0}
                    />
                  ))}
              </>
            ) : (
              <div className={classNames('searched-games-outcome-group', { 'with-draw': is3way })}>
                <div className="searched-games-outcome-group-odds-group">
                  <div className="odd-cell w-100 is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                    &#8212;
                  </div>
                  <div className="odd-cell w-100 is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                    &#8212;
                  </div>
                  {is3way && (
                    <div className="odd-cell w-100 is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                      &#8212;
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className={classNames('search-group-item-odds-prev position-absolute d-flex align-items-center', { invisible: !isPrevShowed })}>
            <div
              role="button"
              tabIndex="0"
              onClick={this.onPrevClick}
              onKeyPress={this.onPrevClick}
              className="search-group-item-odds-prev-btn d-flex justify-content-center align-items-center position-relative"
            >
              <IconArrowControl />
            </div>
          </div>

          <div className={classNames('search-group-item-odds-next position-absolute d-flex align-items-center justify-content-end', { invisible: !isNextShowed })}>
            <div
              role="button"
              tabIndex="0"
              onClick={this.onNextClick}
              onKeyPress={this.onNextClick}
              className="search-group-item-odds-next-btn d-flex justify-content-center align-items-center position-relative"
            >
              <IconArrowControl />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
