import { SPORTS } from '../constants';

export const prepareScore = (sportId, score, dismissals) => {
  if (sportId === SPORTS.CRICKET) {
    if (score) {
      if (score === '0') {
        if (dismissals) {
          return `0/${dismissals}`;
        }

        return '0';
      }

      return `${score}/${dismissals}`;
    }

    return '0/0';
  }

  return score || '0';
};

export const prepareHomeScore = (score, sportId, setAltScore) => prepareScore(score, sportId, setAltScore, true);

export const prepareAwayScore = (score, sportId, setAltScore) => prepareScore(score, sportId, setAltScore, false);

export const prepareBetsScore = (score) => {
  if (!score) {
    return null;
  }

  let result;

  try {
    result = score.split(' ')[0].split(':');
  } catch (_) {
    result = null;
  }

  return result;
};
