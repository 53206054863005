import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportDota = ({
  className, isActive, width, height,
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17 1H1V17H17V1ZM9.88889 4.55556L12.5556 3.66667L13.8889 4.55556L13.4444 6.77778L9.88889 4.55556ZM4.11111 10.3333L2.77778 13.4444L4.55556 14.7778L7.22222 13.4444L4.11111 10.3333ZM12.5556 14.3333L2.77778 4.55556V3.66667H4.11111L15.2222 10.7778L14.3333 14.3333H12.5556Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
  </svg>
);

IconSportDota.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportDota.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
