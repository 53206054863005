import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getConfig } from 'core/helpers';

import { GA } from 'helpers/ga';

import './bet-slip-presets.scss';

const CURRENCY_PRESETS = getConfig('CURRENCY_PRESETS') || {};

export class BetSlipPresets extends Component {
  static propTypes = {
    stakeId: PropTypes.string,
    currency: PropTypes.string.isRequired,
    setBetAmount: PropTypes.func.isRequired,
    isPlaceBetInProgress: PropTypes.bool.isRequired,
    isFreebetUsed: PropTypes.bool,
  };

  static defaultProps = {
    stakeId: null,
    isFreebetUsed: false,
  };

  onClick = (e) => {
    const { dataset: { preset, idx } } = e.currentTarget;
    const {
      setBetAmount,
      isPlaceBetInProgress,
      stakeId,
      isFreebetUsed
    } = this.props;
    let label;

    if (!isPlaceBetInProgress && !isFreebetUsed) {
      setBetAmount({ stakeId, betAmount: Number(preset) });
    }

    if (idx === '0') {
      label = 'preset1-click';
    } else if (idx === '1') {
      label = 'preset2-click';
    } else if (idx === '2') {
      label = 'preset3-click';
    } else if (idx === '3') {
      label = 'preset4-click';
    }

    if (label) {
      GA.event({
        category: 'bet-slip',
        label,
      });
    }
  };

  render() {
    const { currency } = this.props;
    const presets = CURRENCY_PRESETS[currency];

    return presets && (
      <div className="bet-slip-presets rounded mt-1_5">
        {presets.map((preset, idx) => (
          <div
            key={preset}
            role="button"
            tabIndex="0"
            onClick={this.onClick}
            onKeyPress={this.onClick}
            data-preset={preset}
            data-idx={idx}
            className="bet-slip-presets-item d-flex align-items-center justify-content-center text-small font-weight-bold bg-main-4"
          >
            {preset}
          </div>
        ))}
      </div>
    );
  }
}
