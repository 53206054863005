import React, { Component } from 'react';
import { pick } from 'ramda';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { withCoreComponent } from 'core/hocs';
import { ModalWindow as ModalWindowCore } from 'core/components';

import './modal-window.scss';

const MODAL_SHOWN_CLASS = 'modal-shown';

export class ModalWindowUI extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    onOpened: PropTypes.func,
    bodyClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    modalClassName: PropTypes.string,
    children: PropTypes.node.isRequired,
    isCentered: PropTypes.bool,
    fade: PropTypes.bool,
    className: PropTypes.string,
    isFullScreenSm: PropTypes.bool,
    isCloseButtonXs: PropTypes.bool,
    closeButtonClassName: PropTypes.string,
    customCloseButton: PropTypes.node,
    zIndex: PropTypes.number,
  };

  static defaultProps = {
    isOpen: false,
    bodyClassName: '',
    contentClassName: '',
    isCentered: true,
    fade: false,
    onOpened: null,
    className: '',
    modalClassName: '',
    isFullScreenSm: false,
    isCloseButtonXs: false,
    closeButtonClassName: '',
    customCloseButton: null,
    zIndex: 1050,
  };

  toggle = () => {
    const {
      isOpen, id, openModal, closeModal,
    } = this.props;

    if (isOpen) {
      closeModal(id);
      document.body.classList.remove(MODAL_SHOWN_CLASS);
    } else {
      openModal(id);
    }
  };

  onOpened = () => {
    const { onOpened } = this.props;

    if (onOpened) {
      onOpened();
    }

    document.body.classList.add(MODAL_SHOWN_CLASS);
  }

  render() {
    const {
      isOpen,
      children,
      isCentered,
      bodyClassName,
      fade,
      className,
      modalClassName,
      contentClassName,
      isFullScreenSm,
      isCloseButtonXs,
      closeButtonClassName,
      customCloseButton,
      zIndex,
      ...restProps
    } = this.props;

    return (
      <Modal
        zIndex={zIndex}
        isOpen={isOpen}
        fade={fade}
        centered={isCentered}
        external={(
          <div>
            <button
              type="button"
              onClick={this.toggle}
              className={classNames(closeButtonClassName, 'close-button d-flex justify-content-center', {
                'close-button-xs': isCloseButtonXs,
              })}
            >
              {customCloseButton || <span className="position-absolute close-button-inner">&times;</span>}
            </button>
          </div>
        )}
        toggle={this.toggle}
        contentClassName={contentClassName}
        // Don't allow unwanted props down to reactstrap Modal component
        {...pick(Object.keys(Modal.propTypes), restProps)}
        onOpened={this.onOpened}
        modalClassName={modalClassName}
        className={classNames(className, { 'is-full-screen-sm': isFullScreenSm })}
      >
        <ModalBody className={bodyClassName}>
          {children}
        </ModalBody>
      </Modal>
    );
  }
}

export const ModalWindow = withCoreComponent(ModalWindowCore, ModalWindowUI);
