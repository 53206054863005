import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportQuake = ({
  className, isActive, width, height,
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 5.24487C3.5 8.2262 5.92023 10.6718 9 10.9126V8H8V7H11V8H10V10.9126C13.0798 10.6718 15.5 8.2262 15.5 5.24487C15.5 2.88489 13.9835 0.860718 11.8238 0C14.266 0.967041 16 3.41357 16 6.27881C16 9.81689 13.3562 12.7164 10 12.9805V18H9V12.9805C5.64377 12.7164 3 9.81689 3 6.27881C3 3.41357 4.73404 0.966919 7.17618 0C5.01648 0.860718 3.5 2.88489 3.5 5.24487Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
  </svg>
);

IconSportQuake.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportQuake.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
