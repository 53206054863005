import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BET_TYPE } from 'core/constants';
import { checkIsComplexSystem } from 'core/helpers';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconPartialPlaced } from 'components/icons/icon-partial-placed/icon-partial-placed';
import { GA } from 'helpers/ga';
import { BetTypeDropdown } from './bet-type-dropdown/bet-type-dropdown';
import { StickyHeader } from './sticky-header/sticky-header';

import './bet-slip-header.scss';

const ORDINAR_ITEM = 'ORDINAR_ITEM';
const EXPRESS_ITEM = 'EXPRESS_ITEM';

const prepareSelectedItem = (systemIndex, betType) => {
  if (betType === BET_TYPE.SYSTEM && systemIndex) {
    return systemIndex;
  }

  if (betType === BET_TYPE.EXPRESS) {
    return EXPRESS_ITEM;
  }

  return ORDINAR_ITEM;
};

export class BetSlipHeader extends Component {
  static propTypes = {
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    clearBetSlip: PropTypes.func.isRequired,
    setBetType: PropTypes.func.isRequired,
    stakesAmount: PropTypes.number.isRequired,
    systemIndex: PropTypes.number,
    isMobile: PropTypes.bool,
    setIsOpened: PropTypes.func,
    isPartialPlaced: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    systemIndex: null,
    isMobile: false,
    setIsOpened: null,
  };

  timeoutId;

  state = {
    isHidden: false,
    selectedItem: prepareSelectedItem(this.props.systemIndex, this.props.betType),
  };

  componentDidUpdate(prevProps) {
    const { systemIndex, betType } = this.props;
    const { betType: prevBetType } = prevProps;
    const { selectedItem } = this.state;

    if (systemIndex && systemIndex !== selectedItem && selectedItem !== EXPRESS_ITEM && selectedItem !== ORDINAR_ITEM) {
      this.setSelectedItem(systemIndex);
    }

    if (prevBetType !== betType) {
      this.setSelectedItem(prepareSelectedItem(systemIndex, betType));
    }
  }

  componentWillUnmount() {
    this.clearTimeoutId();
  }

  setSelectedItem = selectedItem => this.setState({ selectedItem });

  onOrdinarClick = (e) => {
    e.stopPropagation();
    const { setBetType } = this.props;
    const { selectedItem } = this.state;

    if (selectedItem !== ORDINAR_ITEM) {
      setBetType({ betType: BET_TYPE.ORDINAR });
      this.setSelectedItem(ORDINAR_ITEM);
    }
  };

  onExpressClick = (e) => {
    e.stopPropagation();
    const { setBetType } = this.props;
    const { selectedItem } = this.state;

    if (selectedItem !== EXPRESS_ITEM) {
      setBetType({ betType: BET_TYPE.EXPRESS });
      this.setSelectedItem(EXPRESS_ITEM);
    }
  };

  onSystemClick = (e) => {
    e.stopPropagation();
    const { dataset: { systemIndex } } = e.currentTarget;
    const { setBetType } = this.props;
    const { selectedItem } = this.state;
    const isComplexSystem = checkIsComplexSystem(systemIndex);
    const checkedSelectedItem = isComplexSystem ? selectedItem : Number(selectedItem);
    const checkedSystemIndex = isComplexSystem ? systemIndex : Number(systemIndex);

    if (checkedSelectedItem !== checkedSystemIndex) {
      setBetType({ betType: BET_TYPE.SYSTEM, systemIndex: checkedSystemIndex });
      this.setSelectedItem(systemIndex);
    }
  };

  setIsHidden = isHidden => this.setState({ isHidden });

  onClearClick = () => {
    const { clearBetSlip, isMobile, setIsOpened } = this.props;

    if (isMobile && setIsOpened) {
      setIsOpened(false);
      this.clearTimeoutId();
      this.timeoutId = setTimeout(clearBetSlip, 300); // 300ms - close animation
    } else {
      clearBetSlip();
    }

    GA.event({
      category: 'bet-slip',
      label: 'clear-click',
    });
  };

  clearTimeoutId = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  };

  render() {
    const { stakesAmount, isPartialPlaced } = this.props;
    const { isHidden, selectedItem } = this.state;
    const isDropdownShowed = stakesAmount > 1;

    return isPartialPlaced
      ? (
        <div className="bet-slip-header partial-placed px-2 d-flex align-items-center">
          <IconPartialPlaced className="flex-shrink-0" />
          <FormattedTag id="bet-slip.partial-placed" className="text-small font-weight-bold ml-1_5" />
        </div>
      )
      : (
        <Fragment>
          <div className={classNames('bet-slip-header d-flex align-items-center justify-content-between pr-1_5 bg-main-2', { 'is-hidden': isHidden })}>
            {isDropdownShowed
              ? (
                <BetTypeDropdown
                  selectedItem={selectedItem}
                  onOrdinarClick={this.onOrdinarClick}
                  onExpressClick={this.onExpressClick}
                  onSystemClick={this.onSystemClick}
                  stakesAmount={stakesAmount}
                />
              )
              : <FormattedTag id="bet-slip.type.ordinar" className="caption font-weight-bold text-extra-2" />}

            <FormattedTag
              id="bet-slip.clear"
              role="button"
              tabIndex="0"
              onClick={this.onClearClick}
              onKeyPress={this.onClearClick}
              className="caption text-extra-2"
            />
          </div>
          <StickyHeader
            {...this.props}
            isDropdownShowed={isDropdownShowed}
            onClearClick={this.onClearClick}
            setIsHidden={this.setIsHidden}
            selectedItem={selectedItem}
            onOrdinarClick={this.onOrdinarClick}
            onExpressClick={this.onExpressClick}
            onSystemClick={this.onSystemClick}
          />
        </Fragment>
      );
  }
}
