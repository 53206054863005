import { mathRound } from 'core/helpers';

import { CURRENCY_SYMBOLS } from '../constants';

export const prepareCurrency = (currency) => {
  if (currency === 'TRY') {
    return 'TL';
  }

  if (currency === 'BDT') {
    return 'Tk';
  }

  return currency;
};

export const formatAmountWithCurrencySymbol = (amount, currency) => {
  const formattedAmount = amount && mathRound(amount);

  if (currency === 'TRY') {
    return `${formattedAmount} TL`;
  }

  if (currency === 'BDT') {
    return `${formattedAmount} Tk`;
  }

  return `${CURRENCY_SYMBOLS[currency]}${formattedAmount}`;
};
