import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BET_SLIP_STATE, AI_OUTCOME_SECTION } from 'core/constants';
import { getConfig, getMainLineMarkets, isEmptyOrNil } from 'core/helpers';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { SportTypeIcon } from 'components/sport-type-icon/sport-type-icon';
import { GameStatus } from 'components/betting-table/game-status/game-status';
import { OddsCell } from 'components/betting-table/odds-cell/odds-cell';
import { TeamColumn } from 'components/betting-table/team-column/team-column';
import { IconLivestreamSmall } from 'components/icons/icon-livestream-small/icon-livestream-small';
import { toTimeIfNearOrDate, toTodayOrTomorrow } from 'helpers/date';
import { prepareMarketName } from 'helpers/stake';
import { prepareScore } from 'helpers/score';
import { prepareLogo } from 'helpers/logo';
import { GA } from 'helpers/ga';

import {
  SPORTS_WITH_3WAY_MAIN_MARKET,
  TODAY,
  TOMORROW,
  SPORTS_WITHOUT_LIVE_SCORE,
} from '../../../../constants';

import './search-group-item.scss';

const IS_LIVE_STREAMS_ENABLED = getConfig('IS_LIVE_STREAMS_ENABLED');

const SCROLL_OFFSET = 100;

export class SearchGroupItem extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    isLogoShowed: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    toggleStake: PropTypes.func.isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    logoUrl: PropTypes.string.isRequired,
    logoUrl2: PropTypes.string.isRequired,
    statuses: PropTypes.shape(),
    streams: PropTypes.shape(),
    marketsInfo: PropTypes.shape(),
    desc: PropTypes.shape().isRequired,
    score: PropTypes.shape().isRequired,
    state: PropTypes.shape().isRequired,
    markets: PropTypes.shape().isRequired,
    isLive: PropTypes.bool.isRequired,
    isLiveFrozen: PropTypes.bool.isRequired,
    isPrematchFrozen: PropTypes.bool.isRequired,
    onLeagueClick: PropTypes.func.isRequired,
    onEventClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    statuses: null,
    streams: null,
    marketsInfo: null,
  };

  oddsRef = createRef();

  resizeWindowSubsription;

  state = {
    isPrevShowed: false,
    isNextShowed: false,
  };

  componentDidMount() {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollWidth, clientWidth } = odds;
      this.resizeWindowSubsription = fromEvent(window, 'resize')
        .pipe(debounceTime(200))
        .subscribe(this.onScroll);

      if (scrollWidth > clientWidth) {
        this.setIsNextShowed(true);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  componentDidUpdate(prevProps) {
    const { markets: prevMarkets } = prevProps;
    const { markets } = this.props;

    if (!R.equals(prevMarkets, markets)) {
      this.onScroll();
    }
  }

  componentWillUnmount() {
    if (this.resizeWindowSubsription) {
      this.resizeWindowSubsription.unsubscribe();
    }
  }

  setIsNextShowed = isNextShowed => this.setState({ isNextShowed });

  setIsPrevShowed = isPrevShowed => this.setState({ isPrevShowed });

  onScroll = () => {
    const { isPrevShowed, isNextShowed } = this.state;

    if (this.oddsRef && this.oddsRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = this.oddsRef.current;
      const preparedWidth = Math.ceil(scrollLeft + clientWidth);

      if (isPrevShowed) {
        if (scrollLeft === 0) {
          this.setIsPrevShowed(false);
        }
      } else if (scrollLeft > 0) {
        this.setIsPrevShowed(true);
      }

      if (isNextShowed) {
        if (preparedWidth >= scrollWidth) {
          this.setIsNextShowed(false);
        }
      } else if (preparedWidth < scrollWidth) {
        this.setIsNextShowed(true);
      }
    }
  };

  onPrevClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft - SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  onNextClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft + SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  onEventClick = (e) => {
    const { onEventClick } = this.props;
    onEventClick(e);
    GA.event({
      category: 'search',
      label: 'event-click',
    });
  };

  render() {
    const {
      locale,
      isLogoShowed,
      toggleStake,
      betSlipStakes,
      betSlipState,
      statuses,
      logoUrl,
      logoUrl2,
      streams,
      marketsInfo,
      desc: {
        id: eventId,
        sport: {
          id: sportId,
        },
        tournament: {
          id: leagueId,
          name: leagueName,
        },
        category,
        competitors,
        scheduled,
      },
      score: {
        home_score: homeScore,
        away_score: awayScore,
        home_dismissals: homeDismissals,
        away_dismissals: awayDismissals,
      },
      state: {
        match_status: eventStatus,
        clock,
      },
      markets,
      isLive,
      isLiveFrozen,
      isPrematchFrozen,
      onLeagueClick,
    } = this.props;
    const { isPrevShowed, isNextShowed } = this.state;
    let is3way = SPORTS_WITH_3WAY_MAIN_MARKET.includes(sportId);
    const isFrozen = isLive ? isLiveFrozen : isPrematchFrozen;
    const teamA = competitors[0].name;
    const teamB = competitors[1].name;
    const timestamp = scheduled * 1000;
    const formattedDate = toTodayOrTomorrow(timestamp);
    const marketsList = getMainLineMarkets(markets, marketsInfo, sportId, isFrozen);
    const isAnyMarketExists = marketsList.length > 0;
    const isOneMarket = marketsList.length < 2;
    const teamALogo = prepareLogo({
      logoUrl,
      logoUrl2,
      competitorId: competitors[0].id,
      sportId,
      categorySlug: category.slug,
      countryCode: competitors[0].country_code,
    });
    const teamBLogo = prepareLogo({
      logoUrl,
      logoUrl2,
      competitorId: competitors[1].id,
      sportId,
      categorySlug: category.slug,
      countryCode: competitors[1].country_code,
    });
    const hasLiveStream = Boolean(streams && streams[eventId]);

    const oddProps = {
      teamA,
      teamB,
      eventId,
      betSlipState,
      betSlipStakes,
      toggleStake,
      markets: marketsInfo,
      sportId,
      leagueId,
      isLive,
      isSearch: true,
    };

    return (
      <div className="search-group-item-mobile">
        <div className="search-group-item-mobile-head d-flex align-items-center rounded-top bg-main-2 px-2">
          {isLogoShowed && <SportTypeIcon sportId={sportId} width="12" height="12" isActive className="sport-type-icon mr-1 d-flex" />}
          <RouteLink
            locale={locale}
            to={`/league/${sportId}/${leagueId}`}
            className="text-truncate text-extra-2 caption"
            onClick={onLeagueClick}
            data-league-id={leagueId}
          >
            {leagueName}
          </RouteLink>
        </div>

        <div key={eventId} className={classNames('search-group-item-mobile-body position-relative bg-main-2 px-2 pt-1_5 rounded-bottom', { 'is-markets': isAnyMarketExists })}>
          <RouteLink
            locale={locale}
            to={`/event/${eventId}?isLive=${isLive}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.SEARCH}`}
            className="d-flex"
            onClick={this.onEventClick}
            data-event-id={eventId}
            data-is-live={isLive}
          >
            <div className="flex-fill align-self-center overflow-hidden">
              <TeamColumn name={teamA} logo={teamALogo} sportId={sportId} className="mb-0_5" />
              <TeamColumn name={teamB} logo={teamBLogo} sportId={sportId} />
            </div>
            <div className={classNames('d-flex align-self-start', {
              'text-extra-3': !isLive,
            })}
            >
              {IS_LIVE_STREAMS_ENABLED && hasLiveStream && <IconLivestreamSmall className="mr-1 mt-0_25" />}
              <span className="animate-minute overflow-hidden caption">
                {isLive
                  ? (
                    <GameStatus
                      locale={locale}
                      sportId={sportId}
                      eventStatus={eventStatus}
                      className="search-group-item-mobile-status text-right text-extra-2"
                      statuses={statuses}
                      clock={clock}
                    />
                  )
                  : (
                    <div className="d-flex flex-column justify-content-center text-extra-3">
                      <div className="text-nowrap">
                        {formattedDate === TODAY && <FormattedTag id="general.today" />}
                        {formattedDate === TOMORROW && <FormattedTag id="general.tomorrow" />}
                      &nbsp;
                        {toTimeIfNearOrDate(timestamp, locale)}
                      </div>
                    </div>
                  )}
              </span>
            </div>
            {isLive && !SPORTS_WITHOUT_LIVE_SCORE.includes(sportId) && (
              <Fragment>
                <span className="search-group-item-mobile-score-divider mx-2" />
                <div className="d-flex flex-column text-small font-weight-bold text-right text-success">
                  <span>{prepareScore(sportId, homeScore, homeDismissals)}</span>
                  <span>{prepareScore(sportId, awayScore, awayDismissals)}</span>
                </div>
              </Fragment>
            )}
          </RouteLink>

          <div className="search-group-item-mobile-odds-wrapper d-flex justify-content-between position-absolute">
            <div
              ref={this.oddsRef}
              onScroll={this.onScroll}
              className="search-group-item-mobile-odds-scroll-bar w-100 d-flex"
            >
              {isAnyMarketExists ? (
                marketsList.map(({
                  marketInfo,
                  marketOutcome, isTotal, isHandicap, specifier
                }, index) => {
                  const { id: marketId, variants } = marketInfo;
                  const marketName = prepareMarketName({
                    marketInfo, teamA, teamB, specifier, marketId,
                  });
                  is3way = marketOutcome && Object.values(marketOutcome).length > 2;
                  const isLastMarket = marketsList.length === index + 1;

                  return !isEmptyOrNil(marketOutcome) && (
                    <div key={marketId} className={classNames('search-group-item-mobile-odds-item d-flex flex-column flex-shrink-0 position-relative', { 'is-one-market': isOneMarket, 'with-draw': is3way, 'mr-1_5': !isLastMarket })}>
                      <div className="market-name overflow-hidden caption position-absolute mb-0_5 mt-1_5">
                        <span className="text-nowrap text-extra-3">
                          {marketName}
                        </span>
                      </div>
                      <div className="search-group-item-mobile-odds-group mt-4 flex-shrink-0">
                        {Object.entries(marketOutcome).map(([outcomeId, outcomeValue]) =>
                          outcomeValue ? (
                            <OddsCell
                              key={`${eventId}-${marketId}-${specifier}-${outcomeId}`}
                              stake={outcomeValue}
                              outcomeId={outcomeId}
                              marketId={marketId}
                              isTotal={isTotal}
                              isHandicap={isHandicap}
                              specifier={specifier}
                              variants={variants}
                              {...oddProps}
                            />
                          ) : (
                            <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                              &#8212;
                            </div>
                          ))}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={classNames('search-group-item-mobile-odds-item w-100 mt-1_5', { 'with-draw': is3way })}>
                  <div className="search-group-item-mobile-odds-group">
                    <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                      &#8212;
                    </div>
                    <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                      &#8212;
                    </div>
                    {is3way && (
                      <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                        &#8212;
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div
              role="button"
              tabIndex="0"
              onClick={this.onPrevClick}
              onKeyPress={this.onPrevClick}
              className={classNames('search-group-item-mobile-odds-prev position-absolute', { invisible: !isPrevShowed })}
            />

            <div
              role="button"
              tabIndex="0"
              onClick={this.onNextClick}
              onKeyPress={this.onNextClick}
              className={classNames('search-group-item-mobile-odds-next position-absolute', { invisible: !isNextShowed })}
            />
          </div>
        </div>
      </div>
    );
  }
}
