import React from 'react';
import PropTypes from 'prop-types';
import { getEnv } from 'core/helpers';

import './live-streams.scss';

const rindlesUrl = getEnv('BETBY_RINDLES_URL');

if (!rindlesUrl) {
  throw new Error('BETBY_RINDLES_URL must be set');
}

export const LiveStreams = ({ activeItem, parent }) => activeItem && (
  <div className="live-streams">
    <div className="live-streams-iframe-container position-relative">
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        src={`${rindlesUrl}/rindle.html?rindleUrl=${encodeURIComponent(`${activeItem.url}&parent=${window.location.hostname}${parent ? `&parent=${parent}` : ''}&muted=true`)}`}
        allow="autoplay;fullscreen"
        frameBorder="0"
        width="100%"
        height="100%"
        className="live-streams-iframe position-absolute"
      />
    </div>
  </div>
);

LiveStreams.propTypes = {
  activeItem: PropTypes.shape(),
  parent: PropTypes.string,
};

LiveStreams.defaultProps = {
  activeItem: null,
  parent: null,
};
