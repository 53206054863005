import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { withBetSlip, withUser, withModalActions } from 'core/hocs';
import { isEmptyOrNil, getShowedFreebetsData, setShowedFreebetsData } from 'core/helpers';
import { BET_SLIP_STORE_FIELDS, USER_STORE_FIELDS } from 'core/constants';

import { SkipServerRender } from 'components/skip-server-render/skip-server-render';
import { FreebetsModal } from 'components/modals/freebets-modal/freebets-modal';
import { MODAL_IDS, FREEBET_TYPES } from '../../constants';

class FreebetsUI extends Component {
  static propTypes = {
    freebets: PropTypes.arrayOf(PropTypes.shape()),
    currency: PropTypes.string.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    freebets: null,
  };

  state = {
    showedId: null,
  };

  componentDidUpdate(prevProps) {
    const { freebets } = this.props;
    const { freebets: prevFreebets } = prevProps;

    if ((isEmptyOrNil(prevFreebets) && !isEmptyOrNil(freebets))
    || (prevFreebets && freebets && freebets.length !== prevFreebets.length)) {
      this.showFreebet();
    }
  }

  showFreebet = () => {
    const { freebets } = this.props;
    const showedFreebetsData = getShowedFreebetsData();
    const foundFreebet = R.compose(
      R.head,
      R.reject(({ id, freebet_data: { type }, status }) =>
        showedFreebetsData.includes(id) || type !== FREEBET_TYPES.BET_REFUND || status !== 'new')
    )(freebets);

    if (foundFreebet) {
      this.setShowedId(foundFreebet.id);
      setShowedFreebetsData(showedFreebetsData.concat(foundFreebet.id));
    }
  };

  setShowedId = (showedId) => {
    const { openModal } = this.props;
    this.setState({ showedId });

    if (showedId) {
      openModal(MODAL_IDS.FREEBETS);
    }
  };

  onClosed = () => {
    this.setShowedId(null);
    this.showFreebet();
  };

  render() {
    const { freebets, currency } = this.props;
    const { showedId } = this.state;
    const showedFreebet = showedId && freebets && R.find(R.propEq('id', showedId), freebets);

    return showedFreebet && (
      <SkipServerRender skip>
        <FreebetsModal
          key={showedId}
          freebet={showedFreebet}
          currency={currency}
          onClosed={this.onClosed}
        />
      </SkipServerRender>
    );
  }
}

export const Freebets = withModalActions(
  withUser(
    withBetSlip(FreebetsUI, [BET_SLIP_STORE_FIELDS.FREEBETS]),
    [USER_STORE_FIELDS.CURRENCY]
  )
);

