import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import loadable from '@loadable/component';
import { fromEvent } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { matchRoutes } from 'react-router-config';
import Container from 'reactstrap/lib/Container';
import { withCoreComponent } from 'core/hocs';
import { App as AppCore } from 'core/app';

import { GlobalNotificationsStack } from 'components/global-notifications-stack/global-notifications-stack';
import { MediaQuery } from 'components/media-query/media-query';
import { IconsDefs } from 'components/icons/icons-defs';
import { Header } from 'components/header/header';
import { LeftSidebar } from 'components/left-sidebar/left-sidebar';
import { RightSidebar } from 'components/right-sidebar/right-sidebar';
import { LocationTracker } from 'components/location-tracker/location-tracker';
import { SearchedGamesResultDesktop } from 'components/searched-games/searched-games-result-desktop/searched-games-result-desktop';
import { NavigationBar } from 'components/navigation-bar/navigation-bar';
import { Freebets } from 'components/freebets/freebets';
import { PAGE_NAMES } from './constants';
import './locales';

import './app.scss';

const PageNotFound = loadable(() => import(/* webpackChunkName: "page-not-found" */ './pages/page-not-found/page-not-found'));
const Maintenance = loadable(() => import(/* webpackChunkName: "maintenance" */ './pages/maintenance/maintenance'));

export class AppUI extends Component {
  static propTypes = {
    renderRoutes: PropTypes.func.isRequired,
    route: PropTypes.shape({
      routes: PropTypes.arrayOf(
        PropTypes.shape({
          path: PropTypes.string,
          exact: PropTypes.bool,
        }),
      ),
    }).isRequired,
    is404: PropTypes.bool.isRequired,
    history: PropTypes.shape().isRequired,
    isMaintenance: PropTypes.bool.isRequired,
  };

  resizeWindowSubsription;

  state = {
    isSearchOpen: false,
  };

  componentDidMount() {
    this.resizeWindowSubsription = fromEvent(window, 'resize')
      .pipe(startWith('init'))
      .subscribe(this.setVhProperty);
  }

  componentWillUnmount() {
    if (this.resizeWindowSubsription) {
      this.resizeWindowSubsription.unsubscribe();
    }
  }

  setVhProperty = () => {
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  }

  setSearchOpen = isSearchOpen => this.setState({ isSearchOpen });

  render() {
    const {
      renderRoutes,
      route: { routes },
      is404,
      history,
      isMaintenance,
    } = this.props;
    const { isSearchOpen } = this.state;
    const { search, pathname } = history.location;
    const [routeInfo] = matchRoutes(routes, pathname);
    const currentRoute = R.propOr({}, 'route', routeInfo);
    const params = R.path(['match', 'params'], routeInfo);
    const {
      page: pageName,
    } = currentRoute;
    const withMainBg = pageName !== PAGE_NAMES.HOME && pageName !== PAGE_NAMES.EVENT && pageName !== PAGE_NAMES.SEARCH;

    if (isMaintenance) {
      return <Maintenance />;
    }

    return is404
      ? <PageNotFound />
      : (
        <Fragment>
          <GlobalNotificationsStack />
          {withMainBg && (
            <div className="main-bg-wrapper overflow-hidden position-absolute">
              <div className={`main-bg position-absolute main-bg-default main-bg-${params.sportId}`} />
            </div>
          )}

          <div className="d-flex main-content">
            <LeftSidebar
              pageName={pageName}
              params={params}
              search={search}
              isSearchOpen={isSearchOpen}
              setSearchOpen={this.setSearchOpen}
            />

            <Container
              fluid
              className="d-flex flex-column central-part position-relative px-0 px-sm-2"
            >
              <Header pageName={pageName} params={params} />

              {renderRoutes(routes)}

              <MediaQuery up="lg">
                <SearchedGamesResultDesktop isSearchOpen={isSearchOpen} setSearchOpen={this.setSearchOpen} />
              </MediaQuery>

              <div className="bottom-stub flex-shrink-0" />
            </Container>
            <RightSidebar pageName={pageName} params={params} />
          </div>

          <MediaQuery down="md">
            <NavigationBar pageName={pageName} />
          </MediaQuery>

          <Freebets />

          <LocationTracker pageName={pageName} />
          <IconsDefs />
          {/* <GlobalEventsHandler /> */}
        </Fragment>
      );
  }
}

export const App = withCoreComponent(AppCore, AppUI);
