import * as R from 'ramda';

export const prepareSearchedGames = (searchedGames, searchValue) => R.compose(
  R.reject(({ teams }) => !teams.length),
  R.map(
    (([key, value]) => ({
      sportId: key,
      sportName: value[0].desc.sport.name,
      teams: R.compose(
        R.reject(({ games }) => !games.length),
        R.map(name => ({
          name,
          games: R.filter(
            ({
              desc: { sport: { id: sportId }, competitors },
              isOutright = false,
            }) => key === sportId && !isOutright && (name === competitors[0].name || name === competitors[1].name),
            searchedGames,
          ),
          // outrights: R.filter(
          //   ({ sportId, teamA, isOutright }) => Number(key) === sportId && isOutright && name === teamA,
          //   searchedGames,
          // ),
          outrights: [],
        })),
        R.filter(team => team.toLowerCase().includes(searchValue.toLowerCase().split(' - ')[0])),
        R.uniq,
        R.flatten,
        R.pluck('strings')
      )(value)
    })),
  ),
  games => Object.entries(R.groupBy(R.path(['desc', 'sport', 'id']), games)),
)(searchedGames);

export const filterBySport = (searchedGames, sportId) => R.filter(R.propEq('sportId', sportId), searchedGames);

export const prepareSports = ({ events = [], leagues = [] } = {}, sports = []) => {
  const res = [];

  sports.forEach((sport) => {
    if (!res.find(({ sportId }) => sport.sportId === sportId)
    && (events.find(({ desc: { sport: { id } } }) => sport.sportId === id)
    || leagues.find(({ sportId }) => sport.sportId === sportId))) {
      res.push({ sportId: sport.sportId, sportName: sport.sportName });
    }
  });

  return res;
};

export const filterEventsBySport = (events = [], sportId) =>
  R.filter(({ desc: { sport: { id } } }) => sportId === id, events);

export const filterLeaguesBySport = (leagues = [], sportId) => R.filter(R.propEq('sportId', sportId), leagues);
