import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BET_SLIP_STATE } from 'core/constants';

import { Portal } from 'components/portal/portal';
import { prepareCurrency } from 'helpers/currency';
import { isInvalidStake } from 'helpers/validate';
import { BetSlipKeyboard } from '../bet-slip-keyboard/bet-slip-keyboard';

import './bet-amount-input.scss';

// indent from top screen to betslip with dropdown header
// height ticket-mobile-bet-slip-title = 48
// height bet-slip-sticky-header = 44
// paddingTop ticket-mobile = 68
// top indent = 6
const TOP_INDENT_WITH_HEADER = 166;
// indent from top screen to betslip without dropdown header
// height bet-slip-sticky-header = 44
// paddingTop ticket-mobile = 68
// top indent = 6
const TOP_INDENT = 118;
// indent from bottom: height of keyboard with height ща view button
// height bet-slip-keyboard = 261
const BOTTOM_INDENT = 261;

const scrollToInput = (ticketMobile) => {
  if (ticketMobile) {
    const headerElement = document.querySelector('.bet-slip-header');
    const isHiddenHeader = headerElement && headerElement.classList.contains('is-hidden');
    const { top: inputOffsetTop, bottom: inputOffsetBottom } = ticketMobile.getBoundingClientRect();
    const screenHeight = document.body.clientHeight;
    const topIndent = isHiddenHeader ? TOP_INDENT_WITH_HEADER : TOP_INDENT;

    if (inputOffsetTop < topIndent || inputOffsetBottom > (screenHeight - BOTTOM_INDENT)) {
      const scrollableArea = document.querySelector('.ticket-mobile-scrollable-area');

      if (scrollableArea) {
        scrollableArea.scrollTop = scrollableArea.scrollTop + inputOffsetTop - TOP_INDENT_WITH_HEADER;
      }
    }
  }
};

export class BetAmountInput extends Component {
  static propTypes = {
    stakeId: PropTypes.string,
    currency: PropTypes.string.isRequired,
    betAmount: PropTypes.number,
    setBetAmount: PropTypes.func.isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    isPlaceBetInProgress: PropTypes.bool.isRequired,
    error: PropTypes.shape(),
    setIsKeyboardShowed: PropTypes.func.isRequired,
    isFreebetUsed: PropTypes.bool,
    setIsPlaceBetDisabled: PropTypes.func.isRequired,
  };

  static defaultProps = {
    stakeId: null,
    betAmount: null,
    error: null,
    isFreebetUsed: false,
  };

  state = {
    value: this.props.betAmount || '',
    isKeyboardShowed: false,
  };

  onChangeSubject;

  onChangeSubsription;

  inputRef = createRef();

  componentDidMount() {
    this.onChangeSubject = new Subject();
    this.onChangeSubsription = this.onChangeSubject
      .pipe(debounceTime(100))
      .subscribe(this.onChange);

    document.addEventListener('click', this.checkOutsideClick);
  }

  componentDidUpdate(prevProps) {
    const { betAmount, betSlipState } = this.props;
    const { isKeyboardShowed } = this.state;
    const { betAmount: prevBetAmount, betSlipState: prevBetSlipState } = prevProps;

    if (betAmount !== prevBetAmount) {
      const value = betAmount === null || betAmount === 0 ? '' : String(betAmount);
      this.setKeyboardValueWithoutStoreUpdate(value);
    }

    if (isKeyboardShowed) {
      if (prevBetSlipState !== betSlipState && betSlipState !== BET_SLIP_STATE.DEFAULT) {
        this.setIsKeyboardShowed(false);
      }
    }
  }

  componentWillUnmount() {
    const { setIsPlaceBetDisabled } = this.props;
    setIsPlaceBetDisabled(false);

    if (this.onChangeSubsription) {
      this.onChangeSubsription.unsubscribe();
    }

    document.removeEventListener('click', this.checkOutsideClick);
  }

  onClick = () => {
    const { isFreebetUsed } = this.props;

    if (!isFreebetUsed) {
      this.setIsKeyboardShowed(true);

      // Timer is needed, for scrolling after the keyboard visibility
      setTimeout(() => scrollToInput(this.inputRef.current), 0);
    }
  };

  checkOutsideClick = (e) => {
    const { stakeId } = this.props;
    const path = e.path || (e.composedPath && e.composedPath());
    const inputClass = `bet-amount-input-mobile${stakeId ? `-${stakeId}` : ''}`;
    const keyboardClass = `bet-slip-keyboard${stakeId ? `-${stakeId}` : ''}`;
    const domTokens = R.compose(
      R.reject(DOMTokenList => !DOMTokenList),
      R.pluck('classList')
    )(path);
    const isClickOnCurrentInputOrKeyboard = R.compose(
      R.contains(true),
      R.map(DOMTokenList => DOMTokenList.contains(inputClass) || DOMTokenList.contains(keyboardClass)),
    )(domTokens);

    if (!isClickOnCurrentInputOrKeyboard) {
      const isClickOnAnyInputOrKeyboard = R.compose(
        R.contains(true),
        R.map(DOMTokenList => DOMTokenList.contains('bet-amount-input-mobile') || DOMTokenList.contains('bet-slip-keyboard')),
      )(domTokens);

      this.setIsKeyboardShowed(false, !isClickOnAnyInputOrKeyboard);
    }
  };

  onChange = (value) => {
    const { setBetAmount, stakeId, setIsPlaceBetDisabled } = this.props;
    setBetAmount({ stakeId, betAmount: Number(value) });
    scrollToInput(this.inputRef.current);
    setIsPlaceBetDisabled(false);
  };

  setValue = (value) => {
    const { isPlaceBetInProgress, setIsPlaceBetDisabled } = this.props;

    if (isInvalidStake(value) || isPlaceBetInProgress) {
      return;
    }

    setIsPlaceBetDisabled(true);
    this.setState({ value });
    this.onChangeSubject.next(value);
  };

  setIsKeyboardShowed = (isKeyboardShowed, withParentUpdate = true) => {
    const { setIsKeyboardShowed } = this.props;
    this.setState({ isKeyboardShowed });

    if (withParentUpdate) {
      setIsKeyboardShowed(isKeyboardShowed);
    }
  };

  setKeyboardValueWithoutStoreUpdate = value => this.setState({ value });

  render() {
    const {
      stakeId,
      currency,
      betSlipState,
      error,
      isFreebetUsed,
    } = this.props;
    const { value, isKeyboardShowed } = this.state;
    const isErrorState = betSlipState === BET_SLIP_STATE.ERROR;

    return (
      <>
        <div
          ref={this.inputRef}
          role="button"
          tabIndex="0"
          onClick={this.onClick}
          onKeyPress={this.onClick}
          className={classNames('bet-amount-input-mobile position-relative', {
            [`bet-amount-input-mobile-${stakeId}`]: stakeId,
            'is-freebet': isFreebetUsed,
          })}
        >
          <div className={classNames('pl-6 text-right font-weight-bold w-100 form-control overflow-hidden', {
            'text-extra-3': !value,
            'is-focused': isKeyboardShowed,
            'text-warning': isErrorState && error && error.limit,
          })}
          >
            {value || 0}
          </div>
          <div className="bet-amount-input-mobile-currency position-absolute caption text-extra-2">
            {prepareCurrency(currency)}
          </div>
          {isKeyboardShowed && <div className="bet-amount-input-mobile-cursor position-absolute" />}
        </div>
        <Portal root="ticket-mobile">
          <BetSlipKeyboard
            stakeId={stakeId}
            currency={currency}
            isKeyboardShowed={isKeyboardShowed}
            setIsKeyboardShowed={this.setIsKeyboardShowed}
            keyboardValue={value}
            setKeyboardValue={this.setValue}
          />
        </Portal>
      </>
    );
  }
}
