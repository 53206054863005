import React from 'react';
import PropTypes from 'prop-types';

export const IconWta = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.59798 0.0302839C6.32121 0.107379 6.12121 0.263142 6.0404 0.467679C6.00202 0.563655 6 0.609282 6 1.16153C6.00202 1.78301 6.02626 2.30223 6.07071 2.72074C6.09495 2.94731 6.10303 3.01653 6.14141 3.31075C6.38788 5.17204 7.05859 7.32756 7.84848 8.78607C7.89495 8.8726 7.93939 8.95599 7.94545 8.97015C7.95758 8.99532 8 9.01106 8 8.9906C8 8.98588 7.98182 8.86002 7.9596 8.71055C7.67677 6.76272 7.51919 4.26107 7.57374 2.61218C7.59798 1.90416 7.61818 1.61938 7.67879 1.05297C7.72929 0.591975 7.72323 0.518027 7.62424 0.363837C7.53535 0.226954 7.39798 0.123113 7.21414 0.0570307C7.03838 -0.00747681 6.77576 -0.0184908 6.59798 0.0302839Z" fill="white" fillOpacity="0.4" />
    <path d="M15.9733 0.0158997C15.8007 0.0470581 15.6718 0.104698 15.5428 0.212189C15.3325 0.385107 15.277 0.551796 15.3246 0.869595C15.3405 0.972412 15.3682 1.2388 15.3881 1.46313C15.4317 1.96943 15.4377 3.40887 15.398 3.85597C15.3008 4.96671 15.1698 5.74095 14.9139 6.72239C14.8584 6.9327 14.8108 7.11808 14.8068 7.13521C14.7989 7.17572 14.783 7.2318 14.7711 7.26451C14.7651 7.27853 14.7572 7.30813 14.7512 7.32994C14.7453 7.35175 14.7354 7.38603 14.7294 7.40784C14.7235 7.42964 14.7136 7.46392 14.7076 7.48573C14.7017 7.50754 14.6878 7.55271 14.6759 7.58699C14.664 7.62126 14.6501 7.66332 14.6461 7.68046C14.6421 7.69759 14.6263 7.73654 14.6124 7.76614C14.5965 7.79729 14.5787 7.8487 14.5727 7.88298C14.5648 7.91725 14.541 7.99358 14.5172 8.05434C14.4933 8.11353 14.4715 8.17429 14.4695 8.18831C14.4537 8.24751 14.2493 8.76627 14.1144 9.08251C13.3864 10.7915 12.3448 12.4615 11.0593 13.9819C10.1943 15.0038 9.3849 15.8139 8.33743 16.6972C8.14499 16.8577 7.99422 16.9932 8.00017 16.9994C8.02199 17.015 8.51597 16.705 8.99607 16.3747C9.2877 16.1738 10.0793 15.5647 10.3649 15.3216C10.4959 15.211 10.9026 14.8512 11.0593 14.7063C11.2299 14.5505 11.6584 14.1252 11.9004 13.8729C12.5809 13.164 13.2276 12.3773 13.7811 11.5828C13.92 11.3866 14.164 11.0236 14.164 11.0158C14.164 11.0111 14.2473 10.8756 14.3485 10.7151C14.4477 10.5531 14.5509 10.3864 14.5747 10.3428C14.5985 10.3007 14.6461 10.2197 14.6778 10.1621C14.8187 9.91595 15.025 9.51715 15.1857 9.17598C16.1419 7.15235 16.7331 4.89505 16.9315 2.50065C16.995 1.73419 17.0208 0.701349 16.9811 0.537775C16.8918 0.171684 16.4336 -0.0651073 15.9733 0.0158997Z" fill="white" fillOpacity="0.4" />
    <path d="M13.8767 0.0393648C13.8767 0.0951319 13.7575 1.59592 13.7308 1.86984C13.5274 4.02671 12.7609 6.29675 11.5075 8.46346C11.1705 9.04902 10.7041 9.77071 10.3712 10.23C10.3054 10.3185 10.2438 10.4055 10.2315 10.4235C10.2171 10.4416 10.1493 10.5334 10.0794 10.6285C9.31708 11.6438 8.40064 12.6952 7.45543 13.6383C7.26638 13.8269 7.09172 14.0156 7.07118 14.0566C6.85131 14.4601 7.16159 14.9095 7.71639 14.9915C7.93831 15.0243 8.21571 14.9603 8.39242 14.834C8.49105 14.7619 8.87941 14.3502 9.25955 13.9139C10.3774 12.6279 11.3308 11.2223 12.0787 9.75431C13.0363 7.86971 13.6568 5.83257 13.8973 3.77247C13.9979 2.90809 14.0288 1.66809 13.9712 0.754495C13.9322 0.131216 13.9199 0 13.8952 0C13.8849 0 13.8767 0.0180416 13.8767 0.0393648Z" fill="white" fillOpacity="0.4" />
    <path d="M4.18245 0.0288591C4.1419 0.0560198 4.13337 0.0916681 4.10563 0.314049C4.06082 0.677327 4.03308 0.996469 4.016 1.35805C3.99467 1.75528 3.99467 3.07598 4.016 3.44605C4.03521 3.79066 4.08856 4.38141 4.1099 4.47308C4.11843 4.51042 4.1227 4.54777 4.12057 4.55456C4.1099 4.58512 4.25714 5.5731 4.31902 5.89394C4.40011 6.31833 4.42145 6.41679 4.44919 6.53561C4.46626 6.60522 4.48973 6.70537 4.5004 6.7563C4.7522 7.8699 5.17471 9.16514 5.62283 10.2057C5.89811 10.8423 6.26514 11.5791 6.3697 11.6979C6.57669 11.9356 6.99707 12.0561 7.34062 11.9746C7.7482 11.8778 8 11.6164 8 11.2888C8 11.1411 7.95519 11.0274 7.82929 10.8491C7.31715 10.1277 6.73673 9.14816 6.30782 8.29089C5.52894 6.73253 4.94852 5.07911 4.6199 3.48C4.54521 3.12182 4.45132 2.56162 4.42571 2.34264C4.41931 2.28152 4.41078 2.20853 4.40651 2.18137C4.39797 2.12874 4.3745 1.93692 4.3617 1.79093C4.31475 1.31052 4.31048 1.26299 4.29768 0.950635C4.28914 0.796157 4.28061 0.609426 4.27634 0.534732C4.27207 0.460039 4.2678 0.308956 4.2678 0.198615C4.26567 0.0899706 4.25714 0 4.24647 0C4.2358 0 4.20806 0.0135803 4.18245 0.0288591Z" fill="white" fillOpacity="0.4" />
    <path d="M11.3258 16.5381C11.3258 16.6438 10.9777 16.9626 10.6333 17.1758C9.92987 17.6106 9.11713 17.9618 8.32261 18.1784C7.97638 18.2721 7.64107 18.3438 7.28573 18.3983C6.91034 18.4563 6.90669 18.4699 7.26386 18.4887C7.58276 18.504 8.2315 18.504 8.52306 18.487C9.70756 18.4222 10.8192 18.0948 11.7139 17.5509C11.9308 17.4179 12 17.3531 12 17.2815C12 17.2303 11.9909 17.2201 11.6629 16.8467C11.5554 16.7239 11.4424 16.596 11.4114 16.5602C11.3476 16.4869 11.3258 16.4818 11.3258 16.5381Z" fill="white" fillOpacity="0.4" />
    <path d="M12.9365 18.0167C12.9365 18.026 12.9465 18.0427 12.96 18.0538C12.9935 18.0835 13.0052 18.1707 12.9851 18.2468C12.96 18.3489 12.8761 18.4528 12.6849 18.618C12.6161 18.6756 12.3243 18.8463 12.2069 18.8964C11.8614 19.043 11.3163 19.2286 11.1 19.2732C10.2614 19.4458 9.6643 19.5237 8.88442 19.5608C8.43325 19.5812 7.64666 19.572 7.24246 19.5404C7.06132 19.5274 6.96908 19.5367 7.00933 19.5645C7.03449 19.5812 7.35986 19.6648 7.67349 19.7334C8.08776 19.8244 8.59594 19.906 9.02865 19.9487C9.14438 19.9598 9.31042 19.9766 9.39763 19.984C9.6576 20.01 11.1318 20.0025 11.4186 19.9747C12.1432 19.9042 12.8677 19.7631 13.4514 19.5775C13.8673 19.4458 13.9227 19.4161 13.978 19.2973C14.0334 19.1748 14.0233 19.1581 13.4698 18.5549C13.1897 18.2505 12.9566 18 12.9499 18C12.9415 18 12.9365 18.0074 12.9365 18.0167Z" fill="white" fillOpacity="0.4" />
  </svg>
);

IconWta.propTypes = {
  className: PropTypes.string,
};

IconWta.defaultProps = {
  className: '',
};
