import * as R from 'ramda';
import { BET_SLIP_STATE } from 'core/constants';

export const getIsHighlighted = (betSlipState, stakes, id) => betSlipState !== BET_SLIP_STATE.SUCCESS
&& !!R.find(({ stakeId }) => stakeId === id, stakes);

export const prepareStakeTypeName = (periodName, stakeTypeName) =>
  periodName ? `${periodName}. ${stakeTypeName}` : stakeTypeName;

export const prepareHcp = (outcomeId, argument) => {
  let hcpValue;

  if (outcomeId === '1714') {
    hcpValue = argument > 0 ? `+${argument}` : argument;
  } else if (outcomeId === '1715') {
    if (argument === '0') {
      hcpValue = '0';
    } else {
      const invertedHcp = argument * -1;
      hcpValue = invertedHcp > 0 ? `+${invertedHcp}` : invertedHcp;
    }
  }

  return hcpValue;
};

export const prepareMarketName = ({
  marketInfo,
  teamA,
  teamB,
  specifier,
  marketId,
}) => {
  if (!marketInfo) {
    return marketId;
  }

  let result = marketInfo.name;
  specifier.split('|').map(specifierItem => specifierItem.split('=')).forEach(([key, value]) => {
    if (key && value) {
      const replace = `{(\\W?)${key}}`;
      const regexp = new RegExp(replace, 'g');
      result = result.replaceAll(regexp, value);
    }
  });

  return result
    .replaceAll('{$competitor1}', teamA)
    .replaceAll('{$competitor2}', teamB);
};

export const prepareOutcomeName = ({
  teamA,
  teamB,
  specifier,
  outcomeId,
  marketInfo,
}) => {
  if (!marketInfo) {
    return outcomeId;
  }

  let outcomeName = outcomeId;
  const isTotal = !marketInfo.name.includes('{total}') && !!marketInfo.specifiers
  && marketInfo.specifiers.includes('total') && !!marketInfo.variants
  && !!marketInfo.variants[''][0].outcomes.find(({ id }) => id === '12' || id === '13');
  const isHandicap = !marketInfo.name.includes('{hcp}') && !!marketInfo.specifiers
  && marketInfo.specifiers.includes('hcp') && !!marketInfo.variants
  && !!marketInfo.variants[''][0].outcomes.find(({ id }) => id === '1714' || id === '1715');

  if (isTotal || isHandicap) {
    const { outcomes } = marketInfo.variants[''][0];
    const { name } = R.find(R.propEq('id', outcomeId), outcomes) || {};

    if (name) {
      outcomeName = name;
    }
  } else {
    const variant = marketInfo.variants[specifier] || marketInfo.variants[''];
    const foundVariant = R.find(({ outcomes = [] } = {}) => R.find(R.propEq('id', outcomeId), outcomes), variant || []);

    if (foundVariant) {
      const { name } = R.find(R.propEq('id', outcomeId), foundVariant.outcomes) || {};

      if (name) {
        outcomeName = name;
      }
    }
  }

  const specifiers = {};
  specifier.split('|').forEach((specifierItem) => {
    const [specifierKey, specifierValue] = specifierItem.split('=');
    specifiers[specifierKey] = specifierValue;
  });
  const hcpValue = prepareHcp(outcomeId, specifiers.hcp);

  return outcomeName
    .replaceAll('{$competitor1}', teamA)
    .replaceAll('{$competitor2}', teamB)
    .replaceAll('{total}', specifiers.total || '')
    .replaceAll('({+hcp})', hcpValue || '')
    .replaceAll('({-hcp})', hcpValue || '')
    .replaceAll('({hcp})', specifiers.hcp || '');
};
