import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportStarcraft = ({
  className, isActive, width, height,
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.72033 0.0461009C5.63898 0.0664399 5.34915 0.137626 5.08474 0.193558C3.28474 0.605423 2.47119 0.813897 2.39491 0.885084C2.3339 0.946101 2.34407 0.96644 2.47119 1.01729C2.81695 1.15966 3.46271 1.46983 3.5339 1.53085C3.5339 1.53085 4.96271 2.5525 5.08474 2.99995C5.08474 2.99995 5.03389 2.99995 6.45762 2.99995L7.99999 3.00005V2.03932V0.778304L7.0983 0.386779C6.44745 0.173219 5.90338 -0.00474668 5.89321 0.000338078C5.88304 0.000338078 5.80677 0.0206771 5.72033 0.0461009Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M11.6187 0.22924C11.2576 0.351274 10.7237 0.524156 10.439 0.615681L10 0.796926V2.49195C10 3.50005 9.99155 4.00005 9.99155 4.00005C9.99155 4.00005 10.1339 4.04788 10.2458 3.9106C10.3576 3.77839 10.5763 3.59026 10.7288 3.49873L11.0034 3.32585C11.0034 3.32585 11.339 3.01025 12.3153 3.00008C13.2915 2.98991 14 3.00008 14 3.00008C14.0153 2.85771 13.9831 2.28348 14.1102 2.13602C14.4966 1.68856 14.8373 1.42924 15.4271 1.15466C15.8746 0.94619 16.1119 0.832529 16 0.796936C15.7712 0.720665 12.3051 -0.0199127 12.2848 0.000426292C12.2797 0.00551081 11.9848 0.107207 11.6187 0.22924Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M2.11475 4.00977C1.63461 4.08624 1.16281 4.26869 1.04778 4.60186C0.977763 4.79302 0.987763 4.9236 1.05778 5.00006C1.05778 5.00006 1.808 5.00006 3.92359 5.00006H6.50562L7.02446 5.6669L7.31454 6.00006L8.29982 4.94048C8.83997 4.36154 9 4.00977 9 4.00977H5.79578C5.79578 4.00977 2.25979 3.98792 2.11475 4.00977Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M11.07 4.1478C10.74 4.3923 10 4.85745 10 5.00008C10 5.00008 10.52 5.00008 12.705 5.00008H15.415L16 6.00012C15.95 5.96447 16.14 6.11728 16 6.00012C16 6.00012 16.185 5.97647 17.055 5.09015C17.575 4.5604 18 4.00008 18 4.00008C18 4.00008 17.305 4.00008 14.635 4.00008H11.265L11.07 4.1478Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M7.65058 6.25073C7.13644 6.70895 6.90683 6.72657 6.48254 6.33444L6.22796 6.00398C5.92347 5.99517 5 6.00006 5 6.00006L8 9.00006C8 9.00006 8 8.46694 8 7.50203C7.99501 6.67811 8.01997 6.00006 8 6.00006C7.98003 6.00006 7.80033 6.11414 7.65058 6.25073Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M1.14885 6.00006C1.14885 6.00006 1.22479 6.11183 1.27438 6.14475C1.31901 6.18238 1.8843 6.77964 2.52893 7.48036C3.17355 8.17638 4.27438 9.3709 4.97355 10.1281C5.67273 10.8852 6.24793 11.0001 6.24793 11.0001H1.9438L0.971901 11.9669C0.436364 12.4748 0 12.9168 0 12.9498C0 12.9968 0.763636 13.0062 3.90744 12.9968L7.80992 12.9827L8.0876 12.8604C8.64793 12.6112 8.99504 12.1079 9 11.5483C9 11.2944 8.97025 11.1862 8.83636 10.9275C8.74711 10.7535 8.58843 10.509 8.4843 10.3867C8.3405 10.2127 6.71405 8.43033 5.31074 6.91602C5.24132 6.83607 5.03802 6.61504 4.8595 6.42222C4.68099 6.2294 4.45108 6.00006 4.45108 6.00006H1.14885Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M10.9579 6.00217H9.38981C9.27818 6.23511 9.17542 6.75351 9.07442 7.28119C9.03721 7.4761 9.00532 7.98951 9 8.42211C9 9.10191 9.01595 9.25879 9.12226 9.58205C9.38804 10.4045 9.87176 11.0795 10.7276 11.8116C11.7535 12.6958 12.6252 12.9953 14.1561 13.0001C15.0598 13.0001 15.5116 12.9192 16.1229 12.6483C16.4365 12.5057 17 12.1206 17 12.0445C17 12.0255 16.6704 12.0113 16.2664 12.0065C15.4744 12.0065 15.0013 11.9304 14.3953 11.7165C13.2631 11.3124 12.3595 10.395 12.0246 9.30157C11.913 8.92602 11.8704 7.9705 11.9449 7.51888C12.0352 6.9912 12.2322 6.38248 12.4023 6.00217C12.4023 6.00217 11.7553 5.99742 10.9579 6.00217Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M13.3871 6.38791C13.2018 6.93464 13.1277 7.23838 13.0576 7.71034C12.8806 8.95333 13.1112 9.95333 13.7537 10.7477C13.8649 10.8879 13.9679 11.0001 13.9843 11.0001C13.9967 11.0001 14.0049 9.87389 13.9967 8.50006L13.9884 6.00006H13.7496H13.5148L13.3871 6.38791Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M9.97627 15.2902L10 18C10 18 11.0728 17.7295 11.6949 17.4054C12.2451 17.1188 12.7254 16.7847 13 16.5C13.3102 16.1847 13.6644 15.8749 14 15.4631V14.7003V14C11.5 14 10.8441 13.8034 10 13L9.97627 15.2902Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M8 14.0001C7.85938 14.0001 7.10339 14.0001 6.24407 14.0001H5V14.558V15.4427C5.62034 16.2665 6.01356 16.8645 7 17.5001C7.48813 17.8153 7.92373 18.0001 8 18.0001C8.03051 18.0001 8 17.6444 8 16.0936C8.00508 14.7919 8 14.0001 8 14.0001Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
  </svg>
);

IconSportStarcraft.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportStarcraft.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
