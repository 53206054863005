import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Collapse from 'reactstrap/lib/Collapse';
import { isEmptyOrNil } from 'core/helpers';
import { AI_OUTCOME_SECTION } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { IconFreebet } from 'components/icons/icon-freebet/icon-freebet';
import { IconSuccess } from 'components/icons/icon-success/icon-success';
import { IconArrowBottom } from 'components/icons/icon-arrow-bottom/icon-arrow-bottom';
import { SportTypeIcon } from 'components/sport-type-icon/sport-type-icon';
import { getTopLeagueIcon } from 'helpers/top-leagues';
import { prepareCurrency } from 'helpers/currency';
import { CountDown } from './countdown';

export class FreebetsItem extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    freebet: PropTypes.shape().isRequired,
    currency: PropTypes.string.isRequired,
    activateBonus: PropTypes.func.isRequired,
    sendSportClickFeedback: PropTypes.func.isRequired,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
  };

  state = {
    isLearnMoreOpen: false,
  };

  onActivateClick = () => {
    const { activateBonus, freebet: { id, status } } = this.props;

    if (status === 'new') {
      activateBonus(id);
    }
  };

  toggleLearnMore = () => this.setState(prevState => ({ isLearnMoreOpen: !prevState.isLearnMoreOpen }));

  onSportClick = (e) => {
    const { dataset: { sportId } } = e.currentTarget;
    const { sendSportClickFeedback } = this.props;
    sendSportClickFeedback(sportId);
  };

  onLeagueClick = (e) => {
    const { dataset: { leagueId } } = e.currentTarget;
    const { sendLeagueClickFeedback } = this.props;
    sendLeagueClickFeedback(leagueId);
  };

  onEventClick = (e) => {
    const { dataset: { eventId } } = e.currentTarget;
    const { sendEventClickFeedback } = this.props;
    sendEventClickFeedback(eventId, false);
  };

  render() {
    const { locale, freebet, currency } = this.props;
    const { isLearnMoreOpen } = this.state;
    const {
      freebet_data: {
        amount,
        max_odd: maxOdd,
        min_odd: minOdd,
      },
      to_time: toTime,
      ending_date: endingDate,
      restrictions: {
        restriction_events: restrictionEvents,
      },
      status,
      isActivateBonusInProgress,
    } = freebet;
    const isActivated = status === 'active';
    const timestamp = (isActivated ? endingDate : toTime) * 1000;

    return (
      <div className="freebets-item-wrapper p-2 p-sm-0 p-lg-1_5">
        <div className="freebets-item mb-sm-1 mb-lg-0 p-2 px-lg-2_5 rounded">
          <div className="d-flex justify-content-between mb-2_5">
            <div className="d-flex align-items-center flex-wrap pr-1">
              <IconFreebet className="freebets-item-icon flex-shrink-0" />
              <FormattedTag id="freebet.title" tag="div" className="font-weight-bold mx-1" />
              <CountDown date={timestamp} />
            </div>

            <div className="font-weight-bold flex-shrink-0">
              {amount} {prepareCurrency(currency)}
            </div>
          </div>
          <ButtonWithLoader
            size="sm"
            color="primary"
            block
            className={classNames('freebets-item-btn mb-2', { activated: isActivated })}
            onClick={this.onActivateClick}
            isLoading={isActivateBonusInProgress}
          >
            {isActivated && <IconSuccess className="flex-shrink-0 mr-1" />}
            <FormattedMessage id={`${isActivated ? 'freebet.activated' : 'freebet.activate'}`} />
          </ButtonWithLoader>

          <div
            role="button"
            tabIndex="0"
            onClick={this.toggleLearnMore}
            onKeyPress={this.toggleLearnMore}
            className="d-flex align-items-center justify-content-center"
          >
            <FormattedTag id="freebet.learn-more" className="text-small text-extra-2 mr-0_5" />
            <IconArrowBottom className={classNames('icon-arrow-bottom', { 'is-open': isLearnMoreOpen })} color="white" opacity={0.65} backgroundColor="transparent" />
          </div>

          <Collapse isOpen={isLearnMoreOpen}>
            <div className="pt-2 pb-0_5">
              {!isEmptyOrNil(restrictionEvents) && restrictionEvents.map(({ id, type, desc }, idx) => {
                let linkUrl;
                let linkName;
                let onClick;
                const dataAttrs = {};
                const isSport = type === 'sport';
                const LeagueTypeIcon = !isSport && getTopLeagueIcon(desc.tournament.id);

                if (isSport) {
                  linkUrl = `/sport/${desc.sport.id}`;
                  linkName = desc.sport.name;
                  onClick = this.onSportClick;
                  dataAttrs['data-sport-id'] = desc.sport.id;
                } else if (type === 'tournament') {
                  linkUrl = `/league/${desc.sport.id}/${desc.tournament.id}`;
                  linkName = desc.tournament.name;
                  onClick = this.onLeagueClick;
                  dataAttrs['data-league-id'] = desc.tournament.id;
                } else if (type === 'event') {
                  linkUrl = `/event/${desc.event.id}?isLive=false&sectionBeforeEventPage=${AI_OUTCOME_SECTION.FREEBETS}`;
                  linkName = desc.event.name;
                  onClick = this.onEventClick;
                  dataAttrs['data-event-id'] = desc.event.id;
                }

                return (
                  <RouteLink
                    key={id}
                    locale={locale}
                    to={linkUrl}
                    className={classNames('freebets-item-link d-flex align-items-center', { 'mb-1': idx !== restrictionEvents.length - 1, 'mb-2': idx === restrictionEvents.length - 1 })}
                    onClick={onClick}
                    {...dataAttrs}
                  >
                    {isSport
                      ? <SportTypeIcon sportId={desc.sport.id} />
                      : <LeagueTypeIcon />}

                    <div className="ml-2 text-small">{linkName}</div>
                  </RouteLink>
                );
              })}

              <FormattedTag
                id="freebet.learn-more.description"
                tag="div"
                isHtml
                values={{ amount: `${amount}${prepareCurrency(currency)}`, minOdd, maxOdd }}
                className="freebets-item-description caption"
              />
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}
