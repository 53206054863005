import React from 'react';
import PropTypes from 'prop-types';

export const IconChat = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 4.477 4.478 0 10 0C15.522 0 20 4.477 20 10C20 15.523 15.522 20 10 20C8.668 20 7.399 19.734 6.237 19.262L1.143 19.99C0.482 20.084 -0.084 19.518 0.011 18.857L0.738 13.762C0.266 12.6 0 11.332 0 10ZM7 8.99987C7.553 8.99987 8 9.44687 8 9.99987C8 10.5529 7.553 10.9999 7 10.9999C6.447 10.9999 6 10.5529 6 9.99987C6 9.44687 6.447 8.99987 7 8.99987ZM11 9.99987C11 9.44687 10.553 8.99987 10 8.99987C9.447 8.99987 9 9.44687 9 9.99987C9 10.5529 9.447 10.9999 10 10.9999C10.553 10.9999 11 10.5529 11 9.99987ZM13 8.99987C13.553 8.99987 14 9.44687 14 9.99987C14 10.5529 13.553 10.9999 13 10.9999C12.447 10.9999 12 10.5529 12 9.99987C12 9.44687 12.447 8.99987 13 8.99987Z" fill="white" fillOpacity="0.65" />
  </svg>
);

IconChat.propTypes = {
  className: PropTypes.string,
};

IconChat.defaultProps = {
  className: null,
};
