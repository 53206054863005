import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { GameOutcomeDropdown } from 'components/betting-table/game-outcome-dropdown/game-outcome-dropdown';
import { OddsCell } from 'components/betting-table/odds-cell/odds-cell';
import { prepareMarketName } from 'helpers/stake';

import './searched-games-outcome-group.scss';

export class SearchedGamesOutcomeGroup extends PureComponent {
  static propTypes = {
    marketInfo: PropTypes.shape().isRequired,
    marketOutcome: PropTypes.shape().isRequired,
    isTotal: PropTypes.bool.isRequired,
    isHandicap: PropTypes.bool.isRequired,
    specifier: PropTypes.string.isRequired,
    outcomes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    oddProps: PropTypes.shape().isRequired,
    gameOutcomeFiltersRef: PropTypes.node.isRequired,
    isLastMarket: PropTypes.bool.isRequired,
    isFirstMarket: PropTypes.bool.isRequired,
  };

  render() {
    const {
      marketInfo,
      isTotal,
      isHandicap,
      outcomes,
      oddProps,
      gameOutcomeFiltersRef,
      isLastMarket,
      marketOutcome,
      specifier,
      isFirstMarket,
    } = this.props;
    const { id: marketId, variants } = marketInfo;
    const { eventId, teamA, teamB } = oddProps;
    const marketName = prepareMarketName({
      marketInfo, teamA, teamB, specifier, marketId,
    });
    const isOutcomesDropdown = (isHandicap || isTotal) && Object.keys(outcomes).length > 1;
    const is3way = Object.values(marketOutcome).length > 2;

    return (
      <div className={classNames('searched-games-outcome-group d-flex flex-column flex-shrink-0 position-relative', {
        'with-draw': is3way,
        'with-dropdown': isOutcomesDropdown,
        'mr-1': !isOutcomesDropdown && !isLastMarket
      })}
      >
        <div className="market-name overflow-hidden caption position-absolute text-center">
          <span className="text-nowrap text-extra-3">
            {marketName}
          </span>
        </div>
        <div className="d-flex align-items-center flex-shrink-0 mt-3">
          <div className="searched-games-outcome-group-odds-group">
            {Object.entries(marketOutcome).map(([outcomeId, outcomeValue]) =>
              outcomeValue ? (
                <OddsCell
                  key={`${eventId}-${marketId}-${specifier}-${outcomeId}`}
                  stake={outcomeValue}
                  outcomeId={outcomeId}
                  marketId={marketId}
                  isTotal={isTotal}
                  isHandicap={isHandicap}
                  specifier={specifier}
                  variants={variants}
                  className="searched-games-outcome-group-odds"
                  oddsType="secondary"
                  {...oddProps}
                />
              ) : (
                <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                  &#8212;
                </div>
              ))}
          </div>
          {isOutcomesDropdown && (
            <GameOutcomeDropdown
              gameOutcomeFiltersRef={gameOutcomeFiltersRef}
              className="ml-0_25 mr-0_5"
              classNameMenu={classNames('searched-games-outcome-group-dropdown-menu', { 'is-first': isFirstMarket })}
              outcomes={outcomes}
              marketId={marketId}
              isTotal={isTotal}
              isHandicap={isHandicap}
              specifier={specifier}
              oddProps={oddProps}
            />
          )}
        </div>
      </div>
    );
  }
}
