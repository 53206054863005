import React from 'react';
import PropTypes from 'prop-types';

export const IconPartialPlaced = ({ className }) => (
  <svg className={className} width="38" height="20" viewBox="0 0 38 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M20.2227 16.287C21.3494 14.4587 21.9994 12.3052 21.9994 10C21.9994 7.69476 21.3494 5.54134 20.2227 3.71303C22.056 1.44808 24.8587 0 27.9996 0C33.5224 0 37.9996 4.47715 37.9996 10C37.9996 15.5228 33.5224 20 27.9996 20C24.8587 20 22.056 18.5519 20.2227 16.287ZM27.9997 8.81709L30.8168 6.00032L31.9997 7.18312L29.1826 9.9999L31.9997 12.8167L30.8168 13.9995L27.9997 11.1827L25.1826 13.9995L23.9997 12.8167L26.8168 9.9999L23.9997 7.18314L25.1827 6.00034L27.9997 8.81709Z" fill="#f44336" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10 0C4.47656 0 0 4.47656 0 10C0 15.5234 4.47656 20 10 20C15.5234 20 20 15.5234 20 10C20 4.47656 15.5234 0 10 0ZM8.82031 14.8477L5.12891 11.1602L6.53906 9.74609L8.78125 11.9805L14.5352 5.89844L15.9883 7.27344L8.82031 14.8477Z" fill="#04d992" />
  </svg>
);

IconPartialPlaced.propTypes = {
  className: PropTypes.string,
};

IconPartialPlaced.defaultProps = {
  className: null,
};
