import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Collapse from 'reactstrap/lib/Collapse';
import {
  prepareBetsForRepeat,
  divideValues,
  mathRound,
  convertStringifiedBoolean,
} from 'core/helpers';
import { BET_STATUS, AI_OUTCOME_SECTION } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { BetScore } from 'components/bet-score/bet-score';
import { IconArrowBottom } from 'components/icons/icon-arrow-bottom/icon-arrow-bottom';
import { IconBonus } from 'components/icons/icon-bonus/icon-bonus';
import { IconRepeat } from 'components/icons/icon-repeat/icon-repeat';
import {
  getBetStatusIcon,
  getMultipleBetStatusIcon,
  prepareOutrightName,
  getSystemIntlId,
  isBetWithRepeat,
} from 'helpers/bets';
import { formatAmountWithCurrencySymbol } from 'helpers/currency';
import { toShortFormat } from 'helpers/date';
import { GA } from 'helpers/ga';

export class BetItemMultiple extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    result_sum: PropTypes.string.isRequired,
    sum: PropTypes.string.isRequired,
    k: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    selections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    combinations: PropTypes.arrayOf(PropTypes.shape()),
    bonus: PropTypes.shape(),
    repeatBet: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    bonus: null,
    combinations: null,
  };

  state = {
    isOpen: false,
  };

  toggleIsOpen = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  onCashoutClick = (e) => {
    e.stopPropagation();
  };

  getBetDescription = () => {
    const { selections } = this.props;

    return selections.reduce((prev, cur, idx) => {
      const {
        market_name: marketName,
        outcome_name: outcomeName,
      } = cur;

      if (idx === 0) {
        return `${marketName} ${outcomeName}`;
      }

      return `${prev}, ${marketName} ${outcomeName}`;
    }, '');
  };

  onEventClick = (e) => {
    const { dataset: { eventId, isLive } } = e.currentTarget;
    const { sendEventClickFeedback } = this.props;
    e.stopPropagation();
    GA.event({
      category: 'go-to-event-page',
      label: 'my-bets-event-click',
    });
    sendEventClickFeedback(eventId, convertStringifiedBoolean(isLive));
  };

  repeatBet = (e) => {
    e.stopPropagation();
    const {
      repeatBet,
      sum,
      type,
      selections,
      combinations,
    } = this.props;
    repeatBet({
      sum: combinations ? divideValues([sum, combinations.length]) : sum,
      type,
      selections: prepareBetsForRepeat(selections, sum),
    });

    GA.event({
      category: 'bet-slip',
      label: 'repeat-last-bet-click',
    });
  };

  render() {
    const {
      locale,
      currency,
      result_sum: resultSum,
      sum,
      k,
      status,
      selections,
      type,
      bonus,
    } = this.props;
    const { isOpen } = this.state;
    const bonusValue = bonus && bonus.total_multiplier;
    const [systemIndex, stakesAmount] = type.split('/');
    const isExpress = systemIndex === stakesAmount;
    const betDescription = this.getBetDescription();
    const StatusIcon = getMultipleBetStatusIcon(status);
    const intlId = `bet-slip.type.${isExpress ? 'express' : getSystemIntlId(type)}`;
    const isWon = status === BET_STATUS.WON;
    const isCashouted = status === BET_STATUS.CASHED_OUT;

    return (
      <div
        role="button"
        tabIndex="0"
        onClick={this.toggleIsOpen}
        onKeyPress={this.toggleIsOpen}
        className={classNames('bet-item-multiple py-2 bg-main-4', {
          'is-open': isOpen,
        })}
      >
        <div className="d-flex justify-content-between px-2">
          <div className="mb-0_25">
            <IconArrowBottom className="icon-arrow-bottom" backgroundColor="transparent" />
            <FormattedTag
              id={intlId}
              values={isExpress ? {} : { systemIndex, stakesAmount }}
              className="font-weight-bold ml-1"
            />
          </div>

          {isExpress && !!k && <span className="font-weight-bold">{mathRound(k)}</span>}
        </div>

        <div title={betDescription} className="d-flex justify-content-between mb-1 px-2">
          <span title={betDescription} className="bet-item-multiple-desc caption text-extra-2">{betDescription}</span>

          {isBetWithRepeat(selections) && (
            <div
              role="button"
              tabIndex="0"
              onClick={this.repeatBet}
              onKeyPress={this.repeatBet}
              className="d-flex align-items-center align-self-end flex-shrink-0 ml-1"
            >
              <IconRepeat className="flex-shrink-0 mr-1" />
              <FormattedTag id="bet-slip.repeat-bet" className="caption font-weight-bold text-extra-2" />
            </div>
          )}
        </div>

        <Collapse isOpen={isOpen} className="px-2">
          {selections.map((selection, idx) => {
            const {
              id: selectionId,
              desc: {
                scheduled,
                competitors,
                type: eventType,
                stage,
                season,
                tournament,
                sport: {
                  id: sportId,
                },
              },
              market_name: marketName,
              outcome_name: outcomeName,
              status: selectionStatus,
              k: selectionK,
              final_score: finalScore,
              event_id: eventId,
              eventInfo,
            } = selection;
            const eventDateTimeStamp = scheduled * 1000;
            const StakeStatusIcon = getBetStatusIcon(selectionStatus);
            const isOutright = eventType !== 'match';
            const teamA = !isOutright && competitors[0].name;
            const teamB = !isOutright && competitors[1].name;
            const outrightName = isOutright && prepareOutrightName(eventType, stage, season, tournament);
            const withEventLink = eventInfo && !isOutright;
            const LinkComponent = withEventLink ? RouteLink : 'span';
            const linkProps = withEventLink
              ? {
                locale,
                to: `/event/${eventId}?isLive=${eventInfo && eventInfo.isLive}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.MY_BETS}`,
                onClick: this.onEventClick,
                'data-event-id': eventId,
                'data-is-live': !!eventInfo && eventInfo.isLive,
              }
              : {};

            return (
              <div
                key={selectionId}
                className={classNames({
                  'mb-2_5': idx !== selections.length - 1 || bonusValue,
                  'mb-3': idx === selections.length - 1 && !bonusValue,
                })}
              >
                <div className="d-flex justify-content-between mb-0_5 text-small">
                  <div className="d-flex align-items-center overflow-hidden">
                    <StakeStatusIcon className="bet-status-icon mr-1 flex-shrink-0" />
                    <span title={outcomeName} className="font-weight-bold text-truncate">{outcomeName}</span>
                  </div>
                  {!!selectionK && <span className="text-extra-2">{mathRound(selectionK)}</span>}
                </div>

                <div title={marketName} className="text-extra-2 caption pl-2_5 mb-0_25">{marketName}</div>

                <div className={classNames('d-flex caption pl-2_5', { 'mb-1': !bonusValue })}>
                  <LinkComponent
                    {...linkProps}
                    title={isOutright ? outrightName : `${teamA} - ${teamB}`}
                    className={classNames('text-truncate', { 'bet-item-event-link': withEventLink, 'text-extra-2': !withEventLink })}
                  >
                    {isOutright ? outrightName : `${teamA} - ${teamB}`}
                  </LinkComponent>

                  <span className="mx-0_5 text-extra-3">&middot;</span>

                  <BetScore
                    score={eventInfo && eventInfo.score}
                    finalScore={finalScore}
                    sportId={sportId}
                    date={toShortFormat(eventDateTimeStamp, locale)}
                  />
                </div>
              </div>
            );
          })}
          {!!bonusValue && (
            <div className="d-flex align-items-center justify-content-between my-2_5 text-small">
              <div className="d-flex align-items-center ml-n0_5">
                <IconBonus />
                <FormattedTag id="general.bonus" className="font-weight-bold ml-1 mr-0_25" />
              </div>
              <span className="text-extra-2">
                {mathRound(bonusValue)}
              </span>
            </div>
          )}
        </Collapse>

        <div className="d-flex bet-item-info without-cashout px-2">
          <div className="bet-item-stake pr-0_25 mr-0_5">
            <FormattedTag id="bet-slip.stake" tag="div" className="caption text-extra-2" />
            {!!sum && <div className="text-small font-weight-bold text-break">{formatAmountWithCurrencySymbol(sum, currency)}</div>}
          </div>
          <div className="bet-item-return pl-0_25 ml-0_5">
            <FormattedTag id={isCashouted ? 'bets-history.cashed-out' : 'bet-slip.return'} tag="div" className="caption text-extra-2" />
            <div className="d-flex align-items-center">
              {!isCashouted && <StatusIcon className="bet-status-icon mr-1 flex-shrink-0" />}
              {resultSum && (
                <div className={classNames('bet-item-win-amount text-small font-weight-bold text-break', {
                  'text-success': isWon && !isCashouted,
                  'text-extra-3': !isWon,
                })}
                >
                  {formatAmountWithCurrencySymbol(resultSum, currency)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
