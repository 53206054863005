import React from 'react';
import PropTypes from 'prop-types';

export const IconLiveStatus = ({ className }) => (
  <svg className={className} width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.24219 8.99829H7.00781V8.2688H5.12695V4.77075H4.24219V8.99829ZM7.81055 8.99829H8.69531V4.77075H7.81055V8.99829ZM10.8281 8.99829H11.918L13.3271 4.77075H12.3311L11.3994 8.01392H11.3467L10.4121 4.77075H9.41895L10.8281 8.99829ZM14.0508 8.99829H16.8516V8.2688H14.9355V7.19946H16.7432V6.51978H14.9355V5.50024H16.8516V4.77075H14.0508V8.99829Z" fill="#04D992" />
    <rect x="0.5" y="1.49829" width="20" height="11" rx="1.5" stroke="#04D992" />
  </svg>
);

IconLiveStatus.propTypes = {
  className: PropTypes.string,
};

IconLiveStatus.defaultProps = {
  className: null,
};
