import * as R from 'ramda';

export const findSport = (sports, sportId) => R.find(R.propEq('sportId', sportId), sports);

export const findTopLeague = (topLeagues, leagueId) => R.find(R.propEq('leagueId', leagueId), topLeagues);

export const findCountryByLeague = (countries, leagueId) => R.find(
  ({ leagues }) => R.find(R.propEq('leagueId', leagueId), leagues),
  countries,
);

export const findCountry = (countries, countryId) => R.find(R.propEq('countryId', countryId), countries);
