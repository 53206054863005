import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import Collapse from 'reactstrap/lib/Collapse';
import { prepareBetsForRepeat, mathRound, convertStringifiedBoolean } from 'core/helpers';
import { BET_STATUS, AI_OUTCOME_SECTION } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { CashoutConfirm } from 'components/cashout-confirm/cashout-confirm';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { BetScore } from 'components/bet-score/bet-score';
import { IconArrowBottom } from 'components/icons/icon-arrow-bottom/icon-arrow-bottom';
import { IconLiveStatus } from 'components/icons/icon-live-status/icon-live-status';
import { IconRepeat } from 'components/icons/icon-repeat/icon-repeat';
import { toShortFormat } from 'helpers/date';
import { prepareCurrency, formatAmountWithCurrencySymbol } from 'helpers/currency';
import { getBetStatusIcon, prepareOutrightName, isBetWithRepeat } from 'helpers/bets';
import { GA } from 'helpers/ga';
import { BetStatus } from '../bet-status/bet-status';
import { MODAL_IDS } from '../../../../../../constants';

export class BetItemOrdinar extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    makeCashout: PropTypes.func,
    id: PropTypes.string.isRequired,
    potential_win: PropTypes.string,
    result_sum: PropTypes.string,
    sum: PropTypes.string.isRequired,
    total_k: PropTypes.string,
    k: PropTypes.string,
    timestamp: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    selections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    cashout_amount: PropTypes.string,
    isCashoutInProgress: PropTypes.bool,
    bonus: PropTypes.shape(),
    closeModal: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    repeatBet: PropTypes.func.isRequired,
    onCashoutClick: PropTypes.func.isRequired,
    closeCashout: PropTypes.func.isRequired,
    isCashoutOpen: PropTypes.bool.isRequired,
    cashoutX: PropTypes.number,
    cashoutY: PropTypes.number,
    isCashoutAvailable: PropTypes.bool.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    makeCashout: null,
    potential_win: null,
    result_sum: null,
    total_k: null,
    k: null,
    cashout_amount: null,
    isCashoutInProgress: false,
    bonus: null,
    cashoutX: null,
    cashoutY: null,
  };

  state = {
    isOpen: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  toggleIsOpen = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  onCashoutClick = (e) => {
    const { onCashoutClick, id } = this.props;
    onCashoutClick(e, id);
  };

  cancelCashout = (e) => {
    const { closeCashout } = this.props;
    e.stopPropagation();
    closeCashout();
    GA.event({
      category: 'bets-history',
      label: 'cancel-cashout',
    });
  };

  makeCashout = (e) => {
    e.stopPropagation();
    const {
      id,
      cashout_amount: cashoutAmount,
      makeCashout,
      closeCashout,
    } = this.props;

    closeCashout();
    makeCashout({ id, cashoutAmount });
    GA.event({
      category: 'bets-history',
      label: 'confirm-cashout',
    });
  };

  onEventClick = (e) => {
    const { dataset: { eventId, isLive } } = e.currentTarget;
    const { closeModal, sendEventClickFeedback } = this.props;
    e.stopPropagation();
    closeModal(MODAL_IDS.BET_HISTORY);
    GA.event({
      category: 'go-to-event-page',
      label: 'my-bets-event-click',
    });
    sendEventClickFeedback(eventId, convertStringifiedBoolean(isLive));
  };

  repeatBet = (e) => {
    e.stopPropagation();
    const {
      repeatBet,
      sum,
      type,
      selections,
      closeModal,
    } = this.props;
    repeatBet({
      sum,
      type,
      selections: prepareBetsForRepeat(selections, sum),
    });
    closeModal(MODAL_IDS.BET_HISTORY);
    GA.event({
      category: 'bet-slip',
      label: 'repeat-last-bet-click',
    });
  };

  render() {
    const {
      locale,
      currency,
      id,
      potential_win: potentialWin,
      result_sum: resultSum,
      sum,
      total_k: totalK,
      k,
      timestamp,
      status,
      selections,
      cashout_amount: cashoutAmount,
      isCashoutInProgress,
      bonus,
      isCashoutOpen,
      cashoutX,
      cashoutY,
      isCashoutAvailable,
    } = this.props;
    const { isOpen } = this.state;
    const {
      desc: {
        competitors,
        scheduled,
        type,
        stage,
        season,
        tournament,
        sport: {
          id: sportId,
        },
      },
      market_name: marketName,
      outcome_name: outcomeName,
      final_score: finalScore,
      live,
      event_id: eventId,
      eventInfo,
    } = selections[0];
    const eventDateTimeStamp = scheduled * 1000;
    const fillDate = timestamp * 1000;
    const isOutright = type !== 'match';
    const teamA = !isOutright && competitors[0].name;
    const teamB = !isOutright && competitors[1].name;
    const outrightName = isOutright && prepareOutrightName(type, stage, season, tournament);
    const StatusIcon = getBetStatusIcon(status);
    const isActive = status === BET_STATUS.OPEN;
    const isWon = status === BET_STATUS.WON;
    const isCashouted = status === BET_STATUS.CASHED_OUT;
    const betFactor = isActive ? totalK : k;
    const isFreebet = bonus && bonus.type === 'freebet';
    const withEventLink = eventInfo && !isOutright;
    const LinkComponent = withEventLink ? RouteLink : 'span';
    const linkProps = withEventLink
      ? {
        locale,
        to: `/event/${eventId}?isLive=${eventInfo && eventInfo.isLive}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.MY_BETS}`,
        onClick: this.onEventClick,
        'data-event-id': eventId,
        'data-is-live': !!eventInfo && eventInfo.isLive,
      }
      : {};

    return (
      <div
        role="button"
        tabIndex="0"
        onClick={this.toggleIsOpen}
        onKeyPress={this.toggleIsOpen}
        className={classNames('bets-table-item text-small', {
          'is-open': isOpen,
          'is-won': isWon && !isCashouted,
        })}
      >
        <div className="d-flex justify-content-between align-items-center py-1_5">
          <div className="bets-table-column align-self-start text-right">
            {live && <IconLiveStatus />}
          </div>
          <div className="bets-table-column pl-1_5">
            <div className="font-weight-bold mb-0_5 text-truncate">{outcomeName}</div>
            <div className="text-truncate">{marketName}</div>
          </div>
          <div className="bets-table-column pl-1_5">
            {toShortFormat(fillDate, locale)}
          </div>
          <div className="bets-table-column pl-1_5 text-break">
            {id}
          </div>
          {!!betFactor && (
            <div className="bets-table-column text-right px-1_5">
              {mathRound(betFactor)}
            </div>
          )}
          <div className="bets-table-column text-right pr-2_5">
            {!!sum && <span>{mathRound(sum)} {prepareCurrency(currency)}</span>}
          </div>
          <div className="bets-table-column pr-1_5 d-flex justify-content-end align-items-center">
            {isActive && !!cashoutAmount && isCashoutAvailable && (
              <ButtonWithLoader
                onClick={this.onCashoutClick}
                className={classNames('bets-table-cashout-btn px-1 py-0_25', { 'mr-3_5': !isActive || !eventInfo })}
                contentClassName="d-flex flex-column align-items-center justify-content-center"
                size="sm"
                color="primary"
                isLoading={isCashoutInProgress}
              >
                <FormattedTag id="my-bets.cashout" className="caption font-weight-normal" />
                <span className="text-small font-weight-bold">{formatAmountWithCurrencySymbol(cashoutAmount, currency)}</span>
              </ButtonWithLoader>
            )}

            {isFreebet && <FormattedTag id="freebet.title" className="bet-item-freebet bg-warning px-0_5 py-0_25 font-weight-bold" />}

            {isBetWithRepeat(selections) && (
              <div
                role="button"
                tabIndex="0"
                onClick={this.repeatBet}
                onKeyPress={this.repeatBet}
                className="ml-1_5 flex-shrink-0 align-self-center"
              >
                <IconRepeat className="icon-repeat" />
              </div>
            )}
          </div>
          <div className="bets-table-column d-flex justify-content-end align-items-center text-right pr-1_5">
            {isCashouted
              ? (
                <div className="d-flex flex-column justify-content-center align-items-end text-extra-2">
                  <FormattedTag id="bets-history.cashed-out" className="mb-0_5 font-weight-bold" />
                  {!!resultSum && <span className="caption">{mathRound(resultSum)} {prepareCurrency(currency)}</span>}
                </div>
              )
              : (
                <div className="d-flex align-items-center">
                  <StatusIcon className="bet-status-icon mr-1 flex-shrink-0" />
                  {(potentialWin || resultSum) && (
                    <span className={classNames('bets-table-win-amount font-weight-bold', {
                      'text-extra-3': !isActive && !isWon,
                    })}
                    >
                      {isActive
                        ? mathRound(potentialWin)
                        : mathRound(resultSum)} {prepareCurrency(currency)}
                    </span>
                  )}
                </div>

              )}
            <IconArrowBottom className="icon-arrow-bottom ml-3 flex-shrink-0" backgroundColor="transparent" />
          </div>
        </div>

        <Collapse isOpen={isOpen}>
          <div className="bets-table-collapse mx-1_5 py-2 d-flex text-small">
            <div className="bets-table-collapse-item pr-1_5 pl-5">
              <FormattedTag id="bets-history.bet" tag="div" className="caption text-extra-2 mb-0_5" />
              <div>{marketName} {outcomeName}</div>
            </div>

            <div className="bets-table-collapse-item pr-1_5">
              <FormattedTag id="bets-history.event" tag="div" className="caption text-extra-2 mb-0_5" />
              <div className="d-flex">
                <LinkComponent
                  {...linkProps}
                  title={isOutright ? outrightName : `${teamA} - ${teamB}`}
                  className={classNames('text-truncate', { 'bets-table-event-link': withEventLink, 'text-extra-2': !withEventLink })}
                >
                  {isOutright ? outrightName : `${teamA} - ${teamB}`}
                </LinkComponent>

                <span className="mx-0_5 text-extra-3">&middot;</span>

                <BetScore
                  score={eventInfo && eventInfo.score}
                  finalScore={finalScore}
                  sportId={sportId}
                  date={toShortFormat(eventDateTimeStamp, locale)}
                />
              </div>
            </div>

            <div className="bets-table-collapse-item pr-5 mr-0_5 text-right">
              <FormattedTag id="bets-history.status" tag="div" className="caption text-extra-2 mb-0_5" />
              <BetStatus status={status} />
            </div>
          </div>
        </Collapse>

        {isCashoutOpen && (
          <CashoutConfirm
            currency={currency}
            isPortal
            xPosition={cashoutX}
            yPosition={cashoutY}
            cashoutAmount={cashoutAmount}
            cancelCashout={this.cancelCashout}
            makeCashout={this.makeCashout}
          />
        )}
      </div>
    );
  }
}
