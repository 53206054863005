/* eslint-disable global-require */
module.exports = {
  IS_LIVE_STREAMS_ENABLED: true,
  SUPPORTED_CURRENCIES: [
    'EUR',
    'USD',
    'TRY',
  ],
  IS_BALANCE_FETCHING_ENABLED: false,
  CURRENCY_PRESETS: {
    EUR: ['5', '10', '20', '50'],
    USD: ['5', '10', '20', '50'],
    TRY: ['10', '25', '50', '100'],
  },
  BANNERS_MAPPING: {},
  DEFAULT_BET_AMOUNT: {
    EUR: '10',
    USD: '10',
    TRY: '25',
  },
  BETS_FEED_CURRENCIES: [
    'EUR',
    'USD',
    'TRY',
  ],
  IS_AI_ENABLED: true,
};
/* eslint-enable global-require */
