import React from 'react';
import PropTypes from 'prop-types';
import { isEmptyOrNil } from 'core/helpers';

import { prepareScore } from 'helpers/score';

export const BetScore = ({
  sportId,
  finalScore,
  score,
  date,
}) => {
  if (finalScore) {
    return <span className="text-nowrap font-weight-bold">{finalScore}</span>;
  }

  if (!isEmptyOrNil(score)) {
    return (
      <span className="text-success text-nowrap font-weight-bold">
        {prepareScore(sportId, score.home_score, score.home_dismissals)}
        {' - '}
        {prepareScore(sportId, score.away_score, score.away_dismissals)}
      </span>
    );
  }

  return <span className="text-extra-2 text-nowrap">{date}</span>;
};

BetScore.propTypes = {
  sportId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  finalScore: PropTypes.string,
  score: PropTypes.shape(),
};

BetScore.defaultProps = {
  score: null,
  finalScore: null,
};
