import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { withModalActions, withBetSlipActions, withFeedbackActions } from 'core/hocs';
import { isEmptyOrNil } from 'core/helpers';

import { ModalWindow } from 'components/modal-window/modal-window';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { IconFreebet } from 'components/icons/icon-freebet/icon-freebet';
import { IconFreebetLearnMore } from 'components/icons/icon-freebet-learn-more/icon-freebet-learn-more';
import { IconSuccess } from 'components/icons/icon-success/icon-success';
import { IconArrowRight } from 'components/icons/icon-arrow-right/icon-arrow-right';
import { prepareCurrency } from 'helpers/currency';
import { CountDown } from './countdown/countdown';
import { RestrictionEvents } from './restriction-events/restriction-events';
import { MODAL_IDS } from '../../../constants';

import './freebets-modal.scss';

class FreebetsModalUI extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    freebet: PropTypes.shape().isRequired,
    currency: PropTypes.string.isRequired,
    onClosed: PropTypes.func.isRequired,
    activateBonus: PropTypes.func.isRequired,
    sendSportClickFeedback: PropTypes.func.isRequired,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
  };

  state = {
    isLearnMoreOpen: false,
  };

  closeModal = () => {
    const { closeModal } = this.props;
    closeModal(MODAL_IDS.FREEBETS);
  };

  onActivateClick = () => {
    const { activateBonus, freebet: { id, status } } = this.props;

    if (status === 'new') {
      activateBonus(id);
    }
  };

  toggleLearnMore = () => this.setState(prevState => ({ isLearnMoreOpen: !prevState.isLearnMoreOpen }));

  render() {
    const {
      freebet,
      currency,
      onClosed,
      sendSportClickFeedback,
      sendLeagueClickFeedback,
      sendEventClickFeedback,
    } = this.props;
    const { isLearnMoreOpen } = this.state;
    const {
      freebet_data: {
        amount,
        max_odd: maxOdd,
        min_odd: minOdd,
      },
      to_time: toTime,
      ending_date: endingDate,
      restrictions: {
        restriction_events: restrictionEvents,
      },
      days_to_use: daysToUse,
      status,
      isActivateBonusInProgress,
    } = freebet;
    const isActivated = status === 'active';
    const timestamp = (isActivated ? endingDate : toTime) * 1000;

    return (
      <ModalWindow
        id={MODAL_IDS.FREEBETS}
        coreProps={{ id: MODAL_IDS.FREEBETS }}
        className="freebets-modal"
        bodyClassName="px-2_5 pb-3 pt-3_5 p-sm-5 rounded text-center"
        fade
        centered
        onClosed={onClosed}
        closeButtonClassName="freebets-modal-close-btn"
        isCloseButtonXs
      >
        {isLearnMoreOpen
          ? (
            <div className="mb-4 mb-sm-7 pt-4_5 pt-sm-6 pb-sm-0_5">
              <div
                role="button"
                tabIndex="0"
                onClick={this.toggleLearnMore}
                onKeyPress={this.toggleLearnMore}
              >
                <IconArrowRight className="icon-arrow-right position-absolute" />
              </div>
              <IconFreebetLearnMore className="mb-2_5 mb-sm-5" />
              <FormattedTag id="freebet.learn-more.title" tag="div" className="freebets-modal-learn-more-title font-weight-bolder mb-2 mb-sm-2_5" />
              <FormattedTag
                id="freebet.learn-more.description"
                tag="div"
                isHtml
                values={{ amount: `${amount}${prepareCurrency(currency)}`, minOdd, maxOdd }}
                className="freebets-modal-learn-more-description"
              />
            </div>
          )
          : (
            <>
              <div className="mb-2_5 pb-0_25 mb-sm-4 pb-sm-0">
                <FormattedTag id="freebet.title" tag="div" className="text-small font-weight-bold text-warning text-uppercase mb-0_5 mx-auto" />
                <FormattedTag id="freebet.description" tag="div" className="freebets-modal-subtitle mb-2 mx-auto" />
                <CountDown date={timestamp} />
              </div>
              <div className="bg-main-4 rounded pt-1_5 pb-2_5 py-sm-2 mb-4">
                <div className="d-flex align-items-baseline justify-content-center">
                  <IconFreebet className="flex-shrink-0 mr-2 align-self-center" />
                  <div className="freebets-modal-amount font-weight-bolder mr-0_5">{amount}</div>
                  <div className="freebets-modal-currency">{prepareCurrency(currency)}</div>
                </div>
                {!isEmptyOrNil(restrictionEvents) && (
                  <RestrictionEvents
                    events={restrictionEvents}
                    closeModal={this.closeModal}
                    sendSportClickFeedback={sendSportClickFeedback}
                    sendLeagueClickFeedback={sendLeagueClickFeedback}
                    sendEventClickFeedback={sendEventClickFeedback}
                  />
                )}
              </div>
            </>
          )}

        <ButtonWithLoader
          size="sm"
          color="primary"
          block
          className={classNames('freebets-modal-activate-btn rounded mb-2_5 text-uppercase', { activated: isActivated })}
          onClick={this.onActivateClick}
          isLoading={isActivateBonusInProgress}
        >
          {isActivated && <IconSuccess className="flex-shrink-0 mr-1" />}
          <FormattedMessage id={`${isActivated ? 'freebet.activated' : 'freebet.activate'}`} />
        </ButtonWithLoader>

        {isLearnMoreOpen
          ? <FormattedTag id="freebet.days-to-use" values={{ amount: daysToUse }} className="text-small font-weight-bold text-extra-3" />
          : (
            <FormattedTag
              id="freebet.learn-more"
              onClick={this.toggleLearnMore}
              className="text-small font-weight-bold text-extra-2 text-uppercase"
              role="button"
            />
          )}
      </ModalWindow>
    );
  }
}

export const FreebetsModal = withFeedbackActions(withBetSlipActions(withModalActions(FreebetsModalUI)));
