import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import {
  withLocale,
  withSearchedGames,
  withBetSlipActions,
  withBetSlip,
  withFeedbackActions,
} from 'core/hocs';
import { setDocumentScrollTopPosition, convertStringifiedBoolean } from 'core/helpers';
import { BET_SLIP_STORE_FIELDS, BET_SLIP_STATE } from 'core/constants';

import { Spinner } from 'components/spinner/spinner';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { Tabs, Tab } from 'components/tabs/tabs';
import { Portal } from 'components/portal/portal';
import { prepareSports, filterEventsBySport, filterLeaguesBySport } from 'helpers/searched-games';
import { SearchGroupItem } from './search-group-item/search-group-item';
import { SearchedLeagues } from '../searched-leagues/searched-leagues';

import './searched-games-result-desktop.scss';

class SearchedGamesResultDesktopUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    setSearchOpen: PropTypes.func.isRequired,
    isSearchOpen: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    searchValue: PropTypes.string.isRequired,
    searchedGames: PropTypes.PropTypes.shape(),
    isSearchedGamesInProgress: PropTypes.bool.isRequired,
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    toggleStake: PropTypes.func.isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    statuses: PropTypes.shape(),
    markets: PropTypes.shape(),
    sports: PropTypes.arrayOf(PropTypes.shape()),
    isLiveFrozen: PropTypes.bool.isRequired,
    isPrematchFrozen: PropTypes.bool.isRequired,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    searchedGames: null,
    statuses: null,
    markets: null,
    sports: null,
  };

  state = {
    isInProgress: false,
    activeTab: 0,
  };

  componentDidUpdate(prevProps) {
    const {
      location: { pathname: prevPathname },
      searchValue: prevSearchValue,
      isSearchedGamesInProgress: prevIsSearchedGamesInProgress,
    } = prevProps;
    const {
      location: { pathname }, setSearchOpen,
      searchValue,
      isSearchedGamesInProgress,
    } = this.props;

    if (pathname !== prevPathname) {
      setSearchOpen(false);
    }

    if (prevSearchValue !== searchValue && searchValue) {
      this.setIsInProgress(true);
      setDocumentScrollTopPosition(0, document.querySelector('.central-part'));
    }

    if (prevIsSearchedGamesInProgress && !isSearchedGamesInProgress) {
      this.setActiveTab(0);
      this.setIsInProgress(false);
    }
  }

  closeSearchResult = () => {
    const { setSearchOpen } = this.props;
    setSearchOpen(false);
  }

  setActiveTab = activeTab => this.setState({ activeTab });

  setIsInProgress = isInProgress => this.setState({ isInProgress });

  onLeagueClick = (e) => {
    const { dataset: { leagueId } } = e.currentTarget;
    const { sendLeagueClickFeedback } = this.props;
    sendLeagueClickFeedback(leagueId);
  };

  onEventClick = (e) => {
    const { dataset: { eventId, isLive } } = e.currentTarget;
    const { sendEventClickFeedback } = this.props;
    sendEventClickFeedback(eventId, convertStringifiedBoolean(isLive));
  };

  render() {
    const {
      locale,
      searchValue,
      searchedGames,
      isSearchedGamesInProgress,
      isSearchOpen,
      toggleStake,
      betSlipStakes,
      betSlipState,
      statuses,
      markets,
      sports,
      isLiveFrozen,
      isPrematchFrozen,
    } = this.props;
    const { isInProgress, activeTab } = this.state;
    let events = searchedGames ? searchedGames.events : [];
    let leagues = searchedGames ? searchedGames.leagues : [];
    const preparedSports = searchedGames && sports ? prepareSports(searchedGames, sports) : [];
    const tabs = [];

    if (preparedSports.length) {
      if (preparedSports.length > 1) {
        tabs.push(
          <Tab
            label="general.all"
            tabId={0}
            key={0}
          >
            {null}
          </Tab>
        );

        if (activeTab) {
          events = filterEventsBySport(events, activeTab);
          leagues = filterLeaguesBySport(leagues, activeTab);
        }
      }

      preparedSports.forEach(({ sportId, sportName }) => {
        tabs.push(
          <Tab
            label={sportName}
            tabId={sportId}
            key={sportId}
          >
            {null}
          </Tab>
        );
      });
    }

    return isSearchOpen && (
      <Portal>
        <div className="searched-games-result-desktop-wrapper d-none d-lg-block position-absolute p-1">
          <div
            role="button"
            tabIndex="0"
            onClick={this.closeSearchResult}
            onKeyPress={this.closeSearchResult}
            className="searched-games-result-desktop-backdrop position-absolute"
          />

          <div className="searched-games-result-desktop position-relative px-2_5 pt-2 pb-4_5 bg-main-3 rounded">
            <div className="d-flex justify-content-between mb-2_5 text-extra-3">
              {!isInProgress && !isSearchedGamesInProgress
                ? (
                  <FormattedTag
                    id={`search-results.${events.length || leagues.length ? 'value' : 'not-found'}`}
                    className="text-small"
                    values={{ searchValue }}
                  />
                )
                : <span />}
              <FormattedTag
                id="general.close"
                className="caption text-uppercase"
                role="button"
                tabIndex="0"
                onClick={this.closeSearchResult}
                onKeyPress={this.closeSearchResult}
              />
            </div>

            {isInProgress || isSearchedGamesInProgress
              ? (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <Spinner />
                </div>
              )
              : (
                <Fragment>
                  {!!preparedSports.length && (
                    <Tabs
                      activeTab={preparedSports.length > 1 ? activeTab : preparedSports[0].sportId}
                      toggleTab={this.setActiveTab}
                      classNameLabel={classNames({ 'mb-4': leagues.length, 'mb-2_5': !leagues.length })}
                    >
                      {tabs}
                    </Tabs>
                  )}

                  {!!leagues.length && (
                    <SearchedLeagues
                      leagues={leagues}
                      locale={locale}
                      onLeagueClick={this.onLeagueClick}
                    />
                  )}

                  {!!leagues.length && !!events.length && <div className="searched-games-result-desktop-divider mt-4 mb-2_5" />}

                  {!!events.length && (
                    <div>
                      {events.map(event => (
                        <SearchGroupItem
                          key={event.desc.id}
                          {...event}
                          locale={locale}
                          toggleStake={toggleStake}
                          betSlipStakes={betSlipStakes}
                          betSlipState={betSlipState}
                          statuses={statuses}
                          marketsInfo={markets}
                          isLogoShowed={!activeTab}
                          isLiveFrozen={isLiveFrozen}
                          isPrematchFrozen={isPrematchFrozen}
                          onLeagueClick={this.onLeagueClick}
                          onEventClick={this.onEventClick}
                        />
                      ))}
                    </div>
                  )}
                </Fragment>
              )}
          </div>
        </div>
      </Portal>
    );
  }
}

export const SearchedGamesResultDesktop = withFeedbackActions(
  withLocale(withRouter(withSearchedGames(withBetSlipActions(
    withBetSlip(SearchedGamesResultDesktopUI, [
      BET_SLIP_STORE_FIELDS.STAKES,
      BET_SLIP_STORE_FIELDS.BET_SLIP_STATE,
    ])
  ))))
);
