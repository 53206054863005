import loadable from '@loadable/component';
import { langPrefix } from 'core/helpers';
import { PageNotFoundRouter } from 'core/pages';

import { App } from './app';
import { Search } from './pages/search/search';
import { withLogin } from './hocs/with-login';
import { PAGE_NAMES } from './constants';

const PageNotFound = loadable(() => import(/* webpackChunkName: "page-not-found" */ './pages/page-not-found/page-not-found'));
const Maintenance = loadable(() => import(/* webpackChunkName: "maintenance" */ './pages/maintenance/maintenance'));

const routes = [
  {
    component: App,
    routes: [
      {
        path: langPrefix(''),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "home" */ './pages/home/home')),
        page: PAGE_NAMES.HOME,
      },
      {
        path: langPrefix('sport/:sportId'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "sport" */ './pages/sport/sport')),
        page: PAGE_NAMES.SPORT,
      },
      {
        path: langPrefix('league/:sportId/:leagueId'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "league" */ './pages/league/league')),
        page: PAGE_NAMES.LEAGUE,
      },
      {
        path: langPrefix('event/:eventId'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "event" */ './pages/event/event')),
        page: PAGE_NAMES.EVENT,
      },
      {
        path: langPrefix('live/:liveSportId?'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "live" */ './pages/live/live')),
        page: PAGE_NAMES.LIVE,
      },
      {
        path: langPrefix('search'),
        exact: true,
        component: Search, // not loadable due to autofocus issue when mount
        page: PAGE_NAMES.SEARCH,
      },
      {
        path: langPrefix('watchlist'),
        exact: true,
        component: withLogin(loadable(() => import(/* webpackChunkName: "watchlist" */ './pages/watchlist/watchlist'))),
        page: PAGE_NAMES.WATCHLIST,
      },
      {
        path: langPrefix('my-bets'),
        exact: true,
        component: withLogin(loadable(() => import(/* webpackChunkName: "my-bets" */ './pages/my-bets/my-bets'))),
        page: PAGE_NAMES.MY_BETS,
      },
      {
        path: langPrefix('sports'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "sports" */ './pages/sports/sports')),
        page: PAGE_NAMES.SPORTS,
      },
      {
        path: langPrefix('bets-feed'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "bets-feed" */ './pages/bets-feed/bets-feed')),
        page: PAGE_NAMES.BETS_FEED,
      },
      {
        path: langPrefix('*'),
        component: PageNotFoundRouter,
      },
    ],
  },
];

export default {
  routes,
  pageNotFound: PageNotFound,
  maintenance: Maintenance,
};
