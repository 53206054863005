import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import * as R from 'ramda';
import Button from 'reactstrap/lib/Button';
import {
  BET_TYPE,
  COMPLEX_SYSTEMS,
  COMPLEX_SYSTEMS_CALC_SINGLES,
  AI_OUTCOME_SECTION,
} from 'core/constants';
import {
  checkIsComplexSystem,
  convertStringifiedBoolean,
  prepareStakesForRepeat,
  mathRound,
} from 'core/helpers';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { IconSuccess } from 'components/icons/icon-success/icon-success';
import { IconBonus } from 'components/icons/icon-bonus/icon-bonus';
import { formatAmountWithCurrencySymbol } from 'helpers/currency';
import { GA } from 'helpers/ga';

import './bet-slip-success.scss';

export class BetSlipSuccess extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    totalBetAmount: PropTypes.number.isRequired,
    totalBetFactor: PropTypes.number.isRequired,
    systemIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    systemVariants: PropTypes.number,
    totalReturnAmount: PropTypes.number.isRequired,
    stakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    clearBetSlip: PropTypes.func.isRequired,
    bonusValue: PropTypes.number,
    isFreebetUsed: PropTypes.bool.isRequired,
    systemBetAmount: PropTypes.number,
    repeatBet: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    systemIndex: null,
    systemVariants: null,
    bonusValue: null,
    systemBetAmount: null,
  };

  onEventClick = (e) => {
    const { dataset: { isOutright } } = e.currentTarget;
    const { sendEventClickFeedback } = this.props;

    sendEventClickFeedback(e);

    if (!convertStringifiedBoolean(isOutright)) {
      GA.event({
        category: 'go-to-event-page',
        label: 'bet-slip-event-click',
      });
    }
  };

  repeatBet = () => {
    const {
      repeatBet,
      totalBetAmount,
      betType,
      stakes,
      systemIndex,
    } = this.props;
    let type = '1/1';

    if (betType === BET_TYPE.EXPRESS) {
      type = `${stakes.length}/${stakes.length}`;
    } else if (betType === BET_TYPE.SYSTEM) {
      let systemBetType = systemIndex;
      const complexSystemBetType = R.includes(systemIndex, COMPLEX_SYSTEMS[stakes.length]);

      if (complexSystemBetType) {
        const calcSingles = R.includes(systemIndex, COMPLEX_SYSTEMS_CALC_SINGLES);
        systemBetType = calcSingles ? 'FS' : 'F';
      }

      type = `${systemBetType}/${stakes.length}`;
    }

    repeatBet({
      sum: totalBetAmount,
      type,
      selections: prepareStakesForRepeat(stakes),
    });

    GA.event({
      category: 'bet-slip',
      label: 'repeat-last-bet-click',
    });
  };

  render() {
    const {
      locale,
      currency,
      betType,
      totalBetAmount,
      totalBetFactor,
      systemIndex,
      systemVariants,
      totalReturnAmount,
      stakes,
      clearBetSlip,
      bonusValue,
      isFreebetUsed,
      systemBetAmount,
    } = this.props;
    const isOrdinar = betType === BET_TYPE.ORDINAR;
    const isExpress = betType === BET_TYPE.EXPRESS;
    const isSystem = betType === BET_TYPE.SYSTEM;
    const isOneBet = stakes.length === 1;

    return (
      <div className="bet-slip-success">
        <div className="bet-slip-success-header d-flex justify-content-between align-items-center pr-1">
          <div className="d-flex align-items-center">
            <IconSuccess />
            <FormattedTag id="bet-slip.success" className="ml-1_5 text-small font-weight-bold" />
          </div>

          <FormattedTag
            id="bet-slip.done"
            tag={Button}
            size="sm"
            color="primary"
            onClick={clearBetSlip}
          />
        </div>

        <div className="bet-slip-repeat-bet px-2 py-1_5 bg-main-4">
          <FormattedTag
            tag={Button}
            id="bet-slip.repeat-last-bet"
            block
            size="sm"
            outline
            onClick={this.repeatBet}
          />
        </div>

        <div className="bet-slip-success-bets pt-2 pb-2_5 bg-main-4 overflow-hidden">
          <div className="d-flex justify-content-between mb-0_25 font-weight-bold">
            {isOrdinar && <FormattedMessage id={`bet-slip.type.${isOneBet ? 'ordinar' : 'ordinars'}`} />}
            {isExpress && <FormattedMessage id="bet-slip.type.express" />}
            {isSystem && <FormattedMessage id={checkIsComplexSystem(systemIndex) ? `bet-slip.type.complex.${systemIndex}` : 'bet-slip.type.system'} values={{ systemIndex, stakesAmount: stakes.length }} />}
            {isExpress && !!totalBetFactor && <span>{mathRound(totalBetFactor)}</span>}
            {isFreebetUsed && <FormattedTag id="freebet.title" className="bet-item-freebet bg-warning px-0_5 py-0_25 font-weight-bold align-self-center" />}
          </div>

          {isExpress && (
            <FormattedTag id="bet-slip.express-bets" className="caption text-extra-2" values={{ stakesAmount: stakes.length }} />
          )}

          {isSystem && (
            <FormattedTag id="bet-slip.variants" className="caption text-extra-2" values={{ amount: systemVariants }} />
          )}

          {stakes.map(({
            stakeId,
            isOutright,
            outrightName,
            teamA,
            teamB,
            betFactor,
            marketName,
            outcomeName,
            eventId,
            isLive,
          }) => {
            const teams = isOutright ? outrightName : `${teamA} - ${teamB}`;
            const LinkComponent = isOutright ? 'span' : RouteLink;

            return (
              <div key={stakeId} className="mt-2_5 pl-2_5">
                <div className="d-flex justify-content-between text-small overflow-hidden mb-0_5">
                  <span className="font-weight-bold text-truncate mr-1">{outcomeName}</span>
                  {!!betFactor && <span className="text-extra-2">{mathRound(betFactor)}</span>}
                </div>

                <div title={marketName} className="caption text-extra-2 mb-0_25 text-truncate">
                  {marketName}
                </div>

                {teams && (
                  <LinkComponent
                    locale={locale}
                    to={`/event/${eventId}?isLive=${isLive}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.BET_SLIP}`}
                    title={teams}
                    className={classNames('caption', { 'bet-slip-success-event-link': !isOutright, 'text-extra-2': isOutright })}
                    onClick={this.onEventClick}
                    data-is-outright={isOutright}
                    data-event-id={eventId}
                    data-is-live={isLive}
                  >
                    {teams}
                  </LinkComponent>
                )}
              </div>
            );
          })}

          {!!bonusValue && (
            <div className="d-flex align-items-center justify-content-between text-small mt-2_5">
              <div className="d-flex align-items-center ml-n0_5">
                <IconBonus />
                <FormattedTag id="general.bonus" className="font-weight-bold ml-1 mr-0_25" />
              </div>
              <span className="text-extra-2">
                {mathRound(bonusValue)}
              </span>
            </div>
          )}
        </div>

        <div className="bet-slip-success-footer py-2 bg-main-4">
          <div className="d-flex justify-content-between mb-0_5 text-small text-extra-2">
            <FormattedMessage id="bet-slip.total-bet" />
            {!!totalBetAmount && (
              <span>{formatAmountWithCurrencySymbol(isSystem ? systemBetAmount : totalBetAmount, currency)}</span>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <FormattedTag id="bet-slip.total-return" className="text-small text-extra-2" />
            {!!totalReturnAmount && <span className="font-weight-bold">{formatAmountWithCurrencySymbol(totalReturnAmount, currency)}</span>}
          </div>
        </div>
      </div>
    );
  }
}
