import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Label from 'reactstrap/lib/Label';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { Checkbox } from 'components/checkbox/checkbox';
import { GA } from 'helpers/ga';

import './bet-factor-changes.scss';

export class BetFactorChanges extends Component {
  static propTypes = {
    betFactorChanges: PropTypes.bool.isRequired,
    setBetFactorChanges: PropTypes.func.isRequired,
  };

  onChange = () => {
    const { betFactorChanges, setBetFactorChanges } = this.props;

    setBetFactorChanges(!betFactorChanges);
    GA.event({
      category: 'bet-slip',
      label: 'accept-odds-change',
    });
  };

  render() {
    const { betFactorChanges } = this.props;

    return (
      <div className="bet-factor-changes text-small mt-2 position-relative">
        <Label
          role="button"
          check
          className="d-flex align-items-center justify-content-center"
        >
          <Checkbox
            id="bet-factor-changes-checkbox"
            name="bet-factor-changes-checkbox"
            checked={betFactorChanges}
            onChange={this.onChange}
            className="mr-1_5"
          />
          <FormattedTag id="bet-slip.bet-factor-changes" className="text-extra-2 text-small" />
        </Label>
      </div>
    );
  }
}
