import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import {
  withLocale,
  withSearchedGames,
  withBetSlip,
  withBetSlipActions,
  withFeedbackActions,
} from 'core/hocs';
import { convertStringifiedBoolean } from 'core/helpers';
import { BET_SLIP_STORE_FIELDS, BET_SLIP_STATE } from 'core/constants';

import { Spinner } from 'components/spinner/spinner';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { Tabs, Tab } from 'components/tabs/tabs';
import { prepareSports, filterEventsBySport, filterLeaguesBySport } from 'helpers/searched-games';

import { SearchGroupItem } from './search-group-item/search-group-item';
import { SearchedLeagues } from '../searched-leagues/searched-leagues';

import './searched-games-result-mobile.scss';

class SearchedGamesResultMobileUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    searchValue: PropTypes.string.isRequired,
    searchedGames: PropTypes.PropTypes.shape(),
    isSearchedGamesInProgress: PropTypes.bool.isRequired,
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    toggleStake: PropTypes.func.isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    logoUrl: PropTypes.string.isRequired,
    logoUrl2: PropTypes.string.isRequired,
    statuses: PropTypes.shape(),
    streams: PropTypes.shape(),
    markets: PropTypes.shape(),
    sports: PropTypes.arrayOf(PropTypes.shape()),
    isLiveFrozen: PropTypes.bool.isRequired,
    isPrematchFrozen: PropTypes.bool.isRequired,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    searchedGames: null,
    statuses: null,
    streams: null,
    markets: null,
    sports: null,
  };

  state = {
    isInProgress: false,
    activeTab: 0,
  };

  componentDidUpdate(prevProps) {
    const {
      searchValue: prevSearchValue,
      isSearchedGamesInProgress: prevIsSearchedGamesInProgress,
    } = prevProps;
    const {
      searchValue,
      isSearchedGamesInProgress,
    } = this.props;

    if (prevSearchValue !== searchValue && searchValue) {
      this.setIsInProgress(true);
    }

    if (prevIsSearchedGamesInProgress && !isSearchedGamesInProgress) {
      this.setActiveTab(0);
      this.setIsInProgress(false);
    }
  }

  setActiveTab = activeTab => this.setState({ activeTab });

  setIsInProgress = isInProgress => this.setState({ isInProgress });

  onLeagueClick = (e) => {
    const { dataset: { leagueId } } = e.currentTarget;
    const { sendLeagueClickFeedback } = this.props;
    sendLeagueClickFeedback(leagueId);
  };

  onEventClick = (e) => {
    const { dataset: { eventId, isLive } } = e.currentTarget;
    const { sendEventClickFeedback } = this.props;
    sendEventClickFeedback(eventId, convertStringifiedBoolean(isLive));
  };

  render() {
    const {
      locale,
      searchedGames,
      isSearchedGamesInProgress,
      betSlipStakes,
      toggleStake,
      betSlipState,
      statuses,
      logoUrl,
      logoUrl2,
      streams,
      markets,
      sports,
      isLiveFrozen,
      isPrematchFrozen,
    } = this.props;
    const { isInProgress, activeTab } = this.state;
    let events = searchedGames ? searchedGames.events : [];
    let leagues = searchedGames ? searchedGames.leagues : [];
    const preparedSports = searchedGames && sports ? prepareSports(searchedGames, sports) : [];
    const tabs = [];

    if (preparedSports.length) {
      if (preparedSports.length > 1) {
        tabs.push(
          <Tab
            label="general.all"
            tabId={0}
            key={0}
          >
            {null}
          </Tab>
        );

        if (activeTab) {
          events = filterEventsBySport(events, activeTab);
          leagues = filterLeaguesBySport(leagues, activeTab);
        }
      }

      preparedSports.forEach(({ sportId, sportName }) => {
        tabs.push(
          <Tab
            label={sportName}
            tabId={sportId}
            key={sportId}
          >
            {null}
          </Tab>
        );
      });
    }

    return (
      <div className="searched-games-result-mobile d-sm-none">
        {isInProgress || isSearchedGamesInProgress
          ? <Spinner className="searched-games-result-mobile-loader position-absolute" />
          : (
            <Fragment>
              {!!preparedSports.length && (
                <Tabs activeTab={preparedSports.length > 1 ? activeTab : preparedSports[0].sportId} toggleTab={this.setActiveTab} className="ml-2 mt-2_5">
                  {tabs}
                </Tabs>
              )}

              {!!leagues.length && (
                <SearchedLeagues
                  leagues={leagues}
                  locale={locale}
                  onLeagueClick={this.onLeagueClick}
                />
              )}

              {!!events.length && (
                <div>
                  {events.map(event => (
                    <SearchGroupItem
                      key={event.desc.id}
                      {...event}
                      locale={locale}
                      isLogoShowed={!activeTab}
                      toggleStake={toggleStake}
                      betSlipStakes={betSlipStakes}
                      betSlipState={betSlipState}
                      statuses={statuses}
                      logoUrl={logoUrl}
                      logoUrl2={logoUrl2}
                      streams={streams}
                      marketsInfo={markets}
                      isLiveFrozen={isLiveFrozen}
                      isPrematchFrozen={isPrematchFrozen}
                      onLeagueClick={this.onLeagueClick}
                      onEventClick={this.onEventClick}
                    />
                  ))}
                </div>
              )}

              {!R.isNil(searchedGames) && !events.length && !leagues.length && (
                <FormattedTag
                  tag="div"
                  id="search-results.not-found"
                  className="text-extra-3 text-small pl-2 pt-2_5"
                />
              )}
            </Fragment>
          )}
      </div>
    );
  }
}

export const SearchedGamesResultMobile = withFeedbackActions(
  withLocale((withRouter(withSearchedGames(withBetSlipActions(
    withBetSlip(SearchedGamesResultMobileUI, [
      BET_SLIP_STORE_FIELDS.STAKES,
      BET_SLIP_STORE_FIELDS.BET_SLIP_STATE,
    ])
  )))))
);
