import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import { wrapRoute2Locale, isEmptyOrNil } from 'core/helpers';
import { withLocale, withFeedbackActions } from 'core/hocs';
import { AI_OUTCOME_SECTION } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { SportTypeIcon } from 'components/sport-type-icon/sport-type-icon';
import { TeamLogoPlaceholder } from 'components/team-logo-placeholder/team-logo-placeholder';
import { IconMenu } from 'components/icons/icon-menu/icon-menu';
import { IconColon } from 'components/icons/icon-colon/icon-colon';
import { IconPin } from 'components/icons/icon-pin/icon-pin';
import { IconArrowRight } from 'components/icons/icon-arrow-right/icon-arrow-right';
import { prepareLogo } from 'helpers/logo';
import { GA } from 'helpers/ga';
import { SPORTS_WITHOUT_LIVE_SCORE } from '../../../constants';

import './live-widget-header.scss';

class LiveWidgetHeaderUI extends Component {
  static propTypes = {
    activeItem: PropTypes.shape(),
    setIsLiveWidgetPinned: PropTypes.func.isRequired,
    isInProgress: PropTypes.bool.isRequired,
    isPinned: PropTypes.bool.isRequired,
    eventData: PropTypes.shape(),
    setActiveLiveWidgetItem: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    history: PropTypes.shape().isRequired,
    locale: PropTypes.string.isRequired,
    logoUrl: PropTypes.string.isRequired,
    logoUrl2: PropTypes.string.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeItem: null,
    eventData: null,
  };

  state = {
    isOpen: false,
  };

  toggle = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  onClick = (e) => {
    e.stopPropagation();
    const { dataset: { eventId } } = e.currentTarget;
    const {
      setActiveLiveWidgetItem,
      locale,
      history,
      setIsLiveWidgetPinned,
      sendEventClickFeedback,
    } = this.props;

    setActiveLiveWidgetItem(eventId);
    history.push(wrapRoute2Locale(`/event/${eventId}?isLive=true&sectionBeforeEventPage=${AI_OUTCOME_SECTION.LIVE_WIDGET}`, locale));
    setIsLiveWidgetPinned(false);
    sendEventClickFeedback(eventId, true);
  };

  onPinClick = () => {
    const { isPinned, setIsLiveWidgetPinned, activeItem } = this.props;

    if (activeItem) {
      setIsLiveWidgetPinned(!isPinned);
      GA.event({
        category: 'event-page',
        label: isPinned ? 'unpin-widget' : 'pin-widgets',
      });
    }
  };

  onDropdownClick = () => {
    GA.event({
      category: 'event-page',
      label: 'widget-events-list-click',
    });
  };

  render() {
    const {
      items,
      activeItem,
      isPinned,
      eventData,
      isInProgress,
      logoUrl,
      logoUrl2,
    } = this.props;
    const { isOpen } = this.state;
    const signForNonExistedScore = eventData && eventData.isLive ? 0 : '-';

    if (isInProgress && !isPinned) {
      return (
        <div className="live-widget-header d-flex justify-content-between bg-main-2">
          <div className="live-widget-header-menu-loader d-flex align-items-center justify-content-center">
            <IconMenu className="mx-1" />
          </div>

          <div className="live-widget-header-pin is-disabled d-flex align-items-center justify-content-center">
            <IconPin />
          </div>
        </div>
      );
    }

    return !isEmptyOrNil(items) && (
      <div className={classNames('live-widget-header d-flex bg-main-2 position-relative', {
        'is-empty': !eventData,
      })}
      >
        <Dropdown isOpen={isOpen} toggle={this.toggle} onClick={this.onDropdownClick}>
          <DropdownToggle className="live-widget-header-toggle p-0 rounded-0">
            <IconMenu className="mx-1" />
            {!eventData && (
              <div className="d-flex align-items-center">
                <FormattedTag id="live-widget.select-event" className="mr-0_5 text-small font-weight-normal text-extra-2" />
                <IconArrowRight className="icon-arrow-right" />
              </div>
            )}
          </DropdownToggle>
          <DropdownMenu className="live-widget-header-menu">
            {Object.values(items).map(item => !isEmptyOrNil(item.desc) && (
              <DropdownItem
                key={item.desc.id}
                className={classNames('live-widget-header-menu-item position-relative', {
                  'active font-weight-bold': eventData && eventData.desc.id === item.desc.id,
                })}
                onClick={this.onClick}
                onKeyPress={this.onClick}
                data-event-id={item.desc.id}
              >
                <div className="text-small text-wrap d-flex align-items-center">
                  <SportTypeIcon isActive={eventData && eventData.desc.id === item.desc.id} sportId={item.desc.sport.id} width="16" height="16" className="mr-1_5" />
                  <span>{item.desc.competitors[0].name} - {item.desc.competitors[1].name}</span>
                </div>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>

        {!isEmptyOrNil(eventData) && !isEmptyOrNil(eventData.desc) && (
          <Fragment>
            <div className="flex-fill d-flex px-1 align-items-center justify-content-center overflow-hidden">
              <div className="live-widget-header-team d-flex align-items-center justify-content-between overflow-hidden">
                <div className="d-flex align-items-center overflow-hidden">
                  <TeamLogoPlaceholder
                    imageUrl={prepareLogo({
                      logoUrl,
                      logoUrl2,
                      competitorId: eventData.desc.competitors[0].id,
                      sportId: eventData.desc.sport.id,
                      categorySlug: eventData.desc.category.slug,
                      countryCode: eventData.desc.competitors[0].country_code,
                    })}
                    sportId={eventData.desc.sport.id}
                    className="live-widget-header-logo mr-1 flex-shrink-0"
                  />
                  <span title={eventData.desc.competitors[0].name} className="text-truncate caption font-weight-bold mr-1">{eventData.desc.competitors[0].name}</span>
                </div>
                {!SPORTS_WITHOUT_LIVE_SCORE.includes(eventData.desc.sport.id) && <div className="live-widget-header-score font-weight-bold bg-main-4">{!isEmptyOrNil(eventData.score) ? eventData.score.home_score : signForNonExistedScore}</div>}
              </div>

              <IconColon className="mx-0_25 flex-shrink-0" />

              <div className="live-widget-header-team d-flex align-items-center justify-content-between overflow-hidden">
                {!SPORTS_WITHOUT_LIVE_SCORE.includes(eventData.desc.sport.id) && (
                  <div className="live-widget-header-score font-weight-bold bg-main-4">{!isEmptyOrNil(eventData.score) ? eventData.score.away_score : signForNonExistedScore}</div>
                )}
                <div className="d-flex align-items-center justify-content-end overflow-hidden">
                  <span title={eventData.desc.competitors[1].name} className="text-truncate caption font-weight-bold ml-1">{eventData.desc.competitors[1].name}</span>
                  <TeamLogoPlaceholder
                    imageUrl={prepareLogo({
                      logoUrl,
                      logoUrl2,
                      competitorId: eventData.desc.competitors[1].id,
                      sportId: eventData.desc.sport.id,
                      categorySlug: eventData.desc.category.slug,
                      countryCode: eventData.desc.competitors[1].country_code,
                    })}
                    sportId={eventData.desc.sport.id}
                    className="live-widget-header-logo ml-1 flex-shrink-0"
                  />
                </div>
              </div>
            </div>

            <div
              role="button"
              tabIndex="0"
              onClick={this.onPinClick}
              onKeyPress={this.onPinClick}
              className={classNames('live-widget-header-pin d-flex align-items-center justify-content-center', {
                'is-disabled': !activeItem,
                'is-pinned': isPinned,
              })}
            >
              <IconPin />
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export const LiveWidgetHeader = withFeedbackActions(withRouter(withLocale(LiveWidgetHeaderUI)));
