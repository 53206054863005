import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';
import Collapse from 'reactstrap/lib/Collapse';
import {
  prepareBetsForRepeat,
  divideValues,
  mathRound,
  convertStringifiedBoolean,
} from 'core/helpers';
import { BET_STATUS, AI_OUTCOME_SECTION } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { CashoutConfirm } from 'components/cashout-confirm/cashout-confirm';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { BetScore } from 'components/bet-score/bet-score';
import { IconLiveStatus } from 'components/icons/icon-live-status/icon-live-status';
import { IconArrowBottom } from 'components/icons/icon-arrow-bottom/icon-arrow-bottom';
import { IconBonus } from 'components/icons/icon-bonus/icon-bonus';
import { IconRepeat } from 'components/icons/icon-repeat/icon-repeat';
import { toShortFormat } from 'helpers/date';
import { prepareCurrency, formatAmountWithCurrencySymbol } from 'helpers/currency';
import {
  getMultipleBetStatusIcon,
  prepareOutrightName,
  getSystemIntlId,
  isBetWithRepeat,
} from 'helpers/bets';
import { GA } from 'helpers/ga';
import { BetStatus } from '../bet-status/bet-status';
import { MODAL_IDS } from '../../../../../../constants';

export class BetItemMultiple extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    makeCashout: PropTypes.func,
    id: PropTypes.string.isRequired,
    potential_win: PropTypes.string,
    result_sum: PropTypes.string,
    sum: PropTypes.string.isRequired,
    total_k: PropTypes.string,
    k: PropTypes.string,
    timestamp: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    selections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    combinations: PropTypes.arrayOf(PropTypes.shape()),
    bonus: PropTypes.shape(),
    cashout_amount: PropTypes.string,
    isCashoutInProgress: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    repeatBet: PropTypes.func.isRequired,
    onCashoutClick: PropTypes.func.isRequired,
    closeCashout: PropTypes.func.isRequired,
    isCashoutOpen: PropTypes.bool.isRequired,
    cashoutX: PropTypes.number,
    cashoutY: PropTypes.number,
    isCashoutAvailable: PropTypes.bool.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    makeCashout: null,
    bonus: null,
    potential_win: null,
    result_sum: null,
    total_k: null,
    k: null,
    cashout_amount: null,
    isCashoutInProgress: false,
    combinations: null,
    cashoutX: null,
    cashoutY: null,
  };

  state = {
    isOpen: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  toggleIsOpen = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  onCashoutClick = (e) => {
    const { onCashoutClick, id } = this.props;
    onCashoutClick(e, id);
  };

  cancelCashout = (e) => {
    const { closeCashout } = this.props;
    e.stopPropagation();
    closeCashout();
    GA.event({
      category: 'bets-history',
      label: 'cancel-cashout',
    });
  };

  makeCashout = (e) => {
    e.stopPropagation();
    const {
      id,
      cashout_amount: cashoutAmount,
      makeCashout,
      closeCashout,
    } = this.props;

    closeCashout();
    makeCashout({ id, cashoutAmount });
    GA.event({
      category: 'bets-history',
      label: 'confirm-cashout',
    });
  };

  repeatBet = (e) => {
    e.stopPropagation();
    const {
      repeatBet,
      sum,
      type,
      selections,
      combinations,
      closeModal,
    } = this.props;
    repeatBet({
      sum: combinations ? divideValues([sum, combinations.length]) : sum,
      type,
      selections: prepareBetsForRepeat(selections, sum),
    });
    closeModal(MODAL_IDS.BET_HISTORY);
    GA.event({
      category: 'bet-slip',
      label: 'repeat-last-bet-click',
    });
  };

  getBetDescription = () => {
    const { selections } = this.props;

    return selections.reduce((prev, cur, idx) => {
      const {
        market_name: marketName,
        outcome_name: outcomeName,
      } = cur;

      if (idx === 0) {
        return `${marketName} ${outcomeName}`;
      }

      return `${prev}, ${marketName} ${outcomeName}`;
    }, '');
  };

  onEventClick = (e) => {
    const { dataset: { eventId, isLive } } = e.currentTarget;
    const { closeModal, sendEventClickFeedback } = this.props;
    e.stopPropagation();
    closeModal(MODAL_IDS.BET_HISTORY);
    GA.event({
      category: 'go-to-event-page',
      label: 'my-bets-event-click',
    });
    sendEventClickFeedback(eventId, convertStringifiedBoolean(isLive));
  };

  render() {
    const {
      locale,
      currency,
      id,
      potential_win: potentialWin,
      result_sum: resultSum,
      sum,
      total_k: totalK,
      k,
      timestamp,
      status,
      selections,
      type,
      bonus,
      cashout_amount: cashoutAmount,
      isCashoutInProgress,
      isCashoutOpen,
      cashoutX,
      cashoutY,
      isCashoutAvailable,
    } = this.props;
    const { isOpen } = this.state;
    const bonusValue = bonus && bonus.total_multiplier;
    const fillDate = timestamp * 1000;
    const [systemIndex, stakesAmount] = type.split('/');
    const isExpress = systemIndex === stakesAmount;
    const betDescription = this.getBetDescription();
    const StatusIcon = getMultipleBetStatusIcon(status);
    const intlId = `bet-slip.type.${isExpress ? 'express' : getSystemIntlId(type)}`;
    const isActive = status === BET_STATUS.OPEN;
    const isWon = status === BET_STATUS.WON;
    const isCashouted = status === BET_STATUS.CASHED_OUT;
    const betFactor = isActive ? totalK : k;
    const isLiveExists = !!selections.find(({ live }) => live);
    const withRepeat = isBetWithRepeat(selections);

    return (
      <div
        role="button"
        tabIndex="0"
        onClick={this.toggleIsOpen}
        onKeyPress={this.toggleIsOpen}
        className={classNames('bets-table-item text-small', {
          'is-open': isOpen,
          'is-won': isWon && !isCashouted,
        })}
      >
        <div className="d-flex justify-content-between align-items-center py-1_5">
          <div className="bets-table-column align-self-start text-right">
            {isLiveExists && <IconLiveStatus />}
          </div>
          <div className="bets-table-column pl-1_5">
            <FormattedTag
              id={intlId}
              values={isExpress ? {} : { systemIndex, stakesAmount }}
              tag="div"
              className="font-weight-bold mb-0_5"
            />
            <div title={betDescription} className="text-truncate">{betDescription}</div>
          </div>
          <div className="bets-table-column pl-1_5">
            {toShortFormat(fillDate, locale)}
          </div>
          <div className="bets-table-column pl-1_5 text-break">
            {id}
          </div>
          <div className="bets-table-column text-right px-1_5">
            {isExpress && !!betFactor && mathRound(betFactor)}
          </div>
          <div className="bets-table-column text-right pr-2_5">
            {!!sum && <span>{mathRound(sum)} {prepareCurrency(currency)}</span>}
          </div>
          <div className="bets-table-column d-flex align-items-center justify-content-end pr-1_5">
            {isActive && !!cashoutAmount && isCashoutAvailable && (
              <ButtonWithLoader
                onClick={this.onCashoutClick}
                className={classNames('bets-table-cashout-btn px-1 py-0_25', { 'mr-3_5': !withRepeat })}
                contentClassName="d-flex flex-column align-items-center justify-content-center"
                size="sm"
                color="primary"
                isLoading={isCashoutInProgress}
              >
                <FormattedTag id="my-bets.cashout" className="caption font-weight-normal" />
                <span className="text-small font-weight-bold">{formatAmountWithCurrencySymbol(cashoutAmount, currency)}</span>
              </ButtonWithLoader>
            )}

            {withRepeat && (
              <div
                role="button"
                tabIndex="0"
                onClick={this.repeatBet}
                onKeyPress={this.repeatBet}
                className="ml-1_5 flex-shrink-0"
              >
                <IconRepeat className="icon-repeat" />
              </div>
            )}
          </div>
          <div className="bets-table-column d-flex justify-content-end align-items-center text-right pr-1_5">
            {isCashouted
              ? (
                <div className="d-flex flex-column justify-content-center align-items-end text-extra-2">
                  <FormattedTag id="bets-history.cashed-out" className="mb-0_5 font-weight-bold" />
                  {!!resultSum && <span className="caption">{mathRound(resultSum)} {prepareCurrency(currency)}</span>}
                </div>
              )
              : (
                <div className="d-flex align-items-center">
                  <StatusIcon className="bet-status-icon mr-1 flex-shrink-0" />
                  {(potentialWin || resultSum) && (
                    <span className={classNames('bets-table-win-amount font-weight-bold', {
                      'text-extra-3': !isActive && !isWon
                    })}
                    >
                      {isActive
                        ? mathRound(potentialWin)
                        : mathRound(resultSum)} {prepareCurrency(currency)}
                    </span>
                  )}
                </div>

              )}
            <IconArrowBottom className="icon-arrow-bottom ml-3 flex-shrink-0" backgroundColor="transparent" />
          </div>
        </div>

        <Collapse isOpen={isOpen}>
          <div className="bets-table-collapse mx-1_5 py-2 text-small">
            <div className="d-flex caption text-extra-2">
              <div className="bets-table-collapse-item-multiple pr-1_5 pl-5">
                <FormattedMessage id="bets-history.bet" />
              </div>
              <div className="bets-table-collapse-item-multiple pr-1_5">
                <FormattedMessage id="bets-history.event" />
              </div>
              <div className="bets-table-collapse-item-multiple text-right pr-1_5">
                <FormattedMessage id="bets-history.odd" />
              </div>
              <div className="bets-table-collapse-item-multiple text-right pr-5 mr-0_5">
                <FormattedMessage id="bets-history.status" />
              </div>
            </div>

            <div>
              {selections.map((selection) => {
                const {
                  id: selectionId,
                  desc: {
                    competitors,
                    scheduled,
                    type: eventType,
                    stage,
                    season,
                    tournament,
                    sport: {
                      id: sportId,
                    },
                  },
                  market_name: marketName,
                  outcome_name: outcomeName,
                  status: selectionStatus,
                  k: selectionK,
                  final_score: finalScore,
                  event_id: eventId,
                  eventInfo,
                } = selection;
                const eventDateTimeStamp = scheduled * 1000;
                const isOutright = eventType !== 'match';
                const teamA = !isOutright && competitors[0].name;
                const teamB = !isOutright && competitors[1].name;
                const outrightName = isOutright && prepareOutrightName(eventType, stage, season, tournament);
                const withEventLink = eventInfo && !isOutright;
                const LinkComponent = withEventLink ? RouteLink : 'span';
                const linkProps = withEventLink
                  ? {
                    locale,
                    to: `/event/${eventId}?isLive=${eventInfo && eventInfo.isLive}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.MY_BETS}`,
                    onClick: this.onEventClick,
                    'data-event-id': eventId,
                    'data-is-live': !!eventInfo && eventInfo.isLive,
                  }
                  : {};

                return (
                  <div key={selectionId} className="d-flex mt-1_5">
                    <div className="bets-table-collapse-item-multiple pr-1_5 pl-5">
                      {marketName} {outcomeName}
                    </div>
                    <div className="bets-table-collapse-item-multiple d-flex pr-1_5">
                      <LinkComponent
                        {...linkProps}
                        title={isOutright ? outrightName : `${teamA} - ${teamB}`}
                        className={classNames('text-truncate', { 'bets-table-event-link': withEventLink, 'text-extra-2': !withEventLink })}
                      >
                        {isOutright ? outrightName : `${teamA} - ${teamB}`}
                      </LinkComponent>

                      <span className="mx-0_5 text-extra-3">&middot;</span>

                      <BetScore
                        score={eventInfo && eventInfo.score}
                        finalScore={finalScore}
                        sportId={sportId}
                        date={toShortFormat(eventDateTimeStamp, locale)}
                      />
                    </div>
                    <div className="bets-table-collapse-item-multiple text-right pr-1_5">
                      {!!selectionK && mathRound(selectionK)}
                    </div>
                    <div className="bets-table-collapse-item-multiple text-right pr-5 mr-0_5">
                      <BetStatus status={selectionStatus} />
                    </div>
                  </div>
                );
              })}
              {!!bonusValue && (
                <div className="d-flex mt-1_5">
                  <div className="bets-table-collapse-item-multiple pr-1_5 d-flex align-items-center pl-5">
                    <IconBonus />
                    <FormattedTag id="general.bonus" className="text-small ml-1 mr-0_25" />
                  </div>
                  <div className="bets-table-collapse-item-multiple pr-1_5" />
                  <div className="bets-table-collapse-item-multiple text-right pr-1_5">
                    {mathRound(bonusValue)}
                  </div>
                  <div className="bets-table-collapse-item-multiple pr-5 mr-0_5" />
                </div>
              )}
            </div>
          </div>
        </Collapse>

        {isCashoutOpen && (
          <CashoutConfirm
            currency={currency}
            isPortal
            xPosition={cashoutX}
            yPosition={cashoutY}
            cashoutAmount={cashoutAmount}
            cancelCashout={this.cancelCashout}
            makeCashout={this.makeCashout}
          />
        )}
      </div>
    );
  }
}
