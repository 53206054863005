import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportOverwatch = ({
  className, isActive, width, height,
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.97931 2.37964e-05C10.9434 -0.0044914 12.8551 0.633652 14.4227 1.81709L12.8982 3.83653C11.7713 2.98581 10.3971 2.52708 8.98513 2.53032C7.5732 2.53357 6.2011 2.99862 5.07816 3.85451L3.54432 1.8421C5.10645 0.651465 7.01518 0.004539 8.97931 2.37964e-05Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M2.50602 2.76877C1.28404 4.04228 0.463477 5.64698 0.146417 7.38322C-0.170643 9.11946 0.0297952 10.9106 0.722796 12.5338C1.4158 14.157 2.57077 15.5406 4.04402 16.5125C5.51727 17.4844 7.24377 18.0017 9.00873 18C10.7737 17.9983 12.4992 17.4777 13.9705 16.5029C15.4419 15.5282 16.5942 14.1423 17.284 12.5178C17.9739 10.8932 18.1709 9.10168 17.8504 7.36606C17.53 5.63044 16.7063 4.02734 15.4819 2.7562L13.6595 4.51161C14.5397 5.42538 15.1318 6.57778 15.3622 7.82544C15.5925 9.0731 15.4509 10.3609 14.955 11.5288C14.8645 11.742 14.7628 11.9496 14.6507 12.1507L11 8.5L9.5 5V11L12.7629 14.2629C12.7005 14.3075 12.6372 14.351 12.5731 14.3935C11.5154 15.0942 10.275 15.4685 9.00627 15.4697C7.73753 15.4709 6.49643 15.0991 5.43737 14.4004C5.36968 14.3558 5.30293 14.3099 5.23714 14.2629L8.5 11V5L7 8.5L3.34932 12.1507C3.23923 11.9532 3.13922 11.7495 3.04989 11.5403C2.55173 10.3734 2.40764 9.08587 2.63556 7.83777C2.86348 6.58966 3.45335 5.43612 4.33177 4.52065L2.50602 2.76877Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
  </svg>
);

IconSportOverwatch.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportOverwatch.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
