import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BET_TYPE } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconWarning } from 'components/icons/icon-warning/icon-warning';
import { Portal } from 'components/portal/portal';

import './bet-slip-notification.scss';

export class BetSlipNotification extends Component {
  static propTypes = {
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    isShowed: PropTypes.bool.isRequired,
    isPortal: PropTypes.bool,
  };

  static defaultProps = {
    isPortal: false,
  };

  state = {

  };

  render() {
    const {
      betType,
      isShowed,
      isPortal,
    } = this.props;
    const Wrapper = isPortal ? Portal : Fragment;
    let intlId = 'bet-slip.notification.stakes-exceed.ordinar';

    if (betType === BET_TYPE.EXPRESS) {
      intlId = 'bet-slip.notification.stakes-exceed.express';
    } else if (betType === BET_TYPE.SYSTEM) {
      intlId = 'bet-slip.notification.stakes-exceed.system';
    }

    return (
      <Wrapper>
        <div className={classNames('bet-slip-notification p-1_5 d-flex justify-content-center align-items-center position-fixed', {
          'is-showed': isShowed,
        })}
        >
          <IconWarning className="flex-shrink-0" />
          <FormattedTag
            id={intlId}
            className="ml-1 text-small font-weight-bold"
          />
        </div>
      </Wrapper>
    );
  }
}
