export const SPORTS = {
  FOOTBALL: '1',
  BASKETBALL: '2',
  BASEBALL: '3',
  ICE_HOCKEY: '4',
  TENNIS: '5',
  HANDBALL: '6',
  FLOORBALL: '7',
  TROTTING: '8',
  GOLF: '9',
  BOXING: '10',
  MOTORCYCLING: '11', // old Motorsport
  RUGBY: '12', // NEW
  AUSSIE_RULES: '13',
  WINTER_SPORTS: '14', // NEW
  BANDY: '15',
  AMERICAN_FOOTBALL: '16',
  CYCLING: '17',
  SPECIALS: '18',
  SNOOKER: '19',
  TABLE_TENNIS: '20',
  CRICKET: '21',
  DARTS: '22',
  VOLLEYBALL: '23',
  FIELD_HOCKEY: '24',
  POOL: '25',
  WATER_POLO: '26',
  GAELIC_SPORTS: '27', // NEW
  CURLING: '28',
  FUTSAL: '29',
  OLYMPIC_GAMES: '30',
  BADMINTON: '31',
  BOWLS: '32',
  CHESS: '33',
  BEACH_VOLLEY: '34',
  NETBALL: '35',
  ATHLETICS: '36',
  SQUASH: '37',
  RINK_HOCKEY: '38', // NEW
  LACROSSE: '39',
  FORMULA_1: '40',
  BIKES: '41', // NEW
  DTM: '42', // NEW
  ALPINE_SKIING: '43', // NEW
  BIATHLON: '44',
  BOBSLEIGH: '45', // NEW
  CROSS_COUNTRY: '46', // NEW
  NORDIC: '47', // NEW
  SKI_JUMPING: '48',
  SNOWBOARD: '49', // NEW
  SPEED_SKATING: '50', // NEW
  LUGE: '51', // NEW
  SWIMMING: '52', // NEW
  FINNISH_BASEBALL: '53', // NEW
  SOFTBALL: '54',
  HORSE_RACING: '55',
  SCHWINGEN: '56', // NEW
  INLINE_HOCKEY: '57', // NEW
  GREYHOUND: '58',
  RUGBY_LEAGUE: '59',
  BEACH_FOOTBALL: '60',
  PESAPALLO: '61', // NEW
  STREET_HOCKEY: '62', // NEW
  WORLD_CHAMPIONSHIP: '63', // NEW
  ROWING: '64', // NEW
  FREESTYLE: '65', // NEW
  SNOWBOARD_CROSS: '66', // NEW
  MOTO_GP: '67', // NEW
  MOTO_2: '68', // NEW
  MOTO_3: '69', // NEW
  NASCAR: '70',
  PADEL_TENNIS: '71',
  CANOEING: '72',
  HORSEBALL: '73', // NEW
  AQUATICS: '74', // NEW
  ARCHERY: '75',
  EQUESTRIAN: '76',
  FENCING: '77',
  GYMNASTICS: '78',
  JUDO: '79',
  MODERN_PENTATHLON: '80',
  SAILING: '81',
  SHOOTING: '82',
  TAEKWONDO: '83',
  TRIATHLON: '84',
  WEIGHTLIFTING: '85',
  WRESTLING: '86',
  OLYMPICS_YOUTH: '87', // NEW
  MOUNTAIN_BIKE: '88', // NEW
  RIDING: '89', // NEW
  SURFING: '90',
  BMX: '91', // NEW
  CANOE_SLALOM: '92', // NEW
  RHYTHMIC: '93', // NEW
  TRAMPOLINING: '94', // NEW
  SYNC_SWIMMING: '95', // NEW
  DIVING: '96', // NEW
  TRACK_CYCLING: '97', // NEW
  BEACH_TENNIS: '98', // NEW
  SUMO: '99',
  SUPERBIKE: '100', // NEW
  RALLY: '101',
  FIGURE_SKATING: '102', // NEW
  FREESTYLE_SKIING: '103', // NEW
  SKELETON: '104', // NEW
  SHORT_TRACK: '105', // NEW
  SOCCER_MYTHICAL: '106', // NEW
  E_SPORT: '107',
  LOTTERY: '108',
  CS: '109', // NEW
  LOL: '110', // NEW
  DOTA: '111', // NEW
  STARCRAFT: '112', // NEW
  HEARTHSTONE: '113', // NEW
  HEROES_OF_THE_STORM: '114', // NEW
  WOT: '115', // NEW
  POLO: '116', // NEW
  MMA: '117',
  COD: '118', // NEW
  SMITE: '119', // NEW
  VAINGLORY: '120', // NEW
  OVERWATCH: '121', // NEW
  WARCRAFT_3: '122', // NEW
  CROSSFIRE: '123', // NEW
  HALO: '124', // NEW
  RAINBOW_SIX: '125', // NEW
  SEPAK_TAKRAW: '126',
  STREET_FIGHTER: '127', // NEW
  ROCKET_LEAGUE: '128', // NEW
  INDY_CAR: '129',
  BASQUE_PELOTA: '130', // NEW
  SPEEDWAY: '131',
  GEARS_OF_WAR: '132', // NEW
  CLASH_ROYAL: '133', // NEW
  KING_OF_GLORY: '134', // NEW
  GAELIC_FOOTBALL: '135',
  GAELIC_HURLING: '136', // NEW
  FIFA: '137', // NEW
  KABADDI: '138',
  QUAKE: '139', // NEW
  PUBG: '140', // NEW
  CYCLING_CYCLE_BALL: '141', // NEW
  FORMULA_E: '142', // NEW
  '7_BALL_RUN': '143', // NEW
  MOTORCROSS: '144', // NEW
  SPRINT_CAR_RACING: '145', // NEW
  SPRINT_BOAT_RACING: '146', // NEW
  DRAG_RACING: '147', // NEW
  STOCK_CAR_RACING: '148', // NEW
  MODIFIED_RACING: '149', // NEW
  OFF_ROAD: '150', // NEW
  TRUCK_PULLING: '151', // NEW
  WOW: '152', // NEW
  NBA2K: '153', // NEW
  DRAGON_BALL: '154', // NEW
  BASKETBALL_3X3: '155',
  TEKKEN: '156', // NEW
  BEACH_HANDBALL: '157',
  ARENA_OF_VALOR: '158', // NEW
  MOTORCYCLING_RACING: '190', // NEW
  STOCK_CAR_RACING_1: '191', // NEW
  TVBET: '298', // NEW
  VIRTUAL: '299', // NEW
  FIFA18: '300', // NEW
  ROCKET_LEAGUE_1: '301', // NEW
  NBA2K19: '302', // NEW
  AO_TENNIS: '303', // NEW
  E_FIGHTING: '304', // NEW
  E_CRICKET: '305', // NEW
};

export const SPORTS_WITH_3WAY_MAIN_MARKET = [
  SPORTS.FOOTBALL,
  SPORTS.FIFA,
  SPORTS.FIFA18,
  SPORTS.ICE_HOCKEY,
  SPORTS.HANDBALL,
  SPORTS.FLOORBALL,
  SPORTS.RUGBY,
  SPORTS.RUGBY_LEAGUE,
  SPORTS.FUTSAL,
  SPORTS.E_CRICKET,
  SPORTS.WATER_POLO,
  SPORTS.BANDY,
];

export const SPORTS_WITH_MAIN_MARKET_1 = [
  SPORTS.FOOTBALL,
  SPORTS.FIFA,
  SPORTS.FIFA18,
  SPORTS.ICE_HOCKEY,
  SPORTS.HANDBALL,
  SPORTS.FLOORBALL,
  SPORTS.RUGBY,
  SPORTS.RUGBY_LEAGUE,
  SPORTS.FUTSAL,
  SPORTS.E_CRICKET,
  SPORTS.WATER_POLO,
  SPORTS.BANDY,
];

export const SPORTS_WITH_MAIN_MARKET_219 = [
  SPORTS.BASKETBALL,
  SPORTS.BASKETBALL_3X3,
  SPORTS.AMERICAN_FOOTBALL,
  SPORTS.ROCKET_LEAGUE,
  SPORTS.ROCKET_LEAGUE_1,
  SPORTS.NBA2K,
  SPORTS.NBA2K19,
];

export const SPORTS_WITH_MAIN_MARKET_251 = [
  SPORTS.BASEBALL,
];

export const SPORTS_WITH_MAIN_MARKET_186 = [
  SPORTS.TENNIS,
  SPORTS.BOXING,
  SPORTS.SNOOKER,
  SPORTS.TABLE_TENNIS,
  SPORTS.VOLLEYBALL,
  SPORTS.BADMINTON,
  SPORTS.BEACH_VOLLEY,
  SPORTS.SQUASH,
  SPORTS.CS,
  SPORTS.LOL,
  SPORTS.DOTA,
  SPORTS.STARCRAFT,
  SPORTS.MMA,
  SPORTS.OVERWATCH,
  SPORTS.E_FIGHTING,
  SPORTS.AO_TENNIS,
  SPORTS.BOWLS,
];

export const SPORTS_WITH_MAIN_MARKET_340 = [
  SPORTS.CRICKET,
];

export const SPORTS_WITH_BG = [
  SPORTS.AMERICAN_FOOTBALL,
  SPORTS.AUSSIE_RULES,
  SPORTS.BADMINTON,
  SPORTS.BASEBALL,
  SPORTS.BASKETBALL,
  SPORTS.BASKETBALL_3X3,
  SPORTS.BOWLS,
  SPORTS.CRICKET,
  SPORTS.E_CRICKET,
  SPORTS.DARTS,
  SPORTS.FIELD_HOCKEY,
  SPORTS.FUTSAL,
  SPORTS.HANDBALL,
  SPORTS.ICE_HOCKEY,
  SPORTS.POOL,
  SPORTS.RUGBY,
  SPORTS.RUGBY_LEAGUE,
  SPORTS.SNOOKER,
  SPORTS.FOOTBALL,
  SPORTS.SQUASH,
  SPORTS.TABLE_TENNIS,
  SPORTS.TENNIS,
  SPORTS.AO_TENNIS,
  SPORTS.VOLLEYBALL,
  SPORTS.BEACH_VOLLEY,
  SPORTS.FIFA,
  SPORTS.FIFA18,
  SPORTS.ROCKET_LEAGUE,
  SPORTS.ROCKET_LEAGUE_1,
  SPORTS.NBA2K,
  SPORTS.NBA2K19,
  SPORTS.E_FIGHTING,
];

export const TEAM_SPORTS = [
  SPORTS.FOOTBALL,
  SPORTS.ICE_HOCKEY,
  SPORTS.BASKETBALL,
  SPORTS.VOLLEYBALL,
  SPORTS.AMERICAN_FOOTBALL,
  SPORTS.BASEBALL,
  SPORTS.HANDBALL,
  SPORTS.AUSSIE_RULES,
  SPORTS.CRICKET,
  SPORTS.FIELD_HOCKEY,
  SPORTS.BEACH_FOOTBALL,
  SPORTS.CURLING,
  SPORTS.FLOORBALL,
  SPORTS.NETBALL,
  SPORTS.SOFTBALL,
  SPORTS.GAELIC_FOOTBALL,
  SPORTS.LACROSSE,
  SPORTS.BASKETBALL_3X3,
  SPORTS.BEACH_HANDBALL,
  SPORTS.KABADDI,
  SPORTS.FIFA,
  SPORTS.FIFA18,
];

export const INDIVIDUAL_SPORTS = [
  SPORTS.TENNIS,
  SPORTS.BADMINTON,
  SPORTS.BOWLS,
  SPORTS.DARTS,
  SPORTS.SNOOKER,
  SPORTS.SQUASH,
  SPORTS.TABLE_TENNIS,
  SPORTS.BIATHLON,
  SPORTS.BOXING,
  SPORTS.CYCLING,
  SPORTS.FORMULA_1,
  SPORTS.GOLF,
  SPORTS.MMA,
  SPORTS.CHESS,
  SPORTS.SPEEDWAY,
  SPORTS.SKI_JUMPING,
  SPORTS.SAILING,
  SPORTS.ATHLETICS,
  SPORTS.JUDO,
  SPORTS.EQUESTRIAN,
  SPORTS.WEIGHTLIFTING,
  SPORTS.SUMO,
  SPORTS.GYMNASTICS,
  SPORTS.TAEKWONDO,
  SPORTS.POOL,
  SPORTS.SHOOTING,
  SPORTS.ARCHERY,
  SPORTS.MOTORCYCLING,
  SPORTS.SURFING,
  SPORTS.RALLY,
  SPORTS.NASCAR,
  SPORTS.CANOEING,
  SPORTS.FENCING,
  SPORTS.MODERN_PENTATHLON,
  SPORTS.TRIATHLON,
  SPORTS.WRESTLING,
  SPORTS.INDY_CAR,
  SPORTS.SEPAK_TAKRAW,
];

export const SPORTS_WITH_MAIN_TOTAL_AND_HANDICAP = [
  SPORTS.FOOTBALL,
  SPORTS.ICE_HOCKEY,
  SPORTS.BASKETBALL,
  SPORTS.VOLLEYBALL,
  SPORTS.TENNIS,
  SPORTS.AMERICAN_FOOTBALL,
  SPORTS.BASEBALL,
  SPORTS.HANDBALL,
  SPORTS.AUSSIE_RULES,
  SPORTS.BADMINTON,
  SPORTS.BEACH_VOLLEY,
  SPORTS.DARTS,
  SPORTS.FUTSAL,
  SPORTS.RUGBY_LEAGUE,
  SPORTS.RUGBY,
  SPORTS.SNOOKER,
  SPORTS.SQUASH,
  SPORTS.TABLE_TENNIS,
  SPORTS.BOXING,
  SPORTS.MMA,
  SPORTS.FIELD_HOCKEY,
  SPORTS.WATER_POLO,
  SPORTS.CHESS,
  SPORTS.BEACH_FOOTBALL,
  SPORTS.CURLING,
  SPORTS.FLOORBALL,
  SPORTS.PADEL_TENNIS,
  SPORTS.NETBALL,
  SPORTS.SOFTBALL,
  SPORTS.GAELIC_FOOTBALL,
  SPORTS.E_SPORT,
  SPORTS.BASKETBALL_3X3,
  SPORTS.BEACH_HANDBALL,
  SPORTS.KABADDI,
  SPORTS.FIFA,
  SPORTS.FIFA18,
  SPORTS.NBA2K,
  SPORTS.NBA2K19,
  SPORTS.AO_TENNIS,
  SPORTS.ROCKET_LEAGUE,
  SPORTS.ROCKET_LEAGUE_1,
  SPORTS.DOTA,
  SPORTS.LOL,
  SPORTS.CS,
];

export const SPORTS_WITH_PERIOD_SCORE = [
  SPORTS.FOOTBALL,
  SPORTS.TENNIS,
  SPORTS.ICE_HOCKEY,
  SPORTS.BASKETBALL,
  SPORTS.HANDBALL,
  SPORTS.VOLLEYBALL,
  SPORTS.AMERICAN_FOOTBALL,
  SPORTS.AUSSIE_RULES,
  SPORTS.BADMINTON,
  SPORTS.BEACH_VOLLEY,
  SPORTS.BOWLS,
  SPORTS.FUTSAL,
  SPORTS.RUGBY_LEAGUE,
  SPORTS.RUGBY,
  SPORTS.SQUASH,
  SPORTS.TABLE_TENNIS,
  SPORTS.FIFA,
  SPORTS.FIFA18,
  SPORTS.NBA2K,
  SPORTS.NBA2K19,
  SPORTS.AO_TENNIS,
];

export const SPORTS_WITHOUT_LIVE_STATS = [
  SPORTS.SNOOKER,
  SPORTS.DARTS,
];

export const TOP_SPORTS = [
  SPORTS.FOOTBALL,
  SPORTS.TENNIS,
  SPORTS.BASKETBALL,
  SPORTS.ICE_HOCKEY,
  SPORTS.VOLLEYBALL,
  SPORTS.HANDBALL,
];

export const SPORTS_WITHOUT_LIVE_SCORE = [
  SPORTS.BIATHLON,
  SPORTS.BOXING,
  SPORTS.CYCLING,
  SPORTS.FORMULA_1,
  SPORTS.GOLF,
  SPORTS.MMA,
  SPORTS.SPEEDWAY,
  SPORTS.SKI_JUMPING,
  SPORTS.SAILING,
  SPORTS.ATHLETICS,
  SPORTS.EQUESTRIAN,
  SPORTS.WEIGHTLIFTING,
  SPORTS.GYMNASTICS,
  SPORTS.TAEKWONDO,
  SPORTS.RALLY,
  SPORTS.NASCAR,
  SPORTS.CANOEING,
  SPORTS.TRIATHLON,
  SPORTS.WRESTLING,
  SPORTS.INDY_CAR,
  SPORTS.SEPAK_TAKRAW,
  SPORTS.LOTTERY,
  SPORTS.SPECIALS,
  SPORTS.OLYMPIC_GAMES,
];

export const ALL_INPLAY_SPORTS = 'allInplaySports';

export const MODAL_IDS = {
  SPORTS: 'sports-modal',
  LEAGUES: 'leagues-modal',
  BET_HISTORY: 'bets-history-modal',
  ADD_ACCUMULATOR: 'add-accumulator-modal',
  RESULTS_DATE: 'results-date-modal',
  RESULTS_SPORTS: 'results-sports-modal',
  RESULTS_LEAGUES: 'results-leagues-modal',
  FREEBETS: 'freebets-modal',
  BETS_FEED: 'bets-feed-modal',
  BETS_FEED_SPORTS: 'bets-feed-modal-sports',
};

export const CURRENCY_SYMBOLS = {
  EUR: '€',
  USD: '$',
  TRY: '₺',
};

export const PAGE_NAMES = {
  HOME: 'home',
  SPORT: 'sport',
  LEAGUE: 'league',
  LIVE: 'live',
  EVENT: 'event',
  SEARCH: 'search',
  WATCHLIST: 'watchlist',
  MY_BETS: 'myBets',
  SPORTS: 'sports',
  BETS_FEED: 'bets-feed',
};

export const TODAY = 'today';
export const TOMORROW = 'tomorrow';

export const LIVE_WIDGET_STUBS = {
  NO_STREAM: 'NO_STREAM',
  NO_STATS: 'NO_STATS',
};

export const LIVE_WIDGET_EVENT_TABS = {
  LIVE_STREAM: 'LIVE_STREAM',
  LIVE_STATS: 'LIVE_STATS',
  INFO: 'INFO',
};

export const SCHEDULE_TIME_FILTERS = {
  ALL_DAY: 'all',
  NIGHT: 'night',
  MORNING: 'morning',
  DAY: 'day',
  EVENING: 'evening',
};

export const FREEBET_TYPES = {
  BET_REFUND: 'bet_refund',
};

export const EVENT_STATUSES_WITH_TIME = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  13,
  14,
  15,
  16,
  21,
  40,
  41,
  42,
];
