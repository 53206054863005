import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormattedMessage } from 'react-intl';
import {
  withLocale,
  withCoreComponent,
  withModalActions,
  withFeedbackActions,
} from 'core/hocs';
import { BetSlip as BetSlipCore } from 'core/components';
import { isEmptyOrNil, prepareStakesForRepeat } from 'core/helpers';
import { BET_TYPE, BET_SLIP_STATE, BET_SLIP_ERRORS } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { EmptyState } from 'components/ticket/empty-state/empty-state';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { UnsupportedCurrencyState } from 'components/ticket/unsupported-currency-state/unsupported-currency-state';
import { IconCloseRounded } from 'components/icons/icon-close-rounded/icon-close-rounded';
import { BetSlipHeader } from 'components/bet-slip/bet-slip-header/bet-slip-header';
import { BetSlipNotification } from 'components/bet-slip/bet-slip-notification/bet-slip-notification';
import { isInvalidStake } from 'helpers/validate';
import { GA } from 'helpers/ga';
import { StakeItem } from './stake-item/stake-item';
import { BetSlipFooter } from './bet-slip-footer/bet-slip-footer';
import { BetSlipSuccess } from './bet-slip-success/bet-slip-success';

import './ticket-mobile.scss';

class TicketMobileUI extends Component {
  static propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    isCurrencySupported: PropTypes.bool.isRequired,
    stakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    clearBetSlip: PropTypes.func.isRequired,
    setBetType: PropTypes.func.isRequired,
    totalBetFactor: PropTypes.number,
    totalReturnAmount: PropTypes.number,
    systemVariants: PropTypes.number,
    setBetAmount: PropTypes.func.isRequired,
    totalBetAmount: PropTypes.number,
    isSystemInfoInProgress: PropTypes.bool.isRequired,
    systemIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    acceptChanges: PropTypes.func.isRequired,
    setIsOpened: PropTypes.func.isRequired,
    isOpened: PropTypes.bool.isRequired,
    placeBet: PropTypes.func.isRequired,
    isPlaceBetInProgress: PropTypes.bool.isRequired,
    error: PropTypes.shape(),
    bonuses: PropTypes.arrayOf(PropTypes.shape({})),
    isBonusConditionsReached: PropTypes.bool.isRequired,
    bonusAmount: PropTypes.number,
    setBonusType: PropTypes.func.isRequired,
    betFactorChanges: PropTypes.bool.isRequired,
    setBetFactorChanges: PropTypes.func.isRequired,
    isBackdropShowed: PropTypes.bool.isRequired,
    toggleStake: PropTypes.func.isRequired,
    clearOutOfBonusBalanceError: PropTypes.func.isRequired,
    bonusValue: PropTypes.number,
    isPartialPlaced: PropTypes.bool.isRequired,
    availableFreebetId: PropTypes.string,
    setUsedFreebet: PropTypes.func.isRequired,
    isFreebetUsed: PropTypes.bool.isRequired,
    systemBetAmount: PropTypes.number,
    repeatBet: PropTypes.func.isRequired,
    lastBet: PropTypes.shape().isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    totalBetFactor: null,
    totalReturnAmount: null,
    totalBetAmount: null,
    systemVariants: null,
    systemIndex: null,
    error: null,
    bonuses: null,
    bonusAmount: null,
    bonusValue: null,
    availableFreebetId: null,
    systemBetAmount: null,
  };

  state = {
    isKeyboardShowed: false,
    keyboardValue: '',
    isTouchXDisabled: false,
    isTouchYDisabled: false,
    isPlaceBetDisabled: false,
  };

  onChangeSubject;

  onChangeSubsription;

  resizeSubsription;

  scrollableArea = createRef();

  ticketMobile = createRef();

  prevClientX;

  prevClientY;

  timeoutId;

  componentDidMount() {
    this.onChangeSubject = new Subject();
    this.onChangeSubsription = this.onChangeSubject
      .pipe(debounceTime(100))
      .subscribe(this.onKeyboardChange);
    this.resizeSubsription = fromEvent(window, 'resize')
      .pipe(debounceTime(200))
      .subscribe(() => {
        const { isOpened, isBackdropShowed } = this.props;

        if (isOpened && !isBackdropShowed) {
          this.setTicketMaxHeight();
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      betSlipState,
      stakes,
      setIsOpened,
      isPlaceBetInProgress,
      error,
      isOpened,
      isBackdropShowed,
      totalBetAmount,
      betType,
    } = this.props;
    const {
      betSlipState: prevBetSlipState,
      stakes: prevStakes,
      isPlaceBetInProgress: prevIsPlaceBetInProgress,
      isOpened: prevIsOpened,
      error: prevError,
      totalBetAmount: prevTotalBetAmount,
      betType: prevBetType,
    } = prevProps;
    const { isKeyboardShowed, keyboardValue } = this.state;
    const { isKeyboardShowed: prevIsKeyboardShowed, keyboardValue: prevKeyboardValue } = prevState;
    const ticketMobile = this.ticketMobile.current;
    const isErrorState = betSlipState === BET_SLIP_STATE.ERROR;
    const scrollableArea = this.scrollableArea.current;
    const centralPart = document.querySelector('.central-part');
    const isOrdinarError = betType === BET_TYPE.ORDINAR && R.find(R.prop('error'), stakes);
    const prevIsOrdinarError = prevBetType === BET_TYPE.ORDINAR && R.find(R.prop('error'), prevStakes);

    if (prevStakes.length !== stakes.length) {
      const isQuickBet = prevStakes.length === 1 && stakes.length === 2;

      if (!stakes.length) {
        this.setKeyboardValue('');
      } else if (totalBetAmount) {
        this.setKeyboardValue(totalBetAmount);
      }

      if (!prevStakes.length) {
        setIsOpened(true);
        this.setTicketMaxHeight();
      } else if ((!stakes.length || isQuickBet) && !R.find(R.prop('isRepeatBet'), stakes)) {
        setIsOpened(false);
      }

      if (isOpened && !isQuickBet) {
        this.setTicketMaxHeight();
      }
    }

    if (isKeyboardShowed) {
      if (prevBetSlipState !== betSlipState && betSlipState !== BET_SLIP_STATE.DEFAULT) {
        this.setIsKeyboardShowed(false);
      }

      if (!prevIsKeyboardShowed && scrollableArea) {
        scrollableArea.scrollTop = scrollableArea.scrollHeight;
        const input = document.querySelector('.bet-amount-input-mobile');

        if (input) {
          // 57 = 49(.ticket-mobile-bet-slip-title) + 8(margin)
          if (input.getBoundingClientRect().bottom < 57) {
            scrollableArea.scrollTop = input.offsetTop - 8; // 8 - margin
          }
        }
      }
    }

    if (error && prevIsPlaceBetInProgress && !isPlaceBetInProgress && isErrorState) {
      const value = error.limit || error.amount;

      if (value) {
        this.setKeyboardValueWithoutStoreUpdate(String(value));
      }
    }

    if ((prevBetSlipState === BET_SLIP_STATE.ERROR && prevError
    && prevError.errorId === BET_SLIP_ERRORS.BET_AMOUNT_OUT_OF_BONUS_BALANCE && !isErrorState)
    || (prevTotalBetAmount && !totalBetAmount && prevKeyboardValue && keyboardValue)) {
      this.setKeyboardValueWithoutStoreUpdate('');
    }

    if (totalBetAmount !== prevTotalBetAmount) {
      const value = totalBetAmount === null || totalBetAmount === 0 ? '' : String(totalBetAmount);
      this.setKeyboardValueWithoutStoreUpdate(value);
    }

    if (
      isOpened && !isBackdropShowed
      && (prevBetSlipState !== betSlipState || prevIsKeyboardShowed !== isKeyboardShowed
      || isOrdinarError !== prevIsOrdinarError)
    ) {
      this.setTicketMaxHeight();
    }

    if (prevIsOpened && !isOpened) {
      this.clearTimeoutId();
      this.timeoutId = setTimeout(() => {
        ticketMobile.style.removeProperty('max-height');
      }, 300); // 300ms - close animation
    }

    if (isKeyboardShowed && !prevIsKeyboardShowed) {
      ticketMobile.style.removeProperty('max-height');
    }

    if (!prevIsOpened && isOpened && scrollableArea) {
      scrollableArea.scrollTop = scrollableArea.scrollHeight;
    }

    if (isOpened !== prevIsOpened) {
      if (isOpened) {
        document.addEventListener('click', this.checkOutsideClick);

        if (centralPart) {
          centralPart.addEventListener('scroll', this.onScrollUnderBetSlip);
        }
      } else {
        document.removeEventListener('click', this.checkOutsideClick);

        if (centralPart) {
          centralPart.removeEventListener('scroll', this.onScrollUnderBetSlip);
        }
      }
    }

    if (betSlipState === BET_SLIP_STATE.SUCCESS && prevBetSlipState !== BET_SLIP_STATE.SUCCESS) {
      GA.event({
        category: 'bet-slip',
        label: 'bet-accepted',
      });
    }
  }

  componentWillUnmount() {
    const { isOpened, setIsOpened } = this.props;
    const centralPart = document.querySelector('.central-part');

    if (isOpened) {
      setIsOpened(false);
    }

    if (this.onChangeSubsription) {
      this.onChangeSubsription.unsubscribe();
    }

    if (this.resizeSubsription) {
      this.resizeSubsription.unsubscribe();
    }

    this.clearTimeoutId();
    document.removeEventListener('click', this.checkOutsideClick);

    if (centralPart) {
      centralPart.removeEventListener('scroll', this.onScrollUnderBetSlip);
    }
  }

  setKeyboardValueWithoutStoreUpdate = keyboardValue => this.setState({ keyboardValue });

  setKeyboardValue = (keyboardValue) => {
    const { isPlaceBetInProgress } = this.props;

    if (isInvalidStake(keyboardValue) || isPlaceBetInProgress) {
      return;
    }

    this.setState({ keyboardValue });
    this.onChangeSubject.next(keyboardValue);
  }

  onKeyboardChange = (value) => {
    const { setBetAmount } = this.props;

    setBetAmount({ stakeId: null, betAmount: Number(value) });
  };

  setIsKeyboardShowed = isKeyboardShowed => this.setState({ isKeyboardShowed });

  onMyBetsClick = () => {
    const { setIsOpened } = this.props;
    setIsOpened(false);
    GA.event({
      category: 'bet-slip',
      label: 'my-bets-click',
    });
  };

  onTouchStart = (e) => {
    document.body.classList.add('scroll-disabled');

    if (e.targetTouches.length === 1) {
      this.prevClientX = e.targetTouches[0].clientX;
      this.prevClientY = e.targetTouches[0].clientY;
    }
  };

  onTouchMove = (e) => {
    const { isTouchXDisabled, isTouchYDisabled } = this.state;

    if (e.targetTouches.length === 1) {
      const deltaX = Math.abs(e.targetTouches[0].clientX - this.prevClientX);
      const deltaY = Math.abs(e.targetTouches[0].clientY - this.prevClientY);

      if ((deltaX > 10 || deltaY > 10) && !isTouchXDisabled && !isTouchYDisabled) {
        this.setState({
          isTouchXDisabled: deltaX === deltaY || deltaY > deltaX,
          isTouchYDisabled: deltaX > deltaY,
        });
      }
    }
  };

  onTouchEnd = () => {
    const { isBackdropShowed } = this.props;

    this.setState({
      isTouchXDisabled: false,
      isTouchYDisabled: false,
    }, () => {
      if (!isBackdropShowed) {
        document.body.classList.remove('scroll-disabled');
      }
    });
  };

  setTicketMaxHeight = () => {
    const ticketMobile = this.ticketMobile.current;

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    if (ticketMobile) {
      ticketMobile.style.removeProperty('max-height');
      ticketMobile.style.maxHeight = `${ticketMobile.getBoundingClientRect().height}px`;
    }
  };

  clearTimeoutId = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  };

  closeTicket = () => {
    const { setIsOpened } = this.props;
    setIsOpened(false);
  };

  checkOutsideClick = (e) => {
    const path = e.path || (e.composedPath && e.composedPath());

    const isClickOnTicket = R.compose(
      R.contains(true),
      R.map(DOMTokenList => DOMTokenList.contains('ticket-mobile')),
      R.reject(DOMTokenList => !DOMTokenList),
      R.pluck('classList')
    )(path);

    if (!isClickOnTicket) {
      this.closeTicket();
    }
  };

  onScrollUnderBetSlip = () => {
    this.closeTicket();
  };

  repeatBet = () => {
    const { repeatBet, lastBet } = this.props;
    repeatBet({
      sum: lastBet.sum,
      type: lastBet.type,
      selections: prepareStakesForRepeat(lastBet.selections),
    });

    GA.event({
      category: 'bet-slip',
      label: 'repeat-last-bet-click',
    });
  };

  setIsPlaceBetDisabled = isPlaceBetDisabled => this.setState({ isPlaceBetDisabled });

  render() {
    const {
      isUserLoggedIn,
      locale,
      currency,
      isCurrencySupported,
      stakes,
      betType,
      clearBetSlip,
      setBetType,
      totalBetFactor,
      totalReturnAmount,
      systemVariants,
      setBetAmount,
      totalBetAmount,
      isSystemInfoInProgress,
      systemIndex,
      betSlipState,
      acceptChanges,
      isOpened,
      placeBet,
      isPlaceBetInProgress,
      error,
      bonuses,
      isBonusConditionsReached,
      bonusAmount,
      setBonusType,
      betFactorChanges,
      setBetFactorChanges,
      toggleStake,
      setIsOpened,
      isBackdropShowed,
      clearOutOfBonusBalanceError,
      bonusValue,
      isPartialPlaced,
      availableFreebetId,
      setUsedFreebet,
      isFreebetUsed,
      systemBetAmount,
      repeatBet,
      lastBet,
      sendEventClickFeedback,
    } = this.props;
    const {
      isKeyboardShowed,
      keyboardValue,
      isTouchXDisabled,
      isTouchYDisabled,
      isPlaceBetDisabled,
    } = this.state;
    const isSuspended = stakes.length === 1 && betSlipState === BET_SLIP_STATE.SUSPENDED;
    const isErrorState = betSlipState === BET_SLIP_STATE.ERROR;
    const isNotificationShowed = isErrorState && error && error.errorId === BET_SLIP_ERRORS.STAKES_AMOUNT_EXCEED;

    return (
      <Fragment>
        <div
          ref={this.ticketMobile}
          onTouchStart={this.onTouchStart}
          onTouchMove={this.onTouchMove}
          onTouchEnd={this.onTouchEnd}
          id="ticket-mobile" // used for portal
          className={classNames('ticket-mobile position-fixed d-lg-none mt-3_5', {
            'is-opened': isOpened,
            'is-suspended': isSuspended,
            'is-keyboard-showed': isKeyboardShowed,
            'is-touch-y-disabled': isTouchYDisabled,
            'is-backdrop-showed': isBackdropShowed,
          })}
        >
          <div className="ticket-mobile-bet-slip-title d-flex align-items-center justify-content-between px-1_5 bg-main-5">
            <FormattedTag id="ticket.tab1" className="font-weight-bold" />
            <div
              role="button"
              tabIndex="0"
              onClick={this.closeTicket}
              onKeyPress={this.closeTicket}
            >
              <IconCloseRounded />
            </div>
          </div>

          {isCurrencySupported
            ? (
              <Fragment>
                {stakes.length
                  ? (
                    <Fragment>
                      {betSlipState === BET_SLIP_STATE.SUCCESS
                        ? (
                          <div ref={this.scrollableArea} className="ticket-mobile-scrollable-area position-relative">
                            <BetSlipSuccess
                              currency={currency}
                              betType={betType}
                              totalBetAmount={totalBetAmount}
                              totalBetFactor={totalBetFactor}
                              systemIndex={systemIndex}
                              systemVariants={systemVariants}
                              totalReturnAmount={totalReturnAmount}
                              stakes={stakes}
                              clearBetSlip={clearBetSlip}
                              setKeyboardValue={this.setKeyboardValue}
                              bonusValue={bonusValue}
                              isFreebetUsed={isFreebetUsed}
                              systemBetAmount={systemBetAmount}
                              locale={locale}
                              closeTicket={this.closeTicket}
                              repeatBet={repeatBet}
                              sendEventClickFeedback={sendEventClickFeedback}
                            />
                          </div>
                        )
                        : (
                          <Fragment>
                            <div ref={this.scrollableArea} className="ticket-mobile-scrollable-area position-relative">
                              {stakes.length > 1 && (
                                <BetSlipHeader
                                  isMobile
                                  betType={betType}
                                  clearBetSlip={clearBetSlip}
                                  setBetType={setBetType}
                                  stakesAmount={stakes.length}
                                  systemIndex={systemIndex}
                                  setIsOpened={setIsOpened}
                                  isPartialPlaced={isPartialPlaced}
                                />
                              )}

                              <div>
                                {stakes.map((stake, idx) => (
                                  <StakeItem
                                    key={stake.stakeId}
                                    stakeIdx={idx}
                                    stake={stake}
                                    stakesAmount={stakes.length}
                                    toggleStake={toggleStake}
                                    betType={betType}
                                    betSlipState={betSlipState}
                                    isTouchXDisabled={isTouchXDisabled}
                                    setIsOpened={setIsOpened}
                                    currency={currency}
                                    setBetAmount={setBetAmount}
                                    isPlaceBetInProgress={isPlaceBetInProgress}
                                    setIsKeyboardShowed={this.setIsKeyboardShowed}
                                    availableFreebetId={availableFreebetId}
                                    setUsedFreebet={setUsedFreebet}
                                    isFreebetUsed={isFreebetUsed}
                                    error={error}
                                    locale={locale}
                                    setIsPlaceBetDisabled={this.setIsPlaceBetDisabled}
                                    sendEventClickFeedback={sendEventClickFeedback}
                                  />
                                ))}
                              </div>

                              <BetSlipFooter
                                currency={currency}
                                betType={betType}
                                totalBetFactor={totalBetFactor}
                                totalReturnAmount={totalReturnAmount}
                                systemVariants={systemVariants}
                                setBetAmount={setBetAmount}
                                totalBetAmount={totalBetAmount}
                                isSystemInfoInProgress={isSystemInfoInProgress}
                                betSlipState={betSlipState}
                                acceptChanges={acceptChanges}
                                stakes={stakes}
                                keyboardValue={keyboardValue}
                                isKeyboardShowed={isKeyboardShowed}
                                setIsKeyboardShowed={this.setIsKeyboardShowed}
                                placeBet={placeBet}
                                isPlaceBetInProgress={isPlaceBetInProgress}
                                error={error}
                                bonuses={bonuses}
                                isBonusConditionsReached={isBonusConditionsReached}
                                bonusAmount={bonusAmount}
                                setBonusType={setBonusType}
                                betFactorChanges={betFactorChanges}
                                setBetFactorChanges={setBetFactorChanges}
                                setTicketMaxHeight={this.setTicketMaxHeight}
                                clearOutOfBonusBalanceError={clearOutOfBonusBalanceError}
                                isFreebetUsed={isFreebetUsed}
                                systemBetAmount={systemBetAmount}
                                isPlaceBetDisabled={isPlaceBetDisabled}
                                setIsPlaceBetDisabled={this.setIsPlaceBetDisabled}
                              />
                            </div>

                            {isSuspended && (
                              <FormattedTag
                                id="bet-slip.suspended"
                                className="ticket-mobile-suspended-label h6 d-flex align-items-center position-absolute bg-info"
                              />
                            )}
                          </Fragment>
                        )}
                    </Fragment>
                  )
                  : (
                    <>
                      {isUserLoggedIn && (lastBet.isInProgress || !isEmptyOrNil(lastBet.selections)) && (
                        <div className="ticket-mobile-repeat-bet p-1_5 bg-main-2">
                          <ButtonWithLoader
                            block
                            size="sm"
                            outline
                            isWhite
                            isLoading={lastBet.isInProgress}
                            onClick={this.repeatBet}
                          >
                            <FormattedMessage id="bet-slip.repeat-last-bet" />
                          </ButtonWithLoader>
                        </div>
                      )}
                      <EmptyState />
                    </>
                  )}
              </Fragment>
            )
            : (
              <UnsupportedCurrencyState />
            )}
        </div>

        <BetSlipNotification isPortal betType={betType} isShowed={isNotificationShowed} />
      </Fragment>
    );
  }
}

export const TicketMobile = withFeedbackActions(
  withLocale(withCoreComponent(BetSlipCore, withModalActions(TicketMobileUI)))
);
