import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const CLASSES = {
  primary: {
    INCREASE: 'rank-increase',
    DECREASE: 'rank-decrease',
  },
  secondary: {
    INCREASE: 'rank-increase-secondary',
    DECREASE: 'rank-decrease-secondary',
  }
};

const ARROW_VISIBILITY_TIME = 5600;

export class OddsWithRank extends PureComponent {
  static propTypes = {
    odds: PropTypes.number,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf([
      'primary',
      'secondary',
    ]),
  };

  static defaultProps = {
    odds: null,
    className: null,
    type: 'primary',
  };

  oddsRef = React.createRef();

  timeout = null;

  componentDidUpdate(prevProps) {
    const domNode = this.oddsRef.current;
    const { odds, type } = this.props;

    if (!domNode || !odds) {
      return;
    }

    if (prevProps.odds !== odds && this.timeout) {
      clearTimeout(this.timeout);
    }

    if (prevProps.odds) {
      const rank = Math.sign(odds - prevProps.odds);

      if (rank !== 0) {
        this.removeClasses();
        domNode.classList.add(rank > 0 ? CLASSES[type].INCREASE : CLASSES[type].DECREASE);
        domNode.closest('.outcome-wrapper').classList.add(rank > 0 ? CLASSES[type].INCREASE : CLASSES[type].DECREASE);

        this.timeout = setTimeout(this.removeClasses, ARROW_VISIBILITY_TIME);
      }
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  removeClasses = () => {
    const { type } = this.props;
    const domNode = this.oddsRef.current;
    domNode.classList.remove(CLASSES[type].INCREASE, CLASSES[type].DECREASE);
    domNode.closest('.outcome-wrapper').classList.remove(CLASSES[type].INCREASE, CLASSES[type].DECREASE);
    // eslint-disable-next-line
    void domNode.offsetWidth;
    // eslint-disable-next-line
    void domNode.closest('.outcome-wrapper').offsetWidth;
  }

  render() {
    const { children, odds, ...otherProps } = this.props;

    return (
      <div ref={this.oddsRef} {...otherProps}>
        {children}
      </div>
    );
  }
}
