import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportWarcraft = ({
  className, isActive, width, height,
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 6H7C6.92968 6.2778 6.78312 6.57875 6.78312 6.8797C6.90155 8.01736 7.74167 11.1658 7.74167 11.1658L9.10733 6.25796L10.473 11.0599C10.473 11.0599 11.3131 6.83671 11.3131 6.55891C11.2909 6.34394 11.1925 6.12898 11 6H14C13.5929 6.34394 13.3042 6.77387 13.1377 7.24349C12.8749 8.16618 11.9386 12.859 11.9386 13.16C11.9608 13.4609 12.0829 13.7586 12.2976 13.9967H9.49223C9.56255 13.7817 9.58845 13.5469 9.58845 13.3121C9.58845 12.8822 9.13323 10.8483 9.13323 10.8483C9.13323 10.8483 8.55588 12.9483 8.55588 13.3353C8.55588 13.5701 8.6262 13.785 8.74833 14H5.82087C5.82087 14 6.37232 13.6561 6.35011 13.3154C6.27979 12.3497 4.79201 7.37908 4.66987 6.99215C4.59956 6.6019 4.35899 6.23481 4 6Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M4.04344 15.8364C4.04344 15.7251 4.04344 12.7861 4.04344 10.9551C4.04344 10.9551 4.04344 10.2877 4.04344 9.29091C3.71534 8.04171 3.3549 6.82246 3.17468 6.10374C3.05453 5.63316 2.96673 5.35508 2.93438 5.2738V5.24813C2.87431 4.88877 2.63401 4.58075 2.33364 4.36257C2.09335 4.22567 2.00554 3.94759 2.09335 3.69519C2.18115 3.44706 2.42144 3.28021 2.72181 3.28021H4.07116C4.07116 2.64278 4.07116 2.22781 4.07116 2.17219C4.07116 1.92406 4.73198 1.34225 5 1.1754V1H4.93993H2.66174H2.63401H2.51386H2.39371H2.36599H0.0878003H0V1.16684C0.240296 1.33369 0.928835 1.91551 0.928835 2.16364C0.928835 2.27487 0.928835 3.93904 0.928835 5.77005C0.928835 5.77005 0.928835 15.5583 0.928835 15.8364C0.928835 16.0845 0.268022 16.6663 0 16.8332V17H0.0600739H2.33826H2.36599H2.48614H2.60628H2.63401H4.9122H4.97227V16.8332C4.69963 16.6663 4.04344 16.0845 4.04344 15.8364Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M11.0056 16.0669C11.0056 16.0223 11.0056 15.4426 11.0056 15H10.0716C9.89033 15 9.74164 14.8518 9.62082 14.7626C9.5 14.8518 9.31877 15 9.17007 15H7.93402C7.93402 15.4666 7.93402 16.0223 7.93402 16.0669C7.93402 16.2659 7.26952 16.7324 7 16.8662V17H7.06041H9.3513H9.37918H9.5H9.62082H9.6487H11.9396H12V16.8662C11.698 16.7324 11.0056 16.2659 11.0056 16.0669Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M7.44508 2.16364C7.44508 2.27487 7.44508 3.93904 7.44508 5.77005C7.44508 5.77005 7.44508 6.65561 7.44508 7.90481L8.44951 4.05027C8.50887 3.77219 8.77367 3.60535 9.07043 3.60535C9.3672 3.60535 9.60461 3.79786 9.69136 4.05027L10.6958 7.90481C10.6958 5.65882 10.4903 2.30481 10.4903 2.16364C10.4903 1.91551 11.1432 1.33369 11.408 1.16684V1H11.3487H9.09783H9.075H8.95629H8.83759H8.8102H6.55935H6.5V1.16684C6.79676 1.35936 7.44508 1.94118 7.44508 2.16364Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M17.026 10.9551C17.026 10.9551 17.026 2.44171 17.026 2.16364C17.026 1.91551 17.6892 1.33369 17.9583 1.16684V1H17.898H15.6113H15.5835H15.4629H15.3748H15.3469H13.0603H13V1.16684C13.2412 1.33369 13.9323 1.91551 13.9323 2.16364C13.9323 2.21925 13.9323 2.63422 13.9323 3.27166H15.2866C15.5557 3.27166 15.7968 3.40856 15.8896 3.66096C15.9777 3.88342 15.9174 4.1615 15.7412 4.32834C15.3191 4.6877 15.0176 5.13262 14.8692 5.63316C14.6883 6.30054 14.2987 8.21283 13.9694 9.95829C13.9694 12.6492 13.9694 15.6695 13.9694 15.8364C13.9694 16.0845 13.3061 16.6663 13.0371 16.8332V17H13.0974H15.384H15.5046H15.6252H15.6531H17.9397H18V16.8332C17.7588 16.6663 17.0677 16.0845 17.0677 15.8364C17.026 15.7251 17.026 12.7861 17.026 10.9551Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
  </svg>
);

IconSportWarcraft.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportWarcraft.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
