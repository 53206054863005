import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './spinner.scss';

export const Spinner = ({
  isSmall,
  isWhite,
  isBlack,
  className,
}) => (
  <div className={classNames('custom-spinner', className, {
    small: isSmall,
    white: isWhite,
    black: isBlack,
  })}
  >
    <div />
    <div />
  </div>
);

Spinner.propTypes = {
  isSmall: PropTypes.bool,
  isWhite: PropTypes.bool,
  isBlack: PropTypes.bool,
  className: PropTypes.string,
};

Spinner.defaultProps = {
  isSmall: false,
  isWhite: false,
  isBlack: false,
  className: null,
};
