import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import { BET_SLIP_MAX_SELECTIONS, EXCLUDED_SYSTEMS, COMPLEX_SYSTEMS } from 'core/constants';
import { checkIsComplexSystem } from 'core/helpers';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconArrowBottom } from 'components/icons/icon-arrow-bottom/icon-arrow-bottom';
import { GA } from 'helpers/ga';

import './bet-type-dropdown.scss';

const ORDINAR_ITEM = 'ORDINAR_ITEM';
const EXPRESS_ITEM = 'EXPRESS_ITEM';

export class BetTypeDropdown extends Component {
  static propTypes = {
    stakesAmount: PropTypes.number.isRequired,
    selectedItem: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onOrdinarClick: PropTypes.func.isRequired,
    onExpressClick: PropTypes.func.isRequired,
    onSystemClick: PropTypes.func.isRequired,
  };

  state = {
    isOpen: false,
  };

  toggle = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  prepareSystemIndexes = () => {
    const { stakesAmount } = this.props;
    const systemIndexes = [];

    if (stakesAmount > BET_SLIP_MAX_SELECTIONS) {
      return systemIndexes;
    }

    for (let i = 2; i < stakesAmount; i += 1) {
      const excludedValues = EXCLUDED_SYSTEMS[stakesAmount];
      const isValueExcluded = excludedValues && excludedValues.includes(i);

      if (!isValueExcluded) {
        systemIndexes.push(i);
      }
    }

    if (COMPLEX_SYSTEMS[stakesAmount]) {
      systemIndexes.push(...COMPLEX_SYSTEMS[stakesAmount]);
    }

    return systemIndexes;
  };

  onDropdownClick = () => {
    GA.event({
      category: 'bet-slip',
      label: 'bet-type-dropdown-click',
    });
  };

  render() {
    const {
      stakesAmount,
      selectedItem,
      onOrdinarClick,
      onExpressClick,
      onSystemClick,
    } = this.props;
    const { isOpen } = this.state;
    let betTypeIntl = 'system';

    if (selectedItem === ORDINAR_ITEM) {
      betTypeIntl = 'ordinar';
    } else if (selectedItem === EXPRESS_ITEM) {
      betTypeIntl = 'express';
    } else if (checkIsComplexSystem(selectedItem)) {
      betTypeIntl = `complex.${selectedItem}`;
    }

    return (
      <div className="bet-type-dropdown">
        <Dropdown isOpen={isOpen} toggle={this.toggle} onClick={this.onDropdownClick}>
          <DropdownToggle className="bet-type-dropdown-toggle caption btn-outline-secondary pl-1_5 pr-1">
            <FormattedMessage
              id={`bet-slip.type.${betTypeIntl}`}
              values={selectedItem === ORDINAR_ITEM || selectedItem === EXPRESS_ITEM
                ? {}
                : { systemIndex: selectedItem, stakesAmount }}
            />
            <IconArrowBottom className="ml-1" color="white" opacity={0.65} backgroundColor="transparent" />
          </DropdownToggle>
          <DropdownMenu>
            <FormattedTag
              id="bet-slip.type.ordinar"
              tag={DropdownItem}
              onClick={onOrdinarClick}
              className="text-small"
            />
            <FormattedTag
              id="bet-slip.type.express"
              tag={DropdownItem}
              onClick={onExpressClick}
              className="text-small"
            />
            {this.prepareSystemIndexes().map((idx) => {
              const systemId = checkIsComplexSystem(idx) ? `bet-slip.type.complex.${idx}` : 'bet-slip.type.system';

              return (
                <FormattedTag
                  key={idx}
                  id={systemId}
                  values={{ systemIndex: idx, stakesAmount }}
                  tag={DropdownItem}
                  onClick={onSystemClick}
                  data-system-index={idx}
                  className="text-small"
                />
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
