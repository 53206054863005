import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconStar } from 'components/icons/icon-star/icon-star';
import { IconApproved } from 'components/icons/icon-approved/icon-approved';
import { formatAmountWithCurrencySymbol } from 'helpers/currency';
import colors from '../../customizations/js/color-variables';

import './bonuses.scss';

const prepareBettingRules = (multipliers) => {
  const arr = [];
  Object.entries(multipliers).forEach(([oddsCount, oddFactor]) => {
    const [oddCount, oddNextCount] = oddsCount.split('-');
    arr.push({ oddCount: Number(oddCount), oddNextCount: Number(oddNextCount) + 1, oddFactor: Number(oddFactor) });
  });

  return arr;
};

export const getReachedStakesAmount = (minOdd, stakes) => R.compose(
  R.length,
  R.filter(({ betFactor }) => betFactor >= minOdd),
  R.reject(({ isSuspended }) => isSuspended)
)(stakes);

export class Bonuses extends Component {
  static propTypes = {
    currency: PropTypes.string.isRequired,
    bonuses: PropTypes.arrayOf(PropTypes.shape()),
    stakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isBonusConditionsReached: PropTypes.bool.isRequired,
    bonusAmount: PropTypes.number,
    setTicketMaxHeight: PropTypes.func,
  };

  static defaultProps = {
    bonuses: null,
    bonusAmount: null,
    setTicketMaxHeight: null,
  };

  timeoutId;

  state = {
    currentIdx: null,
    nextIdx: null,
  };

  componentDidMount() {
    const { stakes } = this.props;

    this.setState({
      currentIdx: this.getCurrentIdx(stakes),
      nextIdx: this.getNextIdx(stakes),
    });
  }

  componentDidUpdate(prevProps) {
    const { stakes, setTicketMaxHeight } = this.props;
    const { stakes: prevStakes } = prevProps;
    const currentIdx = this.getCurrentIdx(stakes);
    const prevCurrentIdx = this.getCurrentIdx(prevStakes);
    const nextIdx = this.getNextIdx(stakes);
    const prevNextIdx = this.getNextIdx(prevStakes);

    if (currentIdx !== prevCurrentIdx || nextIdx !== prevNextIdx) {
      this.setIdxs(currentIdx, nextIdx);
    }

    if (setTicketMaxHeight) {
      setTicketMaxHeight();
    }
  }

  setIdxs = (newCurrentIdx, newNextIdx) => {
    const { currentIdx } = this.state;

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    if ((currentIdx === null && newCurrentIdx !== null)
    || (currentIdx !== null && newCurrentIdx !== null && newCurrentIdx > currentIdx)) {
      this.timeoutId = setTimeout(() => {
        this.setState({ currentIdx: newCurrentIdx, nextIdx: newNextIdx });
      }, 500);
    } else {
      this.setState({ currentIdx: newCurrentIdx, nextIdx: newNextIdx });
    }
  };

  getCurrentIdx = (stakes) => {
    const { bonuses } = this.props;
    const {
      comboboost_data: {
        min_odd: minOdd,
        multipliers,
      } = {},
    } = bonuses[0];
    const bettingRules = prepareBettingRules(multipliers);
    const reachedStakesAmount = getReachedStakesAmount(minOdd, stakes);

    if (reachedStakesAmount >= bettingRules[bettingRules.length - 1].oddCount) {
      // last
      return bettingRules.length - 1;
    }

    const foundIdx = R.compose(
      R.findIndex(({ oddCount }) => reachedStakesAmount >= oddCount),
      R.reverse,
    )(bettingRules);

    if (foundIdx !== -1) {
      return bettingRules.length - foundIdx - 1;
    }

    return null;
  };

  getNextIdx = (stakes) => {
    const { bonuses } = this.props;
    const {
      comboboost_data: {
        multipliers,
      } = {},
    } = bonuses[0];
    const bettingRules = prepareBettingRules(multipliers);
    const currentIdx = this.getCurrentIdx(stakes);
    const nextIdx = currentIdx + 1;

    if (currentIdx === null) {
      return 0;
    }

    if (nextIdx >= bettingRules.length) {
      return null;
    }

    return nextIdx;
  };

  getBonusRules = () => {
    const { bonuses, isBonusConditionsReached, stakes } = this.props;
    const { currentIdx, nextIdx } = this.state;
    const {
      comboboost_data: {
        min_odd: minOdd,
        multipliers,
      } = {},
    } = bonuses[0];
    const bettingRules = prepareBettingRules(multipliers);
    const reachedStakesAmount = getReachedStakesAmount(minOdd, stakes);

    if (!isBonusConditionsReached || currentIdx === null) {
      const idx = currentIdx === null ? 0 : currentIdx;
      const oddsDiff = bettingRules[idx].oddCount - reachedStakesAmount;

      return {
        isFirst: true,
        isLast: false,
        oddsDiff: oddsDiff >= 0 ? oddsDiff : 0,
        bonusType1: bettingRules[idx].oddFactor,
        bonusLevel: currentIdx === null ? 0 : currentIdx + 1,
      };
    }

    if (nextIdx === null) {
      return {
        isFirst: false,
        isLast: true,
        oddsDiff: 0,
        bonusType1: bettingRules[currentIdx].oddFactor,
        bonusLevel: bettingRules.length,
      };
    }

    const currentOddsDiff = bettingRules[nextIdx].oddCount - reachedStakesAmount;
    const oddsDiff = currentOddsDiff >= 0 ? currentOddsDiff : 0;
    const maxOddsDiff = bettingRules[nextIdx].oddCount - bettingRules[currentIdx].oddCount;

    return {
      isFirst: false,
      isLast: false,
      oddsDiff: oddsDiff > maxOddsDiff ? maxOddsDiff : oddsDiff,
      bonusType1: bettingRules[nextIdx].oddFactor,
      bonusLevel: nextIdx,
    };
  };

  getCurrentBonus = () => {
    const { bonuses, stakes } = this.props;
    const {
      comboboost_data: {
        min_odd: minOdd,
        multipliers,
      } = {},
    } = bonuses[0];
    const bettingRules = prepareBettingRules(multipliers);
    const reachedStakesAmount = getReachedStakesAmount(minOdd, stakes);

    return R.compose(
      R.prop('oddFactor'),
      R.find(({ oddCount }) => reachedStakesAmount >= oddCount),
      R.reverse,
    )(bettingRules);
  };

  render() {
    const {
      currency,
      bonuses,
      bonusAmount,
    } = this.props;
    const { currentIdx, nextIdx } = this.state;

    if (!bonuses || (bonuses && !bonuses.length) || (currentIdx === null && nextIdx === null)) {
      return null;
    }

    const {
      comboboost_data: {
        min_odd: minOdd,
        multipliers,
      } = {},
    } = bonuses[0];
    const bettingRules = prepareBettingRules(multipliers);
    const maxBonusLevel = bettingRules.length;
    const {
      isFirst,
      isLast,
      oddsDiff,
      bonusType1,
      bonusLevel,
    } = this.getBonusRules();

    return (
      <div
        className={classNames('bonuses rounded my-2 position-relative py-1 px-1_5', {
          'is-set': !isFirst,
        })}
      >
        <div className="mb-1">
          {isFirst && <FormattedTag id="bonuses.no-bonus" className="text-small font-weight-bold" />}
          {!isFirst && <FormattedTag id="bonuses.1.current" values={{ amount: this.getCurrentBonus() }} className="text-small font-weight-bold" />}
          {!isFirst && !!bonusAmount && <span className="text-small"> ({formatAmountWithCurrencySymbol(bonusAmount, currency)})</span>}
        </div>

        {isLast
          ? <FormattedTag id="bonuses.last" className="mt-1 caption" />
          : (
            <div className="d-flex flex-wrap mt-1 caption">
              {!isLast && (
                <FormattedTag
                  id={`bonuses.1.${isFirst ? 'first' : 'next'}`}
                  values={{ amount: bonusType1 }}
                  className="caption mr-0_5"
                />
              )}

              <FormattedMessage id={`bonuses.${oddsDiff === 0 ? 'no-more-bets' : 'more-bets'}`} values={{ amount: oddsDiff, odds: minOdd }}>
                {(intl) => {
                  const splittedIntl = intl.split(' ');

                  return splittedIntl.map((word, idx) => {
                    if (idx === 0) {
                      return (
                        <span className="d-flex">
                          <IconApproved color={oddsDiff === 0 ? colors.cpSecondary1 : colors.cpExtra3} className="icon-approved mr-0_5 flex-shrink-0" />
                          <span>{word}</span>
                        </span>
                      );
                    }

                    return <span className={classNames('ml-0_25', { 'mr-0_5': idx === splittedIntl.length - 1 })}>{word}</span>;
                  });
                }}
              </FormattedMessage>
            </div>
          )}

        <div
          className={classNames('bonuses-level position-absolute px-0_5 d-flex align-items-center', {
            'bg-primary': !isFirst,
            'bg-main-4': isFirst,
          })}
        >
          <IconStar color={isFirst ? colors.cpExtra2 : colors.cpExtra1} className="icon-star mr-0_5" />
          <FormattedTag
            id="bonuses.level"
            values={{ current: bonusLevel, max: maxBonusLevel }}
            className={classNames('font-weight-bold text-uppercase', {
              'text-extra-1': !isFirst,
              'text-extra-2': isFirst,
            })}
          />
        </div>
      </div>
    );
  }
}
