import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
  withBetSlipActions,
  withBetSlip,
  withUser,
  withLocale,
  withFeedbackActions,
} from 'core/hocs';
import { isEmptyOrNil } from 'core/helpers';
import { BET_SLIP_STORE_FIELDS, USER_STORE_FIELDS } from 'core/constants';

import { Spinner } from 'components/spinner/spinner';
import { FreebetsItem } from './freebets-item';
import { FREEBET_TYPES } from '../../../constants';

import './freebets.scss';

const FreebetsUI = ({
  locale,
  freebets,
  isFreebetsInProgress,
  currency,
  activateBonus,
  sendSportClickFeedback,
  sendLeagueClickFeedback,
  sendEventClickFeedback,
}) => {
  if (isFreebetsInProgress) {
    return <Spinner className="mx-auto" />;
  }

  if (isEmptyOrNil(freebets)) {
    return null;
  }

  const filteredFreebets = R.reject(({ freebet_data: { type } }) => type !== FREEBET_TYPES.BET_REFUND, freebets);

  return (
    <div className="freebets">
      {filteredFreebets.map(freebet => (
        <FreebetsItem
          key={freebet.id}
          locale={locale}
          freebet={freebet}
          currency={currency}
          activateBonus={activateBonus}
          sendSportClickFeedback={sendSportClickFeedback}
          sendLeagueClickFeedback={sendLeagueClickFeedback}
          sendEventClickFeedback={sendEventClickFeedback}
        />
      ))}
    </div>
  );
};

FreebetsUI.propTypes = {
  locale: PropTypes.string.isRequired,
  freebets: PropTypes.arrayOf(PropTypes.shape()),
  isFreebetsInProgress: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  activateBonus: PropTypes.func.isRequired,
  sendSportClickFeedback: PropTypes.func.isRequired,
  sendLeagueClickFeedback: PropTypes.func.isRequired,
  sendEventClickFeedback: PropTypes.func.isRequired,
};

FreebetsUI.defaultProps = {
  freebets: null,
};

export const Freebets = withFeedbackActions(withBetSlipActions(withLocale(
  withUser(
    withBetSlip(FreebetsUI, [
      BET_SLIP_STORE_FIELDS.FREEBETS,
      BET_SLIP_STORE_FIELDS.IS_FREEBETS_IN_PROGRESS,
    ]),
    [USER_STORE_FIELDS.CURRENCY]
  )
)));
