import React, { Component } from 'react';

export const withClock = WrappedComponent =>
  class WithClock extends Component {
    intervalId;

    state = {
      interval: 0,
    };

    componentDidMount() {
      this.startTick();
    }

    componentDidUpdate(prevProps, prevState) {
      const { interval } = this.state;
      const { interval: prevInterval } = prevState;

      if (interval === prevInterval) {
        this.stopTick();
        this.startTick();
      }
    }

    componentWillUnmount() {
      this.stopTick();
    }

    startTick = () => {
      this.intervalId = setInterval(() => {
        this.setState(prevState => ({ interval: prevState.interval + 1 }));
      }, 10000);
    };

    stopTick = () => {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
