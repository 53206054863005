import { IconSportOthers } from 'components/icons/icon-sports/icon-sport-others';
import { IconAo } from 'components/icons/icon-leagues/icon-ao';
import { IconAtp } from 'components/icons/icon-leagues/icon-atp';
import { IconEpl } from 'components/icons/icon-leagues/icon-epl';
import { IconEuroleague } from 'components/icons/icon-leagues/icon-euroleague';
import { IconLaliga } from 'components/icons/icon-leagues/icon-laliga';
import { IconLeague1 } from 'components/icons/icon-leagues/icon-league-1';
import { IconNba } from 'components/icons/icon-leagues/icon-nba';
import { IconNhl } from 'components/icons/icon-leagues/icon-nhl';
import { IconRg } from 'components/icons/icon-leagues/icon-rg';
import { IconSeriaA } from 'components/icons/icon-leagues/icon-seria-a';
import { IconTrSl } from 'components/icons/icon-leagues/icon-tr-sl';
import { IconUcl } from 'components/icons/icon-leagues/icon-ucl';
import { IconUel } from 'components/icons/icon-leagues/icon-uel';
import { IconUsOpen } from 'components/icons/icon-leagues/icon-us-open';
import { IconWc } from 'components/icons/icon-leagues/icon-wc';
import { IconWimbledon } from 'components/icons/icon-leagues/icon-wimbledon';
import { IconWta } from 'components/icons/icon-leagues/icon-wta';
import { IconEuro } from 'components/icons/icon-leagues/icon-euro';

export const getTopLeagueIcon = (leagueId, withDefault = true) => {
  if (['1670073988614852608'].includes(leagueId)) {
    return IconWc;
  }

  if (['1672291988642451456'].includes(leagueId)) {
    return IconEuro;
  }

  if (['1669819100844658688'].includes(leagueId)) {
    return IconUcl;
  }

  if (['1669819114245459968'].includes(leagueId)) {
    return IconUel;
  }

  if (['1669818860469096448'].includes(leagueId)) {
    return IconEpl;
  }

  if (['1669819042829045760'].includes(leagueId)) {
    return IconSeriaA;
  }

  if (['1669819032615915520'].includes(leagueId)) {
    return IconLaliga;
  }

  if (['1669818844560101376'].includes(leagueId)) {
    return IconLeague1;
  }

  if (['1669818979335671808'].includes(leagueId)) {
    return IconTrSl;
  }

  if (['1669819088278523904'].includes(leagueId)) {
    return IconNba;
  }

  if (['1669818991847280640'].includes(leagueId)) {
    return IconEuroleague;
  }

  if (['1669818960062844928'].includes(leagueId)) {
    return IconNhl;
  }

  if (['1672291995919568896', '1696398078883532800', '1697583165922746368', '1672291995663646720', '1696413187366330368'].includes(leagueId)) {
    return IconAo;
  }

  if (['1672291995382697984', '1744642536967905280', '1672291996200517632', '1744546631975575552', '1745156586139164672'].includes(leagueId)) {
    return IconRg;
  }

  if (['1672292061686185984', '1756788706720751616', '1672292061946302464', '1756787362320490496', '1758477328800944128'].includes(leagueId)) {
    return IconWimbledon;
  }

  if (['1672291990030696448', '1777748915584634880', '1672291990299201536', '1777675972900954112', '1778125227855847424'].includes(leagueId)) {
    return IconUsOpen;
  }

  if (['1672291893763031040', '1672291890768297984'].includes(leagueId)) {
    return IconAtp;
  }

  if (['1798355528141901824', '1799682611170381824'].includes(leagueId)) {
    return IconWta;
  }

  if (withDefault) {
    return IconSportOthers;
  }

  return null;
};
