import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { prepareBetsForRepeat, mathRound, convertStringifiedBoolean } from 'core/helpers';
import { AI_OUTCOME_SECTION } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { CashoutButton } from 'components/cashout-button/cashout-button';
import { CashoutConfirm } from 'components/cashout-confirm/cashout-confirm';
import { BetScore } from 'components/bet-score/bet-score';
import { IconRepeat } from 'components/icons/icon-repeat/icon-repeat';
import { toShortFormat } from 'helpers/date';
import { getBetStatusIcon, prepareOutrightName, isBetWithRepeat } from 'helpers/bets';
import { formatAmountWithCurrencySymbol } from 'helpers/currency';
import { GA } from 'helpers/ga';

export class BetItemOrdinar extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    makeCashout: PropTypes.func.isRequired,
    potential_win: PropTypes.string.isRequired,
    sum: PropTypes.string.isRequired,
    total_k: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    selections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isCashoutInProgress: PropTypes.bool,
    cashout_amount: PropTypes.string,
    bonus: PropTypes.shape(),
    type: PropTypes.string.isRequired,
    repeatBet: PropTypes.func.isRequired,
    onCashoutClick: PropTypes.func.isRequired,
    closeCashout: PropTypes.func.isRequired,
    isCashoutOpen: PropTypes.bool.isRequired,
    cashoutX: PropTypes.number,
    cashoutY: PropTypes.number,
    isCashoutAvailable: PropTypes.bool.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isCashoutInProgress: false,
    cashout_amount: null,
    bonus: null,
    cashoutX: null,
    cashoutY: null,
  };

  onCashoutClick = (e) => {
    const { onCashoutClick, id } = this.props;
    onCashoutClick(e, id);
  };

  cancelCashout = () => {
    const { closeCashout } = this.props;
    closeCashout();
    GA.event({
      category: 'my-bets',
      label: 'cancel-cashout',
    });
  };

  makeCashout = () => {
    const {
      id,
      cashout_amount: cashoutAmount,
      makeCashout,
      closeCashout,
    } = this.props;

    closeCashout();
    makeCashout({ id, cashoutAmount });
    GA.event({
      category: 'my-bets',
      label: 'confirm-cashout',
    });
  };

  onEventClick = (e) => {
    const { dataset: { eventId, isLive } } = e.currentTarget;
    const { sendEventClickFeedback } = this.props;
    GA.event({
      category: 'go-to-event-page',
      label: 'my-bets-event-click',
    });
    sendEventClickFeedback(eventId, convertStringifiedBoolean(isLive));
  };

  repeatBet = () => {
    const {
      repeatBet,
      sum,
      type,
      selections,
    } = this.props;
    repeatBet({
      sum,
      type,
      selections: prepareBetsForRepeat(selections, sum),
    });

    GA.event({
      category: 'bet-slip',
      label: 'repeat-last-bet-click',
    });
  };

  render() {
    const {
      locale,
      currency,
      potential_win: potentialWin,
      sum,
      total_k: totalK,
      status,
      selections,
      isCashoutInProgress,
      cashout_amount: cashoutAmount,
      bonus,
      isCashoutOpen,
      cashoutX,
      cashoutY,
      isCashoutAvailable,
    } = this.props;
    const {
      desc: {
        competitors,
        scheduled,
        type,
        stage,
        season,
        tournament,
        sport: {
          id: sportId,
        },
      },
      market_name: marketName,
      outcome_name: outcomeName,
      event_id: eventId,
      eventInfo,
      final_score: finalScore,
    } = selections[0];
    const eventDateTimeStamp = scheduled * 1000;
    const isOutright = type !== 'match';
    const teamA = !isOutright && competitors[0].name;
    const teamB = !isOutright && competitors[1].name;
    const outrightName = isOutright && prepareOutrightName(type, stage, season, tournament);
    const StatusIcon = getBetStatusIcon(status);
    const isFreebet = bonus && bonus.type === 'freebet';
    const withEventLink = eventInfo && !isOutright;
    const LinkComponent = withEventLink ? RouteLink : 'span';
    const linkProps = withEventLink
      ? {
        locale,
        to: `/event/${eventId}?isLive=${eventInfo && eventInfo.isLive}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.MY_BETS}`,
        onClick: this.onEventClick,
        'data-event-id': eventId,
        'data-is-live': !!eventInfo && eventInfo.isLive,
      }
      : {};

    return (
      <div className="bet-item-ordinar p-2 bg-main-4">
        <div className="d-flex justify-content-between mb-0_25">
          <span title={outcomeName} className="font-weight-bold text-truncate mr-1">{outcomeName}</span>
          {!!totalK && <span className="font-weight-bold">{mathRound(totalK)}</span>}
        </div>

        <div title={marketName} className="text-extra-2 caption mb-0_25">{marketName}</div>

        <div className="d-flex align-items-center justify-content-between mb-1">
          <div className="d-flex overflow-hidden caption">
            <LinkComponent
              {...linkProps}
              title={isOutright ? outrightName : `${teamA} - ${teamB}`}
              className={classNames('text-truncate', { 'bet-item-event-link': withEventLink, 'text-extra-2': !withEventLink })}
            >
              {isOutright ? outrightName : `${teamA} - ${teamB}`}
            </LinkComponent>

            <span className="mx-0_5 text-extra-3">&middot;</span>

            <BetScore
              score={eventInfo && eventInfo.score}
              finalScore={finalScore}
              sportId={sportId}
              date={toShortFormat(eventDateTimeStamp, locale)}
            />
          </div>

          {isBetWithRepeat(selections) && (
            <div
              role="button"
              tabIndex="0"
              onClick={this.repeatBet}
              onKeyPress={this.repeatBet}
              className="d-flex align-items-center flex-shrink-0 ml-1"
            >
              <IconRepeat className="flex-shrink-0 mr-1" />
              <FormattedTag id="bet-slip.repeat-bet" className="caption font-weight-bold text-extra-2" />
            </div>
          )}
        </div>

        <div className="d-flex align-items-center flex-wrap">
          <div className={classNames('bet-item-info d-flex flex-fill', {
            'without-cashout': !cashoutAmount || !isCashoutAvailable,
          })}
          >
            <div className="bet-item-stake pr-0_25 mr-0_5">
              <FormattedTag id="bet-slip.stake" tag="div" className="caption text-extra-2" />
              {!!sum && <div className="text-small font-weight-bold text-break">{formatAmountWithCurrencySymbol(sum, currency)}</div>}
            </div>
            <div className="bet-item-return pl-0_25 ml-0_5">
              <FormattedTag id="bet-slip.return" tag="div" className="caption text-extra-2" />
              <div className="d-flex align-items-center">
                <StatusIcon className="bet-status-icon mr-1 flex-shrink-0" />
                {!!potentialWin && <div className="text-small font-weight-bold text-break">{formatAmountWithCurrencySymbol(potentialWin, currency)}</div>}
              </div>
            </div>
            {isFreebet && <FormattedTag id="freebet.title" className="bet-item-freebet bg-warning px-0_5 py-0_25 font-weight-bold align-self-end" />}
          </div>

          {!!cashoutAmount && isCashoutAvailable && (
            <CashoutButton
              currency={currency}
              cashoutAmount={cashoutAmount}
              onClick={this.onCashoutClick}
              isLoading={isCashoutInProgress}
            />
          )}

          {isCashoutOpen && (
            <CashoutConfirm
              currency={currency}
              isPortal
              xPosition={cashoutX}
              yPosition={cashoutY}
              cashoutAmount={cashoutAmount}
              cancelCashout={this.cancelCashout}
              makeCashout={this.makeCashout}
            />
          )}
        </div>
      </div>
    );
  }
}
