import React from 'react';
import PropTypes from 'prop-types';

import { IconSportSoccer } from 'components/icons/icon-sports/icon-sport-soccer';
import { IconSportBasketball } from 'components/icons/icon-sports/icon-sport-basketball';
import { IconSportRugby } from 'components/icons/icon-sports/icon-sport-rugby';
import { IconSportTennis } from 'components/icons/icon-sports/icon-sport-tennis';
import { IconSportVolleyball } from 'components/icons/icon-sports/icon-sport-volleyball';
import { IconSportBaseball } from 'components/icons/icon-sports/icon-sport-baseball';
import { IconSportSnooker } from 'components/icons/icon-sports/icon-sport-snooker';
import { IconSportIceHockey } from 'components/icons/icon-sports/icon-sport-ice-hockey';
import { IconSportHandball } from 'components/icons/icon-sports/icon-sport-handball';
import { IconSportBeachHandball } from 'components/icons/icon-sports/icon-sport-beach-handball';
import { IconSportAussieRules } from 'components/icons/icon-sports/icon-sport-aussie-rules';
import { IconSportTableTennis } from 'components/icons/icon-sports/icon-sport-table-tennis';
import { IconSportFutsal } from 'components/icons/icon-sports/icon-sport-futsal';
import { IconSportAmericanFootball } from 'components/icons/icon-sports/icon-sport-american-football';
import { IconSportBadminton } from 'components/icons/icon-sports/icon-sport-badminton';
import { IconSportBeachVolley } from 'components/icons/icon-sports/icon-sport-beach-volley';
import { IconSportBowls } from 'components/icons/icon-sports/icon-sport-bowls';
import { IconSportCricket } from 'components/icons/icon-sports/icon-sport-cricket';
import { IconSportDarts } from 'components/icons/icon-sports/icon-sport-darts';
import { IconSportFieldHockey } from 'components/icons/icon-sports/icon-sport-field-hockey';
import { IconSportSquash } from 'components/icons/icon-sports/icon-sport-squash';
import { IconSportBiathlon } from 'components/icons/icon-sports/icon-sport-biathlon';
import { IconSportGolf } from 'components/icons/icon-sports/icon-sport-golf';
import { IconSportCycling } from 'components/icons/icon-sports/icon-sport-cycling';
import { IconSportFormula } from 'components/icons/icon-sports/icon-sport-formula';
import { IconSportMma } from 'components/icons/icon-sports/icon-sport-mma';
import { IconSportBeachFootball } from 'components/icons/icon-sports/icon-sport-beach-football';
import { IconSportWaterPolo } from 'components/icons/icon-sports/icon-sport-water-polo';
import { IconSportChess } from 'components/icons/icon-sports/icon-sport-chess';
import { IconSportCurling } from 'components/icons/icon-sports/icon-sport-curling';
import { IconSportFloorball } from 'components/icons/icon-sports/icon-sport-floorball';
import { IconSportBoxing } from 'components/icons/icon-sports/icon-sport-boxing';
import { IconSportSpeedway } from 'components/icons/icon-sports/icon-sport-speedway';
import { IconSportPadelTennis } from 'components/icons/icon-sports/icon-sport-padel-tennis';
import { IconSportSkiRacing } from 'components/icons/icon-sports/icon-sport-ski-racing';
import { IconSportSkiJumping } from 'components/icons/icon-sports/icon-sport-ski-jumping';
import { IconSportSailing } from 'components/icons/icon-sports/icon-sport-sailing';
import { IconSportJudo } from 'components/icons/icon-sports/icon-sport-judo';
import { IconSportAthletics } from 'components/icons/icon-sports/icon-sport-athletics';
import { IconSportEquestrian } from 'components/icons/icon-sports/icon-sport-equestrian';
import { IconSportGaelicFootball } from 'components/icons/icon-sports/icon-sport-gaelic-football';
import { IconSportEsport } from 'components/icons/icon-sports/icon-sport-esport';
import { IconSportEtennis } from 'components/icons/icon-sports/icon-sport-etennis';
import { IconSportWeightlifting } from 'components/icons/icon-sports/icon-sport-weightlifting';
import { IconSportSumo } from 'components/icons/icon-sports/icon-sport-sumo';
import { IconSportGymnastics } from 'components/icons/icon-sports/icon-sport-gymnastics';
import { IconSportTaekwondo } from 'components/icons/icon-sports/icon-sport-taekwondo';
import { IconSportPool } from 'components/icons/icon-sports/icon-sport-pool';
import { IconSportShooting } from 'components/icons/icon-sports/icon-sport-shooting';
import { IconSportArchery } from 'components/icons/icon-sports/icon-sport-archery';
import { IconSportWaterSports } from 'components/icons/icon-sports/icon-sport-water-sports';
import { IconSportLacrosse } from 'components/icons/icon-sports/icon-sport-lacrosse';
import { IconSportMotorcycling } from 'components/icons/icon-sports/icon-sport-motorcycling';
import { IconSportSurfing } from 'components/icons/icon-sports/icon-sport-surfing';
import { IconSportRally } from 'components/icons/icon-sports/icon-sport-rally';
import { IconSportCanoeing } from 'components/icons/icon-sports/icon-sport-canoeing';
import { IconSportOlympicGames } from 'components/icons/icon-sports/icon-sport-olympic-games';
import { IconSportBasketball3x3 } from 'components/icons/icon-sports/icon-sport-basketball-3x3';
import { IconSportSkiing } from 'components/icons/icon-sports/icon-sport-skiing';
import { IconSportFencing } from 'components/icons/icon-sports/icon-sport-fencing';
import { IconSportModernPentathlon } from 'components/icons/icon-sports/icon-sport-modern-pentathlon';
import { IconSportHurling } from 'components/icons/icon-sports/icon-sport-hurling';
import { IconSportKabaddi } from 'components/icons/icon-sports/icon-sport-kabaddi';
import { IconSportSepakTakraw } from 'components/icons/icon-sports/icon-sport-sepak-takraw';
import { IconSportLottery } from 'components/icons/icon-sports/icon-sport-lottery';
import { IconSportOthers } from 'components/icons/icon-sports/icon-sport-others';
import { IconSportSpecials } from 'components/icons/icon-sports/icon-sport-specials';
import { IconSportFifa18 } from 'components/icons/icon-sports/icon-sport-fifa-18';
import { IconSportEfighting } from 'components/icons/icon-sports/icon-sport-efighting';
import { IconSportNba2k } from 'components/icons/icon-sports/icon-sport-nba2k';
import { IconSportRocket } from 'components/icons/icon-sports/icon-sport-rocket';
import { IconSportEcricket } from 'components/icons/icon-sports/icon-sport-ecricket';
import { IconSportArenaOfValor } from 'components/icons/icon-sports/icon-sport-arena-of-valor';
import { IconSportCod } from 'components/icons/icon-sports/icon-sport-cod';
import { IconSportCs } from 'components/icons/icon-sports/icon-sport-cs';
import { IconSportDota } from 'components/icons/icon-sports/icon-sport-dota';
import { IconSportHs } from 'components/icons/icon-sports/icon-sport-hs';
import { IconSportLol } from 'components/icons/icon-sports/icon-sport-lol';
import { IconSportOverwatch } from 'components/icons/icon-sports/icon-sport-overwatch';
import { IconSportQuake } from 'components/icons/icon-sports/icon-sport-quake';
import { IconSportStarcraft } from 'components/icons/icon-sports/icon-sport-starcraft';
import { IconSportWarcraft } from 'components/icons/icon-sports/icon-sport-warcraft';
import { IconSportKingOfGlory } from 'components/icons/icon-sports/icon-sport-king-of-glory';
import { IconSportRainbowSix } from 'components/icons/icon-sports/icon-sport-rainbow-six';

import { SPORTS } from '../../constants';

const ICONS = {
  [SPORTS.FOOTBALL]: IconSportSoccer,
  [SPORTS.BASKETBALL]: IconSportBasketball,
  [SPORTS.BASEBALL]: IconSportBaseball,
  [SPORTS.ICE_HOCKEY]: IconSportIceHockey,
  [SPORTS.TENNIS]: IconSportTennis,
  [SPORTS.HANDBALL]: IconSportHandball,
  [SPORTS.FLOORBALL]: IconSportFloorball,
  [SPORTS.GOLF]: IconSportGolf,
  [SPORTS.BOXING]: IconSportBoxing,
  [SPORTS.MOTORCYCLING]: IconSportMotorcycling,
  [SPORTS.RUGBY]: IconSportRugby,
  [SPORTS.AUSSIE_RULES]: IconSportAussieRules,
  [SPORTS.AMERICAN_FOOTBALL]: IconSportAmericanFootball,
  [SPORTS.CYCLING]: IconSportCycling,
  [SPORTS.SPECIALS]: IconSportSpecials,
  [SPORTS.SNOOKER]: IconSportSnooker,
  [SPORTS.TABLE_TENNIS]: IconSportTableTennis,
  [SPORTS.CRICKET]: IconSportCricket,
  [SPORTS.DARTS]: IconSportDarts,
  [SPORTS.VOLLEYBALL]: IconSportVolleyball,
  [SPORTS.FIELD_HOCKEY]: IconSportFieldHockey,
  [SPORTS.POOL]: IconSportPool,
  [SPORTS.WATER_POLO]: IconSportWaterPolo,
  [SPORTS.CURLING]: IconSportCurling,
  [SPORTS.FUTSAL]: IconSportFutsal,
  [SPORTS.OLYMPIC_GAMES]: IconSportOlympicGames,
  [SPORTS.BADMINTON]: IconSportBadminton,
  [SPORTS.BOWLS]: IconSportBowls,
  [SPORTS.CHESS]: IconSportChess,
  [SPORTS.BEACH_VOLLEY]: IconSportBeachVolley,
  [SPORTS.ATHLETICS]: IconSportAthletics,
  [SPORTS.SQUASH]: IconSportSquash,
  [SPORTS.LACROSSE]: IconSportLacrosse,
  [SPORTS.FORMULA_1]: IconSportFormula,
  [SPORTS.ALPINE_SKIING]: IconSportSkiing,
  [SPORTS.BIATHLON]: IconSportBiathlon,
  [SPORTS.SKI_JUMPING]: IconSportSkiJumping,
  [SPORTS.SWIMMING]: IconSportWaterSports,
  [SPORTS.RUGBY_LEAGUE]: IconSportRugby,
  [SPORTS.PADEL_TENNIS]: IconSportPadelTennis,
  [SPORTS.CANOEING]: IconSportCanoeing,
  [SPORTS.ARCHERY]: IconSportArchery,
  [SPORTS.EQUESTRIAN]: IconSportEquestrian,
  [SPORTS.FENCING]: IconSportFencing,
  [SPORTS.GYMNASTICS]: IconSportGymnastics,
  [SPORTS.JUDO]: IconSportJudo,
  [SPORTS.MODERN_PENTATHLON]: IconSportModernPentathlon,
  [SPORTS.SAILING]: IconSportSailing,
  [SPORTS.SHOOTING]: IconSportShooting,
  [SPORTS.TAEKWONDO]: IconSportTaekwondo,
  [SPORTS.WEIGHTLIFTING]: IconSportWeightlifting,
  [SPORTS.SURFING]: IconSportSurfing,
  [SPORTS.BEACH_TENNIS]: IconSportBeachFootball,
  [SPORTS.SUMO]: IconSportSumo,
  [SPORTS.RALLY]: IconSportRally,
  [SPORTS.FREESTYLE_SKIING]: IconSportSkiRacing,
  [SPORTS.E_SPORT]: IconSportEsport,
  [SPORTS.LOTTERY]: IconSportLottery,
  [SPORTS.CS]: IconSportCs,
  [SPORTS.LOL]: IconSportLol,
  [SPORTS.DOTA]: IconSportDota,
  [SPORTS.STARCRAFT]: IconSportStarcraft,
  [SPORTS.HEARTHSTONE]: IconSportHs,
  [SPORTS.MMA]: IconSportMma,
  [SPORTS.COD]: IconSportCod,
  [SPORTS.OVERWATCH]: IconSportOverwatch,
  [SPORTS.WARCRAFT_3]: IconSportWarcraft,
  [SPORTS.RAINBOW_SIX]: IconSportRainbowSix,
  [SPORTS.SEPAK_TAKRAW]: IconSportSepakTakraw,
  [SPORTS.ROCKET_LEAGUE]: IconSportRocket,
  [SPORTS.SPEEDWAY]: IconSportSpeedway,
  [SPORTS.KING_OF_GLORY]: IconSportKingOfGlory,
  [SPORTS.GAELIC_FOOTBALL]: IconSportGaelicFootball,
  [SPORTS.GAELIC_HURLING]: IconSportHurling,
  [SPORTS.FIFA]: IconSportFifa18,
  [SPORTS.KABADDI]: IconSportKabaddi,
  [SPORTS.QUAKE]: IconSportQuake,
  [SPORTS.FORMULA_E]: IconSportFormula,
  [SPORTS.NBA2K]: IconSportNba2k,
  [SPORTS.BASKETBALL_3X3]: IconSportBasketball3x3,
  [SPORTS.BEACH_HANDBALL]: IconSportBeachHandball,
  [SPORTS.ARENA_OF_VALOR]: IconSportArenaOfValor,
  [SPORTS.FIFA18]: IconSportFifa18,
  [SPORTS.ROCKET_LEAGUE_1]: IconSportRocket,
  [SPORTS.NBA2K19]: IconSportNba2k,
  [SPORTS.AO_TENNIS]: IconSportEtennis,
  [SPORTS.E_FIGHTING]: IconSportEfighting,
  [SPORTS.E_CRICKET]: IconSportEcricket,
};

const SportTypeIconUI = ({ sportId, className, ...restProps }) => {
  const IconComponent = ICONS[sportId] || IconSportOthers;

  return (
    <span className={className}>
      <IconComponent {...restProps} />
    </span>
  );
};

SportTypeIconUI.propTypes = {
  sportId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SportTypeIconUI.defaultProps = {
  className: null,
};

export const SportTypeIcon = React.memo(SportTypeIconUI);
