import React from 'react';
import PropTypes from 'prop-types';

import { RouteLink } from 'components/route-link/route-link';

import './searched-leagues.scss';

export const SearchedLeagues = ({ locale, leagues, onLeagueClick }) => (
  <div className="searched-leagues px-2 px-sm-0_5 px-lg-0 pb-3 pb-sm-0">
    {leagues.map(({
      leagueId,
      sportId,
      leagueName,
      countryName,
      eventsCount,
    }) => (
      <RouteLink
        key={leagueId}
        locale={locale}
        to={`/league/${sportId}/${leagueId}`}
        className="searched-leagues-item"
        data-league-id={leagueId}
        onClick={onLeagueClick}
      >
        <span className="searched-leagues-name">{`${countryName}. ${leagueName}`}</span>
        <span className="text-extra-3 ml-1">{eventsCount}</span>
      </RouteLink>
    ))}
  </div>
);

SearchedLeagues.propTypes = {
  locale: PropTypes.string.isRequired,
  leagues: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onLeagueClick: PropTypes.func.isRequired,
};
