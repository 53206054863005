import * as R from 'ramda';

export const prepareInplayGames = (inplayGames, watchlist) => {
  let preparedWatchlist = [];
  const preparedInplayGames = [];

  inplayGames.forEach((group) => {
    const groupGames = [];

    group.forEach((event) => {
      if (watchlist.includes(event.desc.id)) {
        preparedWatchlist.push(event);
      } else {
        groupGames.push(event);
      }
    });

    if (groupGames.length) {
      preparedInplayGames.push(groupGames);
    }
  });

  preparedWatchlist = R.compose(
    R.filter(event => !!event),
    R.map(eventId => R.find(R.pathEq(['desc', 'id'], eventId), preparedWatchlist))
  )(watchlist);

  return {
    preparedWatchlist,
    preparedInplayGames,
  };
};
