import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withLocale } from 'core/hocs';
import { getEnv } from 'core/helpers';
import { BETBY_LANG_IDS } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconLiveStatsStub } from 'components/icons/icon-live-stats-stub/icon-live-stats-stub';

import './live-stats.scss';

const trackerUrl = getEnv('BETBY_LIVE_TRACKER_URL');

if (!trackerUrl) {
  throw new Error('BETBY_LIVE_TRACKER_URL must be set');
}

class LiveStatsUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    eventData: PropTypes.shape().isRequired,
    liveTrackerProviders: PropTypes.string.isRequired,
    isUserIdentified: PropTypes.bool.isRequired,
  };

  static defaultProps = {
  };

  state = {
    isLoaded: false,
    isFailed: false,
    height: 0,
  };

  componentDidMount() {
    window.addEventListener('message', this.onIframeMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onIframeMessage);
  }

  onIframeMessage = ({ data: { message = {} } = {} } = {}) => {
    if (message.type === 'bt-frame-height-changed') {
      this.setState({ height: message.payload });
    } else if (message.type === 'bt-frame-widget-failed') {
      this.setState({ isFailed: true });
    } else if (message.type === 'bt-frame-widget-loaded') {
      this.setState({ isLoaded: true });
    }
  };

  render() {
    const {
      locale,
      eventData,
      liveTrackerProviders,
      isUserIdentified,
    } = this.props;
    const { isLoaded, isFailed, height } = this.state;
    const {
      desc: {
        betradar_id: betradarId,
        sport: {
          id: sportId,
        },
      },
      isLive,
    } = eventData;
    const lang = BETBY_LANG_IDS[locale] || 'en';
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone.replaceAll('/', ':');
    const eventId = betradarId.match(/\w+:\w+:(\d+)/);

    if (isFailed || !eventId || (isUserIdentified && !liveTrackerProviders)) {
      return (
        <div className="d-flex flex-column align-items-center justify-content-center text-center px-2 pb-2_5 pt-5">
          <IconLiveStatsStub className="mb-2" />
          <FormattedTag id="live-stats.empty.event" className="text-extra-3" />
        </div>
      );
    }

    return (
      <div className={classNames('live-stats', { invisible: !isLoaded || !isUserIdentified })}>
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe
          src={`${trackerUrl}/tracker.html?eventId=${eventId[1]}&sportId=${sportId}&lang=${lang}&liveEvent=${isLive}&providers=${liveTrackerProviders}&timezone=${timezone}`}
          frameBorder="0"
          width="100%"
          height={`${height}px`}
        />
      </div>
    );
  }
}

export const LiveStats = withLocale(LiveStatsUI);
