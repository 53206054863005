import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportLol = ({
  className, isActive, width, height,
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.01612 5.48462C0.368456 6.65845 0 8.00271 0 9.43214C0 10.8616 0.368456 12.2071 1.01612 13.3809V5.48462Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M8.9863 1.13745C8.30168 1.13745 7.63742 1.22013 7 1.37047V2.67587C7.63107 2.49798 8.29656 2.40026 8.9863 2.40026C12.9361 2.40026 16.1387 5.53848 16.1387 9.41086C16.1387 11.1547 15.4872 12.7495 14.4124 13.976L14.2084 14.6913L13.7571 16.2748C15.9716 14.7803 17.4251 12.2734 17.4251 9.43336C17.4238 4.8507 13.6462 1.13745 8.9863 1.13745Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M7 14H13.1761H13.3112C14.3563 12.832 15 11.2503 15 9.50871C15 5.91359 12.2672 3 8.89563 3C8.23373 3 7.59738 3.11446 7 3.32127V14Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
    <path d="M6 0H1L2 1.85537V16.1459L1 18H11.9206L12.9227 15H6V0Z" fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} />
  </svg>
);

IconSportLol.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportLol.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
