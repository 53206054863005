import * as R from 'ramda';
import { isOneOfValues } from 'core/helpers';
import { BET_STATUS, COMPLEX_SYSTEMS } from 'core/constants';

import { IconWon } from 'components/icons/bet-status/icon-won';
import { IconPending } from 'components/icons/bet-status/icon-pending';
import { IconLost } from 'components/icons/bet-status/icon-lost';

const PENDING_STATUSES = [BET_STATUS.OPEN, BET_STATUS.USELESS];

export const getBetStatusIcon = R.cond([
  [isOneOfValues(PENDING_STATUSES), R.always(IconPending)],
  [R.equals(BET_STATUS.LOST), R.always(IconLost)],
  [R.T, R.always(IconWon)],
]);

export const findLostStake = R.find(({ order: { statusId } }) => statusId === BET_STATUS.LOST);

export const getMultipleBetStatusIcon = R.cond([
  [isOneOfValues(PENDING_STATUSES), R.always(IconPending)],
  [R.equals(BET_STATUS.WON), R.always(IconWon)],
  [R.T, R.always(IconLost)],
]);

export const prepareOutrightName = (type, stage, season, tournament) => {
  if (type === 'stage' && stage) {
    return stage.name;
  }

  if (type === 'season' && season) {
    return season.name;
  }

  if (type === 'tournament' && tournament) {
    return tournament.name;
  }

  return null;
};

export const getComplexSystemName = (type) => {
  const systemIndex = type.includes('FS') ? 1 : 0;
  const complexSystemIndex = type.split('/')[1];

  return COMPLEX_SYSTEMS[complexSystemIndex][systemIndex];
};

export const getSystemIntlId = type => type.includes('F') ? `complex.${getComplexSystemName(type)}` : 'system';

export const isBetWithRepeat = selections =>
  !!R.find(({ status, eventInfo } = {}) => eventInfo && PENDING_STATUSES.includes(status), selections);
