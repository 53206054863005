import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withUser } from 'core/hocs';
import { USER_STORE_FIELDS } from 'core/constants';

import { MediaQuery } from 'components/media-query/media-query';
import { SportsMenu } from './sports-menu/sports-menu';
import { StickyHeader } from './sticky-header/sticky-header';
import { PAGE_NAMES } from '../../constants';

import './header.scss';

class HeaderUI extends Component {
  static propTypes = {
    pageName: PropTypes.string.isRequired,
  };

  state = {
    isHidden: false,
  };

  setIsHidden = isHidden => this.setState({ isHidden });

  render() {
    const { pageName } = this.props;
    const { isHidden } = this.state;

    return (
      <div
        className={classNames('header-main d-flex flex-column mx-sm-n2 flex-shrink-0', {
          'header-short': pageName === PAGE_NAMES.EVENT || pageName === PAGE_NAMES.SEARCH,
          'bg-main-3': !isHidden,
        })}
      >
        <MediaQuery down="md">
          {pageName !== PAGE_NAMES.EVENT && pageName !== PAGE_NAMES.SEARCH && (
            <Fragment>
              <SportsMenu pageName={pageName} isHidden={isHidden} />
              <StickyHeader pageName={pageName} setIsHidden={this.setIsHidden} />
            </Fragment>
          )}
        </MediaQuery>
      </div>
    );
  }
}

export const Header = withUser(HeaderUI, [USER_STORE_FIELDS.IS_USER_LOGGED_IN]);
